import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import ExtractsRegulations from './components/ExtractsRegulations';

const NationalBuildingRegulationsRoot = styled('div')(({ theme }) => ({}));

const NationalBuildingRegulations = () => {
    return (
        <Fade in={true}>
            <NationalBuildingRegulationsRoot>
                <Introduction />

                <ExtractsRegulations />
            </NationalBuildingRegulationsRoot>
        </Fade>
    );
};

export default NationalBuildingRegulations;
