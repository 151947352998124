import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';

import HomeownersImage from '../../assets/images/product-pages/homeowners.jpg';
import WhatsCovered from './components/WhatsCovered';
import Downloads from './components/Downloads';

// Icons
import HousFireIcon from '../../assets/images/icons/housefire.svg';
import StormIcon from '../../assets/images/icons/storm.svg';
import AccidentIcon from '../../assets/images/icons/accident.svg';
import ElectricIcon from '../../assets/images/icons/electric.svg';
import StorageTankIcon from '../../assets/images/icons/storage-tank.svg';
import LandslideIcon from '../../assets/images/icons/landslide.svg';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import CasinoCctvIcon from '../../assets/images/icons/casino-cctv.svg';
import SecurityGuardIcon from '../../assets/images/icons/security-guard.svg';
import DataTransferIcon from '../../assets/images/icons/data-transfer.svg';
import LandscapeIcon from '../../assets/images/icons/landscape.svg';

// Documents
import KeyInformation from '../../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Key-Information-Document-April-2023.pdf';
import HomeowersInsurance from '../../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Insurance-Policy-April-2023.pdf';
import InsuranceProposal from '../../assets/documents/products-key-information/Insurance-Proposal-Form 2024.pdf';

const HomeownersBuildingInsuranceRoot = styled('div')(({ theme }) => ({}));

const content = {
    image: HomeownersImage,
    title: 'Homeowners Building Insurance',
    paragraph_one: (
        <Fragment>
            At CIA Building Insurance Specialists, we understand that every home has livelihood and
            family at its core. Its value is so much more than the replacement cost of its roof and
            four walls.
        </Fragment>
    ),
    paragraph_two: (
        <Fragment>
            Offering very wide cover on an <b>all risk basis</b>, the Homeowners Insurance Policy
            enables our clients to live life without the fear of loss caused by damage to their
            property. Our Homeowners Insurance Policy provides cover specifically for free standing
            private dwellings, whether individually owned or in a company, trust or close
            corporation. Even your holiday home can be covered.
        </Fragment>
    ),
    btnText: 'Online Quote',
    btnUrl: '/get-quote#homeowners-insurance',
};

const icon_items = [
    {
        title: 'Fire',
        text: 'Cover for loss or damage to buildings caused by fire and includes fires due to arson.',
        icon: HousFireIcon,
    },
    {
        title: 'Natural Disasters',
        text: 'Such as hailstorms, floods, tornados, lightning, earthquakes and tsunamis.',
        icon: StormIcon,
    },
    {
        title: 'Accidental Damage',
        text: 'Unforseen and unintentional damage such as spilling paint on a carpet.',
        icon: AccidentIcon,
    },
    {
        title: 'Power Surge',
        text: 'R100 000 power surge cover included free of charge.',
        icon: ElectricIcon,
    },
    {
        title: 'Geysers',
        text: 'Full geyser replacement cover including heatpumps and solar installations.',
        icon: StorageTankIcon,
    },
    {
        title: 'Subsidence and Landslide',
        text: 'Cover includes dolomite sites.',
        icon: LandslideIcon,
    },
    {
        title: 'Loss of Rent',
        text: 'Loss of rent, removal costs and alternative accommodation following damage.',
        icon: WalletIcon,
    },
    {
        title: 'Theft and Malicious Damage',
        text: 'Full cover for theft and malicious damage including exterior fixtures and fittings.',
        icon: CasinoCctvIcon,
    },
    {
        title: 'Security Guards',
        text: 'Appointment of temporary security to safeguard the property.',
        icon: SecurityGuardIcon,
    },
    {
        title: 'Property Transfer',
        text: 'Cover prior to property transfer.',
        icon: DataTransferIcon,
    },
    {
        title: 'Landscaped Gardens',
        text: 'Reinstatement of gardens following fire, impact and explosion.',
        icon: LandscapeIcon,
    },
    {
        title: 'Fallen Trees',
        text: 'Causing damage to the building.',
        icon: StormIcon,
    },
];

const document_items = [
    {
        title: 'Broadform Homeowners Insurance Policy',
        text: 'Document size: 477KB',
        document: HomeowersInsurance,
    },
    {
        title: 'Broadform Homeowners Insurance Policy - Key Information Document',
        text: 'Document size: 310KB',
        document: KeyInformation,
    },
    {
        title: 'Insurance Proposal Form',
        text: 'Document size: 235KB',
        document: InsuranceProposal,
    },
];

const HomeownersBuildingInsurance = () => {
    return (
        <Fade in={true}>
            <HomeownersBuildingInsuranceRoot>
                <Introduction content={content} />

                <WhatsCovered icon_items={icon_items} />

                <Downloads document_items={document_items} />
            </HomeownersBuildingInsuranceRoot>
        </Fade>
    );
};

export default HomeownersBuildingInsurance;
