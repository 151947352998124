import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Fade, Typography } from '@mui/material';
import { Loader } from '@googlemaps/js-api-loader';
import Introduction from './components/Introduction';
import RequestCallbackForm from '../../components/forms/RequestCallbackForm';

const RequestCallBackRoot = styled('div')(({ theme }) => ({}));

const RequestCallBack = () => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        // const loader = new Loader({
        //     apiKey: `AIzaSyCQMQ11q1LQcg7Ps-wYJS6qsKltOxFlQvw`,
        //     version: 'weekly',
        //     libraries: ['places', 'drawing', 'geometry'],
        // });
        // loader.load().then(() => {
        //     setReady(true);
        // });
    }, []);

    return (
        <Fade in={true}>
            <RequestCallBackRoot>
                <Introduction />

                <RequestCallbackForm />
            </RequestCallBackRoot>
        </Fade>
    );
};

export default RequestCallBack;
