import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import UploadForm from './components/UploadForm';

const ExistingPolicyScheduleRoot = styled('div')(({ theme }) => ({}));

const ExistingPolicySchedule = () => {
    return (
        <Fade in={true}>
            <ExistingPolicyScheduleRoot>
                <Introduction />

                <UploadForm />
            </ExistingPolicyScheduleRoot>
        </Fade>
    );
};

export default ExistingPolicySchedule;
