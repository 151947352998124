import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
    textAlign: 'center',
}));

const IntroductionTitle = styled('div')(({ theme }) => ({}));

const IntroductionText = styled('div')(({ theme }) => ({
    margin: '30px 0',
}));

const TitleDot = styled('span')(({ theme }) => ({
    marginLeft: '2px',
    height: '6px',
    width: '6px',
    backgroundColor: '#D7412E',
    borderRadius: '100%',
    display: 'inline-block',
}));

const Introduction = () => {
    const history = useHistory();

    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <IntroductionTitle>
                    <Typography variant="h1">
                        Commitment
                        <TitleDot /> Integrity
                        <TitleDot /> Ability
                        <TitleDot />
                    </Typography>
                </IntroductionTitle>

                <IntroductionText>
                    <Typography variant="body1">
                        CIA is an award-winning building insurance underwriter, with branches
                        throughout South Africa. When disaster strikes, we’re here to help keep the
                        roof over your head. We provide cover for all types of buildings including sectional
                        title developments, freestanding homes, office parks, warehouses and
                        shopping malls with our easy-to-understand all risk insurance policies
                        written in plain language. As the only dedicated building insurance
                        specialists in South Africa, our niche focus has allowed us to become the
                        experts in understanding and covering the risks faced by today’s property
                        owners.
                    </Typography>
                </IntroductionText>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push('/about-us')}
                >
                    Read More{' '}
                    <FontAwesomeIcon
                        style={{ marginLeft: '5px', fontSize: '18px' }}
                        icon={faAlignRight}
                    />
                </Button>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
