import formModel from './formModel';

const { firstName, lastName, email } = formModel;

const initialValues = {
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
};

export default initialValues;
