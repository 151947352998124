import React from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import CommunityImage from '../../../assets/images/slider/communitiy-home-carousel.jpg';
import HomeownersImage from '../../../assets/images/slider/homeowners-home-carousel.jpg';
import CommercialImage from '../../../assets/images/slider/commercial-home-carousel.jpg';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';

const HomeSliderRoot = styled('div')(({ theme }) => ({
    // height: '600px',
}));

const SlickSlider = styled(Slider)(({ theme }) => ({
    '& .slick-dots': {
        bottom: 20,
        '& li.slick-active button::before': {
            color: theme.palette.primary.main,
        },
        '& li': {
            '& button::before': {
                fontSize: theme.typography.pxToRem(18),
                color: '#fff',
                opacity: 1,
            },
        },
    },
}));

const SliderContainer = styled('div')(({ theme }) => ({
    overflow: 'hidden',
}));

const SliderCommunity = styled('div')(({ theme }) => ({
    height: '600px',
    background: `url(${CommunityImage}) 70% 70% no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        height: '500px',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
        height: '450px',
    },
}));

const SliderHomeowners = styled('div')(({ theme }) => ({
    height: '600px',
    background: `url(${HomeownersImage}) 70% 70% no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        height: '500px',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
        height: '450px',
    },
}));

const SliderCommercial = styled('div')(({ theme }) => ({
    height: '600px',
    background: `url(${CommercialImage}) 70% 70% no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        height: '500px',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
        height: '450px',
    },
}));

const CardRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.97)',
    padding: '30px',
    position: 'absolute',
    top: 0,
    right: '13%',
    height: '280px',
    width: '330px',
    borderRadius: '0 0 25px 25px',
    boxShadow: '0px 1px 20px -3px rgba(0,0,0,0.4)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        height: '280px',
        width: '240px',
        left: '6%',
        top: '8%',
        borderRadius: '25px',
        padding: '20px',
    },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
    margin: '0 90px 0 0',
    [theme.breakpoints.down('sm')]: {
        fontSize: '27px',
        margin: '0 20px 0 0',
    },
}));

const CardText = styled(Typography)(({ theme }) => ({
    margin: '40px 0 auto 0',
    [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        margin: '30px 0 auto 0',
    },
}));

const CardButton = styled(Button)(({ theme }) => ({
    maxWidth: '165px',
}));

const CommunityCard = ({ history }) => {
    return (
        <CardRoot>
            <CardTitle variant="h2">
                Helping you <b>weather the storm</b>
            </CardTitle>
            <CardText variant="h4">Claim pay-outs that are fast, friendly & fair</CardText>
            <CardButton
                variant="contained"
                color="primary"
                onClick={() => history.push('/get-quote#community-insurance')}
            >
                Get a Quote
                <FontAwesomeIcon
                    style={{ marginLeft: '5px', fontSize: '18px' }}
                    icon={faPaperPlane}
                />
            </CardButton>
        </CardRoot>
    );
};

const HomeownersCard = ({ history }) => {
    return (
        <CardRoot>
            <CardTitle variant="h2">
                Come home to complete <b>peace of mind</b>
            </CardTitle>
            <CardText variant="h4">Trusted by thousands of homeowners</CardText>
            <CardButton
                variant="contained"
                color="primary"
                onClick={() => history.push('/get-quote#homeowners-insurance')}
            >
                Online Quote
                <FontAwesomeIcon
                    style={{ marginLeft: '5px', fontSize: '18px' }}
                    icon={faPaperPlane}
                />
            </CardButton>
        </CardRoot>
    );
};

const CommercialCard = ({ history }) => {
    return (
        <CardRoot>
            <CardTitle variant="h2">
                We protect what <b>you've built</b>
            </CardTitle>
            <CardText variant="h4">Over R750 billion in buildings covered across SA</CardText>
            <CardButton
                variant="contained"
                color="primary"
                onClick={() => history.push('/get-quote#commercial-insurance')}
            >
                Get a Quote
                <FontAwesomeIcon
                    style={{ marginLeft: '5px', fontSize: '18px' }}
                    icon={faPaperPlane}
                />
            </CardButton>
        </CardRoot>
    );
};

const HomeSlider = () => {
    const history = useHistory();

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        arrows: false,
    };

    return (
        <HomeSliderRoot>
            <SlickSlider {...settings}>
                <SliderContainer>
                    <SliderCommunity />
                    <CommunityCard history={history} />
                </SliderContainer>
                <SliderContainer>
                    <SliderHomeowners />
                    <HomeownersCard history={history} />
                </SliderContainer>
                <SliderContainer>
                    <SliderCommercial />
                    <CommercialCard history={history} />
                </SliderContainer>
            </SlickSlider>
        </HomeSliderRoot>
    );
};

export default HomeSlider;
