import * as Yup from 'yup';
import formModel from './formModel';

const {
    propertyLocation,
    email,
    name,
    contactNumber,
    alternativeContactNumber,
    policyNumber,
    nameOfInsured,
    unitNumber,
    comments,
} = formModel;

const validationSchema = Yup.object().shape({
    [propertyLocation.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
    [email.name]: Yup.string()
        .email('Incorrect email format')
        .required(`${email.requiredErrorMsg}`),
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [contactNumber.name]: Yup.number()
        .typeError('This field can only contain numbers.')
        .required(`${contactNumber.requiredErrorMsg}`),
    [alternativeContactNumber.name]: Yup.number().typeError('This field can only contain numbers.'),
    [policyNumber.name]: Yup.string().required(`${policyNumber.requiredErrorMsg}`),
    [nameOfInsured.name]: Yup.string().required(`${nameOfInsured.requiredErrorMsg}`),
    [unitNumber.name]: Yup.string(),
    [comments.name]: Yup.string(),
});

export default validationSchema;
