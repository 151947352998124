import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Link } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';
import CareersForm from '../../../components/forms/CareersForm';
import ClaimsNegotiator from '../../../assets/documents/job-listings/Claims negotiator 2024.pdf';

const CareersRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const CareersDesc = styled(Typography)(({ theme }) => ({
    fontStyle: 'italic',
}));

const careers_array = [
    {
        text: 'Claims Negotiator',
        document: ClaimsNegotiator,
    },
];

const Careers = () => {
    return (
        <CareersRoot>
            <Container maxWidth="lg">
                <SectionTitle
                    title="Join Our Team"
                    subtitle="Current positions available"
                    align="left"
                />
                <ul>
                    {careers_array.map((item, index) => (
                        <li key={index}>
                            <Link href={item['document']} target="_blank">
                                {item['text']}
                            </Link>
                        </li>
                    ))}
                </ul>
                <CareersDesc variant="body1">
                    Feel free to submit your details for future positions
                </CareersDesc>
                <CareersForm />
            </Container>
        </CareersRoot>
    );
};

export default Careers;
