const formModel = {
    propertyLocation: {
        name: 'propertyLocation',
        label: 'Enter Property Location*',
        requiredErrorMsg: 'Property Location is required',
    },
    email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email is required',
    },
    name: {
        name: 'name',
        label: 'Name*',
        requiredErrorMsg: 'Name is required',
    },
    contactNumber: {
        name: 'contactNumber',
        label: 'Contact Number*',
        requiredErrorMsg: 'Contact Number is required',
    },
    alternativeContactNumber: {
        name: 'alternativeContactNumber',
        label: 'Alternative Contact Number',
        requiredErrorMsg: null,
    },
    policyNumber: {
        name: 'policyNumber',
        label: 'Policy Number*',
        requiredErrorMsg: 'Policy Number is required',
    },
    nameOfInsured: {
        name: 'nameOfInsured',
        label: 'Name of insured/Complex*',
        requiredErrorMsg: 'Insured/complex namme is required',
    },
    unitNumber: {
        name: 'unitNumber',
        label: 'Unit Number',
        requiredErrorMsg: null,
    },
    comments: {
        name: 'comments',
        label: 'Comments',
        requiredErrorMsg: null,
    },
};

export default formModel;
