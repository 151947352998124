import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Collapse, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import GoogleMap from '../../../../../components/website-map/GoogleMap';
import BroadformBuildingKeyInformationDocumentApril2023 from '../../../../../assets/documents/products-key-information/commercial/Broadform-Building-Key-Information-Document-April-2023.pdf';
import BroadformHomeownersKeyInformationDocumentApril2023 from '../../../../../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Key-Information-Document-April-2023.pdf';
import BroadformBuildingInsurancePolicyApril2023 from '../../../../../assets/documents/products-key-information/commercial/Broadform-Building-Insurance-Policy-April-2023.pdf';
import BroadformHomeownersInsurancePolicyApril2023 from '../../../../../assets/documents/products-key-information/homeowners/Broadform-Homeowners-Insurance-Policy-April-2023.pdf';

const QuotationDetailsRoot = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const MapContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '200px',
    borderRadius: '5px',
    overflow: 'hidden',
}));

const QuotationResults = styled('div')(({ theme }) => ({
    marginTop: '20px',
}));

const QuotationTable = styled('table')(({ theme }) => ({
    width: '100%',
    '& strong': {
        color: '#444',
    },
    '& th': {
        padding: '10px',
    },
    '& td': {
        padding: '10px',
        width: '30%',
        border: '1px solid rgb(206, 206, 206)',
    },
    '& tbody tr:nth-child(odd)': {
        background: 'rgba(0, 0, 0, 0.05)',
    },
    '& .title': {
        backgroundColor: '#555555',
        color: '#FFFFFF',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    '& .description': {
        textAlign: 'left',
        width: '40%',
    },
    '& .premiumText': {
        backgroundColor: '#d3d3d3',
        color: '#D7412E',
        fontSize: '19px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
}));

const QuotationHeader = styled('thead')(({ theme }) => ({
    width: '100%',
}));

const QuotationBody = styled('tbody')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    // border: 'none',
    borderRight: '2px solid rgb(206, 206, 206)',
    borderLeft: '1px solid rgb(206, 206, 206)',
    borderBottom: '1px solid rgb(206, 206, 206)',
}));

const CollapseRow = styled('tr')(({ theme }) => ({
    borderBottom: '2px solid #D7412E',
    cursor: 'pointer',
    color: '#444444',
    ':hover': {
        backgroundColor: 'rgba(0,0,0,.040)',
        color: '#D7412E',
    },
}));

const CheckIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    color: 'rgb(32, 192, 32)',
    fontSize: '19px',
}));

const QuotationDetails = ({ formikProps, handleBack, handleSubmit }) => {
    const { values } = formikProps;
    const [expanded, setExpanded] = useState(true);
    const [map, setMap] = useState(null);

    const mapOptions = {
        zoom: 19,
        mapTypeId: 'hybrid',
        center: {
            lat: parseFloat(values['gpsCoordinates'].split(',')[0]),
            lng: parseFloat(values['gpsCoordinates'].split(',')[1]),
        },
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
    };

    const returnMap = (tempMap) => {
        setMap(tempMap);

        var icon = {
            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            scaledSize: new window.google.maps.Size(33, 33),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 30),
        };

        new window.google.maps.Marker({
            position: {
                lat: parseFloat(values['gpsCoordinates'].split(',')[0]),
                lng: parseFloat(values['gpsCoordinates'].split(',')[1]),
            },
            map: tempMap,
            icon: icon,
            draggable: false,
        });
    };

    return (
        <QuotationDetailsRoot>
            <MapContainer>
                <GoogleMap options={mapOptions} returnMap={returnMap} />
            </MapContainer>

            {expanded && (
                <div style={{ display: 'flex', padding: '20px 0 0' }}>
                    <Button variant="contained" color="primary" onClick={handleBack}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginLeft: 'auto' }}
                    >
                        Next
                    </Button>
                </div>
            )}

            <QuotationResults>
                <QuotationTable>
                    <QuotationHeader>
                        <tr>
                            <th></th>
                            <th className="title">Option 1: Broadform Buildings Policy</th>
                            <th className="title">Option 2: Broadform Homeowners Policy</th>
                        </tr>
                    </QuotationHeader>
                    <QuotationBody>
                        <tr>
                            <td className="description">
                                <strong>Sum Insured Value</strong>
                            </td>
                            <td>
                                <strong>
                                    R{' '}
                                    {values['sumInsredAmount']
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    R{' '}
                                    {values['sumInsredAmount']
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </strong>
                            </td>
                        </tr>
                        <tr className="premiumText">
                            <td className="description">
                                <strong>Total Monthly Premium</strong>
                            </td>
                            <td>R {values['broadformBuildingValue']}</td>
                            <td>{values['sumInsredAmount'] < 2000000 ? 'Option not available': `R ${values['broadformHomeownersValue']}`}</td>
                        </tr>
                        <CollapseRow
                            className="row-collapse"
                            onClick={() => setExpanded(!expanded)}
                        >
                            <td colSpan="3">
                                <span className="quote-showmore-text">
                                    Click to hide summary of cover
                                </span>{' '}
                                {expanded ? (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                )}
                            </td>
                        </CollapseRow>
                        <tr>
                            <td colSpan={3} style={{ border: 'none', padding: 0, margin: 0 }}>
                                <Collapse in={expanded}>
                                    <QuotationTable>
                                        <QuotationBody>
                                            <tr>
                                                <td className="description">
                                                    <strong>
                                                        All Risk cover, including, but not limited
                                                        to:
                                                    </strong>
                                                    <ul style={{ margin: 0 }}>
                                                        <li>Fire</li>
                                                        <li>Earthquake</li>
                                                        <li>Accidental damage</li>
                                                        <li>Storm, flood, hail & lightning</li>
                                                        <li>
                                                            SASRIA Cover for Riots, Public Violence
                                                            and Terrorism
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">Power surge</td>
                                                <td>R50 000</td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">
                                                    Theft without forcible and violent entry
                                                </td>
                                                <td>R15 000</td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">Geyser replacement</td>
                                                <td>Limits applicable</td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">Machinery breakdown</td>
                                                <td>Optional</td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">
                                                    Extended subsidence & landslip - sites affected
                                                    by dolomite, limestone and acid mine drainage
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <CheckIcon icon={faCheck} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">
                                                    Emergency accommodation
                                                </td>
                                                <td>-</td>
                                                <td>R1,000 a day max 7 days</td>
                                            </tr>
                                            <tr>
                                                <td className="description">
                                                    Loss of rent or alternative accommodation -
                                                    following an insured event
                                                </td>
                                                <td>Up to 25% of Sum Insured</td>
                                                <td>Up to 30% of Sum Insured</td>
                                            </tr>
                                            <tr>
                                                <td className="description">Public liability</td>
                                                <td>R50 million</td>
                                                <td>R50 million</td>
                                            </tr>
                                            <tr>
                                                <td className="description">
                                                    Summary of cover and standard excess (Key
                                                    Information Document)
                                                </td>
                                                <td>
                                                    <Link
                                                        href={
                                                            BroadformBuildingKeyInformationDocumentApril2023
                                                        }
                                                        target="_blank"
                                                    >
                                                        Open Document
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        href={
                                                            BroadformHomeownersKeyInformationDocumentApril2023
                                                        }
                                                        target="_blank"
                                                    >
                                                        Open Document
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="description">Full Policy Wording</td>
                                                <td>
                                                    <Link
                                                        href={
                                                            BroadformBuildingInsurancePolicyApril2023
                                                        }
                                                        target="_blank"
                                                    >
                                                        Open Document
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        href={BroadformHomeownersInsurancePolicyApril2023}
                                                        target="_blank"
                                                    >
                                                        Open Document
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    ( <CheckIcon icon={faCheck} /> ) Fully covered
                                                    up to the Building Sum Insured
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    All cover provided subject to policy terms &
                                                    conditions
                                                </td>
                                            </tr>
                                        </QuotationBody>
                                    </QuotationTable>
                                </Collapse>
                            </td>
                        </tr>
                    </QuotationBody>
                </QuotationTable>
            </QuotationResults>
        </QuotationDetailsRoot>
    );
};

export default QuotationDetails;
