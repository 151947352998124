import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SectionTitleRoot = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

const Title = styled('span')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 600,
    color: '#D7412E',
    paddingBottom: '7px',
    borderBottom: '3px solid #D7412E',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    margin: '20px 0',
}));

const SectionTitle = (props) => {
    const { title, subtitle, align, hideArrow } = props;

    return (
        <SectionTitleRoot style={{ textAlign: align && align }}>
            <Title variant="h3">{title}</Title>
            <Subtitle variant="h1">
                {subtitle}{' '}
                {hideArrow ? null : (
                    <FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronRight} />
                )}
            </Subtitle>
        </SectionTitleRoot>
    );
};

export default SectionTitle;
