import formModel from './formModel';

const {
    firstName,
    lastName,
    idNumber,
    email,
    contactNumber,
    fspNumber,
    companyName,
    registeredAcDevelop,
} = formModel;

const initialValues = {
    [firstName.name]: '',
    [lastName.name]: '',
    [idNumber.name]: '',
    [email.name]: '',
    [contactNumber.name]: '',
    [fspNumber.name]: '',
    [companyName.name]: '',
    [registeredAcDevelop.name]: '',
};

export default initialValues;
