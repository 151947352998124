import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <Typography variant="h1" style={{ margin: '0 auto' }}>
                    Upload an existing policy schedule
                </Typography>
                <Typography variant="body1" style={{ margin: '20px auto 0' }}>
                    and we'll send you a comparative CIA quote shortly:
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
