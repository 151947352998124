import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const QuoteSuccessRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const QuoteSuccess = () => {
    return (
        <QuoteSuccessRoot>
            <Typography variant="h2" color="#D7412E" textAlign="center" marginBottom={3}>
                Thank you for choosing CIA.
            </Typography>

            <Typography variant="h5" textAlign="center">
                One of our friendly consultants will contact you shortly to complete the
                underwriting process.
            </Typography>
        </QuoteSuccessRoot>
    );
};

export default QuoteSuccess;
