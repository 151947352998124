import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../../components/SectionTitle';

import MultitaskingIcon from '../../../assets/images/icons/multitasking.svg';
import LaptopIcon from '../../../assets/images/icons/laptop.svg';
import FindMyFriendIcon from '../../../assets/images/icons/find-my-friend.svg';
import InsurancePolicyIcon from '../../../assets/images/icons/insurance-policy.svg';
import PolicyIcon from '../../../assets/images/icons/policy.svg';
import ProcessIcon from '../../../assets/images/icons/process.svg';

const SelfAssistRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const SelfAssistItems = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const GridContainer = styled(Grid)(({ theme }) => ({
    padding: '15px 20px 15px 0',
}));

const ItemIcon = styled('img')(({ theme }) => ({
    height: '35px',
    width: '35px',
    filter: 'invert(43%) sepia(100%) saturate(3298%) hue-rotate(341deg) brightness(85%) contrast(97%)',
}));

const ItemTitle = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 500,
    fontSize: '20px',
    marginBottom: '5px',
}));

const ItemText = styled('div')(({ theme }) => ({
    color: '#666666',
    fontWeight: 500,
    fontSize: '16px',
    margin: '6px 0 16px',
}));

const GridLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
}));

const selfAssist_Array = [
    {
        title: 'Find a Broker',
        text: 'Insurance brokers are an essential part of the insurance industry and provides tons of value to their clients.',
        linkText: 'Find a broker near you',
        link: '/find-a-broker',
        icon: FindMyFriendIcon,
    },
    {
        title: 'Online Broker Training',
        text: 'We believe in the continuous professional development of our brokers and managing agents. We pride ourselves in the expert training we offer.',
        linkText: 'More info',
        link: '/online-broker-training',
        icon: LaptopIcon,
    },
    {
        title: 'Track a Claim',
        text: 'Please have your claim and policy number ready to track your claim status.',
        linkText: 'Track your claim',
        link: '/track-a-claim',
        icon: ProcessIcon,
    },
    {
        title: 'Request Insurance Certificate',
        text: 'Issue an online Insurance Certificate.',
        linkText: 'Request a certificate',
        link: '/request-insurance-certificate',
        icon: MultitaskingIcon,
    },
    {
        title: 'Broker Agency Application',
        text: 'Get the info you need to work with us.',
        linkText: 'Request your broker application',
        link: '/broker-agency-application',
        icon: InsurancePolicyIcon,
    },
    {
        title: 'Existing Policy Schedule',
        text: 'Upload your policy schedule for quick assistance.',
        linkText: 'More info',
        link: '/policy-schedule',
        icon: PolicyIcon,
    },
];

const SelfAssistOptions = () => {
    const history = useHistory();

    return (
        <SelfAssistRoot>
            <Container maxWidth="lg">
                <SectionTitle title="Self Assist Options" subtitle="Quick online self service" />

                <SelfAssistItems>
                    <Grid container>
                        {selfAssist_Array.map((item, index) => (
                            <GridContainer item xs={12} sm={6} md={4} container key={index}>
                                <Grid item xs style={{ textAlign: 'center', marginRight: '10px' }}>
                                    <ItemIcon src={item['icon']} />
                                </Grid>
                                <Grid item xs={10}>
                                    <ItemTitle className="item-title">{item['title']}</ItemTitle>
                                    <ItemText>
                                        {item['text']}{' '}
                                        <GridLink onClick={() => history.push(item['link'])}>
                                            {item['linkText']}
                                        </GridLink>
                                    </ItemText>
                                </Grid>
                            </GridContainer>
                        ))}
                    </Grid>
                </SelfAssistItems>
            </Container>
        </SelfAssistRoot>
    );
};

export default SelfAssistOptions;
