import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Container, Fade, Grid, Typography } from '@mui/material';
import SectionTitle from '../../../../components/SectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import OnlineTraining from '../../../../assets/images/online-training.jpg';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
    minHeight: '434px',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
    marginTop: '30px',
    display: 'flex',
}));

const Introduction = () => {
    const history = useHistory();

    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} order={{ sm: 2, md: 1 }}>
                        <SectionTitle
                            title="Broker Training"
                            subtitle="Free online CPD training"
                            align="left"
                            hideArrow={true}
                        />

                        <Typography variant="body1">
                            At CIA, we believe in the continuous professional development of our
                            brokers and managing agents. We pride ourselves in the expert training
                            we offer, both in person and online.
                        </Typography>

                        <Typography variant="body1" marginY={2}>
                            If you'd like to access our online platform powered by AC Develop,
                            please click <b>register</b> to sign up free of charge. Your login
                            details will be emailed to you shortly thereafter.
                        </Typography>

                        <ButtonContainer>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push('/register-online-training')}
                            >
                                Register <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    window.open(
                                        'https://acdevelop.training/App/ACDLogin.html',
                                        '_blank'
                                    )
                                }
                                style={{ marginLeft: '10px' }}
                            >
                                Access Portal <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </ButtonContainer>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        order={{ sm: 1, md: 2 }}
                        display={{ xs: 'none', sm: 'block' }}
                    >
                        <img
                            loading="lazy"
                            width={`100%`}
                            src={OnlineTraining}
                            alt="Broker Training"
                        />
                    </Grid>
                </Grid>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
