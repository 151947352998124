import React from 'react';
import { styled } from '@mui/material/styles';

import Introduction from './components/Introduction';
import GetQuoteWizard from './components/GetQuoteWizard';

const GetQuoteRoot = styled('div')(({ theme }) => ({}));

const GetQuote = () => {
    return (
        <GetQuoteRoot>
            <Introduction />

            <GetQuoteWizard />
        </GetQuoteRoot>
    );
};

export default GetQuote;
