const formModel = {
    firstName: {
        name: 'firstName',
        label: 'First Name*',
        requiredErrorMsg: 'First Name is required',
    },
    lastName: {
        name: 'lastName',
        label: 'Last Name*',
        requiredErrorMsg: 'Last Name is required',
    },
    email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email is required',
    },
};

export default formModel;
