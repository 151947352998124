import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <Typography variant="h1" style={{ margin: '0 auto' }}>
                    Extracts from the National Building Regulations
                </Typography>
                <Typography variant="body1" style={{ margin: '20px auto 0' }}>
                    First introduced in South Africa in 1977, the National Building Regulations
                    specify the minimum requirements for design and construction to which all
                    buildings must comply. The regulations have been revised and updated a number of
                    times since then and are essential to ensuring the safety and longevity of
                    buildings being constructed.
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
