const formModel = {
    policyNumber: {
        name: 'policyNumber',
        label: 'Policy Number*',
        requiredErrorMsg: 'Policy Number is required',
    },
    claimNumber: {
        name: 'claimNumber',
        label: 'Claim Number*',
        requiredErrorMsg: 'Claim Number is required',
    },
};

export default formModel;
