const formModel = {
    propertyLocation: {
        name: 'propertyLocation',
        label: 'Search Property*',
        requiredErrorMsg: 'This field is required',
    },
    postalCode: {
        name: 'postalCode',
    },
    gpsCoordinates: {
        name: 'gpsCoordinates',
    },
    broadformBuildingValue: {
        name: 'broadformBuildingValue',
    },
    broadformHomeownersValue: {
        name: 'broadformHomeownersValue',
    },
    packageOption: {
        name: 'packageOption',
        label: 'Package Option',
    },
    sumInsredAmount: {
        name: 'sumInsredAmount',
        label: 'Building Replacement Value',
    },
    propertyQuestion1: {
        name: 'propertyQuestion1',
        label: `1. Have all the buildings on the property been constructed using standard materials? (Brick / Steel / Concrete)`,
        requiredErrorMsg: 'This field is required',
    },
    propertyQuestion2: {
        name: 'propertyQuestion2',
        label: `2. Are there any lapa's or other non-standard structures (such as wooden cabins or wendy houses) larger than 10m² situated on the property?`,
        requiredErrorMsg: 'This field is required',
    },
    propertyQuestion3: {
        name: 'propertyQuestion3',
        label: `3. Is there more than 10m² of cladding attached to the outside walls of the building (For example: aluminium panels, wooden planks, fibre cement boards, etc.).`,
        requiredErrorMsg: 'This field is required',
    },
    propertyQuestion4: {
        name: 'propertyQuestion4',
        label: `4. Do any commercial activities take place on the property?`,
        requiredErrorMsg: 'This field is required',
    },
    propertyQuestion5: {
        name: 'propertyQuestion5',
        label: `5. Is the property used as a holiday residence or vacant for more than 30 days a year?`,
        requiredErrorMsg: 'This field is required',
    },
    firstname: {
        name: 'firstname',
        label: 'First Name / Insured Name*',
        requiredErrorMsg: 'This field is required',
    },
    surname: {
        name: 'surname',
        label: 'Surname',
    },
    contactNumber: {
        name: 'contactNumber',
        label: 'Contact Number*',
        requiredErrorMsg: 'This field is required',
    },
    email: {
        name: 'email',
        label: 'Email Address*',
        requiredErrorMsg: 'This field is required',
    },
    yourBroker: {
        name: 'yourBroker',
        label: 'Your Broker',
        requiredErrorMsg: 'This field is required',
    },
    publicizeOption: {
        name: 'publicizeOption',
        label: 'Where did you hear about us? ( Optional )',
        requiredErrorMsg: 'This field is required',
    },
    referralArea: {
        name: 'referralArea',
    },
};

export default formModel;
