import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

const RadioField = (props) => {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <FormControl>
            <Typography variant="h5">{props['label']}</Typography>
            <RadioGroup {...field} name={props['name']}>
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            </RadioGroup>

            <Typography variant="h6" color="error">
                {_renderHelperText()}
            </Typography>
        </FormControl>
    );
};

export default RadioField;
