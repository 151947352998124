import React from 'react';
import { styled } from '@mui/material/styles';
import AboutUsImage from '../../assets/images/about-us.jpg';
import Introduction from './components/Introduction';
import { Fade } from '@mui/material';
import { APP_FADE_TIMEOUT } from '../../constants/AppConstants';
import OurValues from './components/OurValues';
import OurPartners from './components/OurPartners';
import Careers from './components/Careers';

const AboutUsRoot = styled('div')(({ theme }) => ({}));

const AboutUsImageContainer = styled('div')(({ theme }) => ({
    height: '500px',
    background: `url(${AboutUsImage}) 70% 70% no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        height: '300px',
        background: `url(${AboutUsImage}) 50% 50% no-repeat`,
    },
}));

const AboutUs = () => {
    return (
        <Fade in={true} timeout={APP_FADE_TIMEOUT}>
            <AboutUsRoot>
                <AboutUsImageContainer />

                <Introduction />

                <OurValues />

                <OurPartners />

                <Careers />
            </AboutUsRoot>
        </Fade>
    );
};

export default AboutUs;
