import * as Yup from 'yup';
import formModel from './formModel';

const { policyNumber, claimNumber } = formModel;

const validationSchema = Yup.object().shape({
    [policyNumber.name]: Yup.string().required(`${policyNumber.requiredErrorMsg}`),
    [claimNumber.name]: Yup.string().required(`${claimNumber.requiredErrorMsg}`),
});

export default validationSchema;
