// #region Imports

// Libraries
import React from 'react';
import { useField } from 'formik';
import { Button } from '@mui/material';

// #endregion

// #region Main Component

const UploadInput = (props) => {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    return (
        <label htmlFor="upload-files">
            <input
                accept=".doc,.docx,application/pdf"
                id="upload-files"
                type="file"
                {...field}
                {...rest}
                style={{ display: 'none' }}
            />

            <Button variant="contained" component="span" style={{ marginTop: '10px' }}>
                Upload
            </Button>
        </label>
    );
};

export default UploadInput;

// #endregion
