import React from 'react';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../../components/SectionTitle';
import { Container, Grid } from '@mui/material';
import ToleranceIcon from '../../../assets/images/icons/tolerance.svg';
import RegulationIcon from '../../../assets/images/icons/regulation.svg';
import SolutionIcon from '../../../assets/images/icons/solution.svg';

const OurValuesRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const OurValuesContainer = styled(Container)(({ theme }) => ({}));

const GridContainer = styled(Grid)(({ theme }) => ({
    padding: '15px 20px 15px 0',
}));

const ItemIcon = styled('img')(({ theme }) => ({
    height: '35px',
    width: '35px',
    filter: 'invert(43%) sepia(100%) saturate(3298%) hue-rotate(341deg) brightness(85%) contrast(97%)',
}));

const ItemTitle = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 500,
    fontSize: '20px',
    marginBottom: '5px',
}));

const ItemSubtitle = styled('div')(({ theme }) => ({
    color: '#D7412E',
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '5px',
}));

const ItemText = styled('div')(({ theme }) => ({
    color: '#666666',
    fontWeight: 500,
    fontSize: '16px',
    margin: '6px 0 16px',
}));

const ourValues_Array = [
    {
        title: 'Commitment',
        subtitle: 'Dedicated to our customers',
        text: `Our customers are at the heart of everything we do. We're here for the long term.`,
        icon: ToleranceIcon,
    },
    {
        title: 'Integrity',
        subtitle: 'True to our word',
        text: `We do what we say. We're very proud to have one of the lowest Ombud overturn ratios in the industry.`,
        icon: RegulationIcon,
    },
    {
        title: 'Ability',
        subtitle: 'Experienced & skilled',
        text: 'We do things right. Over 20 years of delivering fast, efficient and personable service.',
        icon: SolutionIcon,
    },
];

const OurValues = () => {
    return (
        <OurValuesRoot>
            <SectionTitle title="Our Values" subtitle="What we stand for" />

            <OurValuesContainer maxWidth="lg">
                <Grid container>
                    {ourValues_Array.map((item, index) => (
                        <GridContainer item xs={12} sm={4} md={4} container key={index}>
                            <Grid item xs style={{ textAlign: 'center', marginRight: '10px' }}>
                                <ItemIcon src={item['icon']} />
                            </Grid>
                            <Grid item xs={10}>
                                <ItemTitle className="item-title">{item['title']}</ItemTitle>
                                <ItemSubtitle>{item['subtitle']}</ItemSubtitle>
                                <ItemText>{item['text']}</ItemText>
                            </Grid>
                        </GridContainer>
                    ))}
                </Grid>
            </OurValuesContainer>
        </OurValuesRoot>
    );
};

export default OurValues;
