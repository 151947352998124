import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    Divider,
    Fade,
    Grid,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import { links_array } from '../../../constants/AppConstants';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const NavbarMobileRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#FAFAFA',
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 82px)',
    marginTop: '-2px',
    overflowY: 'overlay',
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const MobileLinksContainer = styled('div')(({ theme }) => ({
    padding: '10px 0',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
        padding: '10px 20px',
    },
}));

const MobileLinkItem = styled('div')(({ theme }) => ({
    padding: '15px 10px',
    cursor: 'pointer',
    color: '#666666',
    ':hover': {
        color: '#D7412E',
    },
    '&.active': {
        color: '#D7412E',
    },
}));

const MobileLinkText = styled('div')(({ theme }) => ({}));

const MobileActionsContainer = styled('div')(({ theme }) => ({
    padding: '30px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const AccordionStyled = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    color: '#666666',
    border: 'none',
    ':hover': {
        color: '#D7412E',
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummaryStyled = styled((props) => (
    <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: '0 10px',
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
    padding: '0 22px 20px',
    borderTop: 'none',
}));

const NavbarMobile = (props) => {
    const { currentLink, mobileOpen, handleClick } = props;
    const history = useHistory();

    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        var pageLink = links_array.find((x) => x.items.some((x) => x.link === currentLink));
        setExpanded(
            pageLink ? pageLink.category.replace(/\s/g, '').toLowerCase() : window.location.pathname
        );
    }, [currentLink]);

    useEffect(() => {
        if (mobileOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'overlay';
        }
    }, [mobileOpen]);

    return (
        <Fade in={mobileOpen}>
            <NavbarMobileRoot>
                <Container maxWidth="sm" style={{ padding: '0 14px' }}>
                    <MobileLinksContainer>
                        <MobileLinkItem
                            className={currentLink === '/' && 'active'}
                            onClick={() => {
                                handleClick();
                                history.push('/');
                            }}
                        >
                            <MobileLinkText>Home</MobileLinkText>
                        </MobileLinkItem>

                        <Divider />

                        <MobileLinkItem
                            className={currentLink === '/about-us' && 'active'}
                            onClick={() => {
                                handleClick();
                                history.push('/about-us');
                            }}
                        >
                            <MobileLinkText>About Us</MobileLinkText>
                        </MobileLinkItem>

                        <Divider />

                        <AccordionStyled
                            expanded={expanded === 'products'}
                            onChange={handleChange('products')}
                        >
                            <AccordionSummaryStyled
                                aria-controls="products-content"
                                id="products-header"
                            >
                                <MobileLinkText>Products</MobileLinkText>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                {links_array
                                    .find((x) => x.category === 'Products')
                                    ['items'].map((link_item, index) => (
                                        <MobileLinkItem
                                            className={
                                                currentLink === link_item['link'] && 'active'
                                            }
                                            onClick={() => {
                                                handleClick();
                                                history.push(link_item['link']);
                                            }}
                                            key={index}
                                        >
                                            <MobileLinkText>{link_item['title']}</MobileLinkText>
                                        </MobileLinkItem>
                                    ))}
                            </AccordionDetailsStyled>
                        </AccordionStyled>

                        <Divider />

                        <AccordionStyled
                            expanded={expanded === 'usefulinfo'}
                            onChange={handleChange('usefulinfo')}
                        >
                            <AccordionSummaryStyled
                                aria-controls="usefulinfo-content"
                                id="usefulinfo-header"
                            >
                                <MobileLinkText>Useful Info</MobileLinkText>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                {links_array
                                    .find((x) => x.category === 'Useful Info')
                                    ['items'].map((link_item, index) => (
                                        <MobileLinkItem
                                            className={
                                                currentLink === link_item['link'] && 'active'
                                            }
                                            onClick={() => {
                                                handleClick();
                                                history.push(link_item['link']);
                                            }}
                                            key={index}
                                        >
                                            <MobileLinkText>{link_item['title']}</MobileLinkText>
                                        </MobileLinkItem>
                                    ))}
                            </AccordionDetailsStyled>
                        </AccordionStyled>

                        <Divider />

                        <AccordionStyled
                            expanded={expanded === 'selfassist'}
                            onChange={handleChange('selfassist')}
                        >
                            <AccordionSummaryStyled
                                aria-controls="selfassist-content"
                                id="selfassist-header"
                            >
                                <MobileLinkText>Self Assist</MobileLinkText>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                {links_array
                                    .find((x) => x.category === 'Self Assist')
                                    ['items'].map((link_item, index) => (
                                        <MobileLinkItem
                                            className={
                                                currentLink === link_item['link'] && 'active'
                                            }
                                            onClick={() => {
                                                handleClick();
                                                history.push(link_item['link']);
                                            }}
                                            key={index}
                                        >
                                            <MobileLinkText>{link_item['title']}</MobileLinkText>
                                        </MobileLinkItem>
                                    ))}
                            </AccordionDetailsStyled>
                        </AccordionStyled>

                        <Divider />

                        <MobileLinkItem
                            className={currentLink === '/contact-us' && 'active'}
                            onClick={() => {
                                handleClick();
                                history.push('/contact-us');
                            }}
                        >
                            <MobileLinkText>Contact Us</MobileLinkText>
                        </MobileLinkItem>

                        <MobileLinkItem
                            onClick={() => {
                                window.location.href = 'https://app.cia.co.za';
                            }}
                        >
                            <MobileLinkText>Broker Login</MobileLinkText>
                        </MobileLinkItem>

                        <Divider />

                        <MobileActionsContainer>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleClick();
                                    history.push('/claim');
                                }}
                            >
                                Claim
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    handleClick();
                                    history.push('/get-quote');
                                }}
                            >
                                Get a Quote
                            </Button>
                        </MobileActionsContainer>
                    </MobileLinksContainer>
                </Container>
            </NavbarMobileRoot>
        </Fade>
    );
};

export default NavbarMobile;
