import * as Yup from 'yup';
import formModel from './formModel';

const {
    propertyLocation,
    postalCode,
    gpsCoordinates,
    sumInsredAmount,
    propertyQuestion1,
    complexName,
    numberOfUnits,
    buildingOccupation,
    roofConstruction,
    wallConstruction,
    contactPerson,
    contactNumber,
    email,
    yourBroker,
    preferredBranch,
} = formModel;

const validationSchema = [
    Yup.object().shape({
        [propertyLocation.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [postalCode.name]: Yup.string(),
        [gpsCoordinates.name]: Yup.string(),
        [sumInsredAmount.name]: Yup.string(),
        [propertyQuestion1.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [complexName.name]: Yup.string().required(`${complexName.requiredErrorMsg}`),
        [numberOfUnits.name]: Yup.string().required(`${numberOfUnits.requiredErrorMsg}`),
        [buildingOccupation.name]: Yup.string().required(`${buildingOccupation.requiredErrorMsg}`),
        [roofConstruction.name]: Yup.string().required(`${roofConstruction.requiredErrorMsg}`),
        [wallConstruction.name]: Yup.string().required(`${wallConstruction.requiredErrorMsg}`),
    }),
    Yup.object().shape({
        [contactPerson.name]: Yup.string().required(`${contactPerson.requiredErrorMsg}`),
        [contactNumber.name]: Yup.string().required(`${contactNumber.requiredErrorMsg}`),
        [email.name]: Yup.string()
            .email('Incorrect email format')
            .required(`${email.requiredErrorMsg}`),
        [yourBroker.name]: Yup.string(),
        [preferredBranch.name]: Yup.string(),
    }),
];

export default validationSchema;
