import formModel from './formModel';

const { name, email } = formModel;

const initialValues = {
    [name.name]: '',
    [email.name]: '',
};

export default initialValues;
