import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import formModel from '../../../../../validation/get-quote/homeowners/formModel';
import InputField from '../../../../../components/forms/form-fields/InputField';

const ContactDetailsRoot = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const QuotationResults = styled('div')(({ theme }) => ({
    marginTop: '20px',
}));

const QuotationTable = styled('table')(({ theme }) => ({
    width: '100%',
    '& strong': {
        color: '#444',
    },
    '& th': {
        padding: '10px',
    },
    '& td': {
        padding: '10px',
        width: '30%',
        border: '1px solid rgb(206, 206, 206)',
    },
    '& tbody tr:nth-child(odd)': {
        background: 'rgba(0, 0, 0, 0.05)',
    },
    '& .title': {
        backgroundColor: '#555555',
        color: '#FFFFFF',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    '& .description': {
        textAlign: 'left',
        width: '40%',
    },
    '& .premiumText': {
        backgroundColor: '#d3d3d3',
        color: '#D7412E',
        fontSize: '19px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
}));

const QuotationHeader = styled('thead')(({ theme }) => ({
    width: '100%',
}));

const QuotationBody = styled('tbody')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    borderRight: '2px solid rgb(206, 206, 206)',
    borderLeft: '1px solid rgb(206, 206, 206)',
    borderBottom: '1px solid rgb(206, 206, 206)',
}));

const ConditionsContainer = styled('div')(({ theme }) => ({
    marginTop: '20px',
}));

const conditions_array = [
    {
        id: 1,
        text: `The appointment of a short term insurance broker of your choice who is a licensed financial 
        services provider.`,
    },
    {
        id: 2,
        text: `Verification and acceptance by us of information provided during the quoting process.`,
    },
    {
        id: 3,
        text: `Satisfactory claims experience as evidenced by your detailed claims history for the last 3 years.`,
    },
    {
        id: 4,
        text: `Confirmation that no previous insurer has ever turned down an application for insurance, cancelled 
        any policy or part thereof, imposed special conditions, refused renew any policy or part thereof, 
        or refused to continue any part of your insurance.`,
    },
    {
        id: 5,
        text: `The property is not a smallholding or farm.`,
    },
    {
        id: 6,
        text: `You are the owner or representative owner of the property.`,
    },
    {
        id: 7,
        text: `The property is not located within close proximity of an open water body nor is it within 
        close proximity of open bush.`,
    },
    {
        id: 8,
        text: `The terms, conditions, excesses, exclusions and provisions of the policy wording.`,
    },
    {
        id: 9,
        text: `Prices shown are valid for 31 days.`,
    },
    {
        id: 10,
        text: `For holiday homes and buildings vacant for longer than 31 days theft and malicious damage is 
        subject to the building being fitted with a radio alarm system linked to a security company that 
        provides an armed response service.`,
    },
];

const ContactDetails = (props) => {
    const { packageOption, firstname, surname, contactNumber, email, yourBroker, publicizeOption } =
        formModel;

    const { formikProps, skipQuote, conditionValue, handleCondition, conditionMessage } = props;
    return (
        <ContactDetailsRoot>
            {!skipQuote && (
                <QuotationResults>
                    <QuotationTable>
                        <QuotationHeader>
                            <tr>
                                <th></th>
                                <th className="title">Option 1: Broadform Buildings Policy</th>
                                <th className="title">Option 2: Broadform Homeowners Policy</th>
                            </tr>
                        </QuotationHeader>
                        <QuotationBody>
                            <tr>
                                <td className="description">
                                    <strong>Sum Insured Value</strong>
                                </td>
                                <td>
                                    <strong>
                                        R{' '}
                                        {formikProps['values']['sumInsredAmount']
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        R{' '}
                                        {formikProps['values']['sumInsredAmount']
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </strong>
                                </td>
                            </tr>
                            <tr className="premiumText">
                                <td className="description">
                                    <strong>Total Monthly Premium</strong>
                                </td>
                                <td>R {formikProps['values']['broadformBuildingValue']}</td>
                                <td>{formikProps['values']['sumInsredAmount'] < 2000000 ? 'Option not available' : `R ${formikProps['values']['broadformHomeownersValue']}`}</td>
                            </tr>
                        </QuotationBody>
                    </QuotationTable>
                </QuotationResults>
            )}

            <Grid container spacing={6}>
                {skipQuote && (
                    <Grid item xs={12}>
                        <Typography variant="h5" textAlign="center" marginTop={3}>
                            Thank you for choosing CIA. Due to your specific requirements we need
                            additional information in order to provide you with a quote.
                        </Typography>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography variant="h6" textAlign="center" marginTop={3}>
                        Please provide your contact details below and one of our friendly staff
                        members will contact you shortly.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="package-select-label">{packageOption['label']}</InputLabel>
                        <Select
                            labelId="package-select-label"
                            id="package-select"
                            value={formikProps['values']['sumInsredAmount'] < 2000000 ? 'Broadform Buildings' : formikProps['values']['packageOption']}
                            label={packageOption['label']}
                            size="small"
                            variant="standard"
                            readOnly={formikProps['values']['sumInsredAmount'] < 2000000}
                            defaultValue={formikProps['values']['packageOption']}
                            onChange={(event) =>
                                formikProps.setFieldValue('packageOption', event.target.value)
                            }
                        >
                            <MenuItem value="Broadform Buildings">
                                Option 1: Broadform Buildings Policy
                            </MenuItem>
                            <MenuItem value="Broadform Homeowners">
                                Option 2: Broadform Homeowners Policy
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name={firstname.name} label={firstname.label} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name={surname.name} label={surname.label} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name={contactNumber.name} label={contactNumber.label} fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputField name={email.name} label={email.label} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <InputField name={yourBroker.name} label={yourBroker.label} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="publicize-select-label">
                            {publicizeOption['label']}
                        </InputLabel>
                        <Select
                            labelId="publicize-select-label"
                            id="publicize-select"
                            value={formikProps['values']['publicizeOption']}
                            label={publicizeOption['label']}
                            size="small"
                            variant="standard"
                            onChange={(event) =>
                                formikProps.setFieldValue('publicizeOption', event.target.value)
                            }
                        >
                            <MenuItem value="The Home Channel">The Home Channel</MenuItem>
                            <MenuItem value="Facebook">Facebook</MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Broker">Broker</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>
                            <MenuItem value="LivingSpace Magizine">LivingSpace Magizine</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <ConditionsContainer>
                <Typography variant="h3" marginTop={5} color="#D7412E">
                    Please take note
                </Typography>
                <Typography variant="body1" marginY={2}>
                    This indicative web quotation is subject to the following:
                </Typography>
                <ol>
                    {conditions_array.map((item, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                            <Typography variant="body1">{item['text']}</Typography>
                        </li>
                    ))}
                </ol>
            </ConditionsContainer>

            {conditionMessage && (
                <Typography variant="body1" color="error">
                    {conditionMessage}
                </Typography>
            )}
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            checked={conditionValue}
                            onChange={handleCondition}
                        />
                    }
                    label="I agree to the above conditions"
                />
            </FormGroup>
        </ContactDetailsRoot>
    );
};

export default ContactDetails;
