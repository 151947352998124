import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const IntroImage = styled('img')(({ theme }) => ({
    width: '100%',
    boxShadow: '0 2px 10px -2px rgb(0 0 0 / 60%)',
    [theme.breakpoints.up('lg')]: {
        height: '100%',
    },
}));

const Introduction = ({ content }) => {
    const history = useHistory();

    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <IntroImage src={content['image']} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h1">{content['title']}</Typography>

                        <Typography variant="body1" marginTop={3}>
                            {content['paragraph_one']}
                        </Typography>

                        <Typography variant="body1" marginY={2}>
                            {content['paragraph_two']}
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push(content['btnUrl'])}
                        >
                            {content['btnText']}
                            <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '3px' }} />
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
