import * as Yup from 'yup';
import formModel from './formModel';

const { name, email } = formModel;

const validationSchema = Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [email.name]: Yup.string()
        .email('Incorrect email format')
        .required(`${email.requiredErrorMsg}`),
});

export default validationSchema;
