const formModel = {
    firstName: {
        name: 'firstName',
        label: 'First Name*',
        requiredErrorMsg: 'First Name is required',
    },
    lastName: {
        name: 'lastName',
        label: 'Last Name*',
        requiredErrorMsg: 'Last Name is required',
    },
    idNumber: {
        name: 'idNumber',
        label: 'ID Number (for CPD Purposes)*',
        requiredErrorMsg: 'ID Number is required',
    },
    email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email is required',
    },
    contactNumber: {
        name: 'contactNumber',
        label: 'Contact Number*',
        requiredErrorMsg: 'Contact Number is required',
    },
    fspNumber: {
        name: 'fspNumber',
        label: 'FSP Number*',
        requiredErrorMsg: 'FSP Number is required',
    },
    companyName: {
        name: 'companyName',
        label: 'Company Name*',
        requiredErrorMsg: 'Company Name is required',
    },
    registeredAcDevelop: {
        name: 'registeredAcDevelop',
        label: 'Are you already registerd with AC Develop?:',
        requiredErrorMsg: 'Please select an option.',
    },
};

export default formModel;
