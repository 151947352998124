import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import { links_array } from '../../../../constants/AppConstants';

const MegaMenuRoot = styled('div')(({ theme }) => ({
    padding: '24px 0',
}));

const MegaMenuItem = styled(Grid)(({ theme }) => ({
    minHeight: '122px',
    borderRadius: '1rem',
    padding: '15px 20px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#FDFDFD',
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.04)',
        '& .item-title': {
            color: '#D7412E',
        },
    },
}));

const ItemIcon = styled('img')(({ theme }) => ({
    height: '35px',
    width: '35px',
    filter: 'invert(43%) sepia(100%) saturate(3298%) hue-rotate(341deg) brightness(85%) contrast(97%)',
}));

const ItemTitle = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 600,
    fontSize: '15px',
}));

const ItemText = styled('div')(({ theme }) => ({
    color: '#5F5F5F',
    fontWeight: 500,
    fontSize: '14px',
    margin: '6px 0 16px',
}));

const SelfAssistMenu = () => {
    const history = useHistory();

    return (
        <MegaMenuRoot>
            <Container maxWidth="lg">
                <Grid container>
                    {links_array
                        .find((x) => x.category === 'Self Assist')
                        ['items'].map((link_item, index) => (
                            <MegaMenuItem
                                item
                                xs={4}
                                container
                                key={index}
                                onClick={() => history.push(link_item['link'])}
                            >
                                <Grid item xs>
                                    <ItemIcon src={link_item['icon']} />
                                </Grid>
                                <Grid item xs={10}>
                                    <ItemTitle className="item-title">
                                        {link_item['title']}
                                    </ItemTitle>
                                    <ItemText>{link_item['text']}</ItemText>
                                </Grid>
                            </MegaMenuItem>
                        ))}
                </Grid>
            </Container>
        </MegaMenuRoot>
    );
};

export default SelfAssistMenu;
