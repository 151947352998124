// #region Imports

import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import AxiosRequest from '../../../../../../utils/axios/AxiosRequest';
import {
    Button,
    CircularProgress,
    Fade,
    Link,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import formModel from '../../../../../../validation/get-quote/commercial/formModel';
import initialValues from '../../../../../../validation/get-quote/commercial/initialValues';
import validationSchema from '../../../../../../validation/get-quote/commercial/validationSchema';
import PropertyDetails from './PropertyDetails';
import ContactDetails from './ContactDetails';
import QuoteSuccess from '../QuoteSuccess';
import RecaptchaField from '../../../../../../components/recaptcha/RecaptchaField';

// #endregion

// #region Imports

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const RecaptchaText = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    marginTop: '20px',
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D7412E',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D7412E',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#bcbcc4',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#bcbcc4',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#D7412E',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#D7412E',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

// #endregion

const steps = ['Property Details', 'Contact Details'];

const CommercialWizard = () => {
    const [formLoading, setFormLoading] = useState(false);
    const [formMessage, setFormMessage] = useState(null);
    const [activeValidation, setActiveValidation] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const fieldRef = useRef(null);
    const recaptchaRef = useRef();

    async function _submitForm(values, actions) {
        const token = await recaptchaRef.current.executeAsync();
        values['token'] = token;

        var result = await AxiosRequest.post('/api/getquote/commercial', values);

        console.log(result);

        setFormLoading(false);

        if ('isSuccessful' in result && !result['isSuccessful']) {
            setFormMessage(result['message']);
            window.scrollTo(0, fieldRef.current.offsetTop - 100);
            actions.setSubmitting(false);
            return false;
        }

        window.scrollTo(0, fieldRef.current.offsetTop - 100);
        actions.setSubmitting(false);

        setActiveStep(activeStep + 1);
        setActiveValidation(false);
    }

    async function _handleSubmit(values, actions) {
        if (isLastStep) {
            setFormLoading(true);
            _submitForm(values, actions);
        } else {
            window.scrollTo(0, fieldRef.current.offsetTop - 100);

            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
            actions.setTouched({});
        }
    }

    function _handleBack() {
        window.scrollTo(0, fieldRef.current.offsetTop - 100);
        setActiveStep(activeStep - 1);
    }

    const _renderStepContent = (step, rest) => {
        switch (step) {
            case 0:
                return <PropertyDetails formikProps={rest} activeValidation={activeValidation} />;
            case 1:
                return <ContactDetails formikProps={rest} />;
            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <Fade in={true}>
            <div>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<QontoConnector />}
                    ref={fieldRef}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Fragment>
                    {activeStep === steps.length ? (
                        <QuoteSuccess />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidationSchema}
                            onSubmit={_handleSubmit}
                            validateOnChange={activeValidation}
                            validateOnBlur={activeValidation}
                        >
                            {({ isSubmitting, ...rest }) => (
                                <Form id="commercial-quote">
                                    <RecaptchaField recaptchaRef={recaptchaRef} />

                                    {formMessage && (
                                        <Typography
                                            color="error"
                                            variant="h5"
                                            textAlign="center"
                                            marginTop={7}
                                        >
                                            {formMessage}
                                        </Typography>
                                    )}

                                    {_renderStepContent(
                                        activeStep,
                                        rest,
                                        _handleBack,
                                        _handleSubmit
                                    )}

                                    <RecaptchaText variant="body1" textAlign="center">
                                        This site is protected by reCAPTCHA and the{' '}
                                        <Link
                                            href="https://policies.google.com/privacy"
                                            target="_blank"
                                        >
                                            Google Privacy Policy
                                        </Link>{' '}
                                        and{' '}
                                        <Link
                                            href="https://policies.google.com/terms"
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </Link>{' '}
                                        apply.
                                    </RecaptchaText>

                                    <ButtonContainer>
                                        {activeStep !== 0 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={_handleBack}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Button
                                                disabled={isSubmitting || formLoading}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setActiveValidation(true);
                                                }}
                                            >
                                                {isSubmitting || formLoading ? (
                                                    <CircularProgress size={24} />
                                                ) : isLastStep ? (
                                                    'Submit'
                                                ) : (
                                                    'Next'
                                                )}
                                            </Button>
                                        </div>
                                    </ButtonContainer>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Fragment>
            </div>
        </Fade>
    );
};

export default CommercialWizard;
