import axios from 'axios';
import { AxiosOptions } from '../AxiosOptions';

/**
 * @param {object} request_options Object
 */
const AxiosBase = async (request_options) => {
    let options = {
        ...AxiosOptions,
        ...request_options,
    };

    try {
        let response = await axios(options);
        let responseOK = response && response.status === 200;

        if (responseOK) {
            let data = await response.data;
            return data;
        }

        return;
    } catch (error) {
        // Error
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            if (error.response.status === 401) {
                window.location.replace = 'authentication/login';

                throw Object.assign(new Error('Unauthorized access'), {
                    code: error.response.status,
                });
            }
            if (error.response.status === 404) {
                throw Object.assign(new Error('Resource not found'), {
                    code: error.response.status,
                });
            }
            const data = error['response']['data'];
            const status = error['response']['status'];
            const header = error['response']['headers'];

            return { error, data, status, header };
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            const request = error['request'];
            return { error, request };
        } else {
            // Something happened in setting up the request and triggered an Error
            const message = error['message'];
            return { error, message };
        }
    }
};

export default AxiosBase;
