import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, IconButton, Typography } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';
import NewsletterForm from '../../../components/forms/NewsletterForm';

const NewsletterRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const NewsletterDesc = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '1200px',
    padding: '0 30px',
}));

const Newsletter = () => {
    return (
        <NewsletterRoot>
            <SectionTitle title="Stay Updated" subtitle="Our Newsletter" />

            <NewsletterDesc variant="body1">
                Join more than 12,000 subscribers and stay up to date with "must know" insurance
                topics, advice from the experts, industry related news and special announcements.
            </NewsletterDesc>

            <NewsletterForm />
        </NewsletterRoot>
    );
};

export default Newsletter;
