import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Collapse, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../../components/SectionTitle';

//#region

const WhatsCoveredRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
}));

const IconContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
}));

const ItemIcon = styled('img')(({ theme }) => ({
    height: '40px',
    width: '40px',
    marginRight: '10px',
    filter: 'invert(43%) sepia(100%) saturate(3298%) hue-rotate(341deg) brightness(85%) contrast(97%)',
}));

const TextContainer = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 600,
    fontSize: '15px',
}));

const ItemTitle = styled('div')(({ theme }) => ({
    color: '#D7412E',
    fontWeight: 600,
    fontSize: '20px',
}));

const ItemText = styled('div')(({ theme }) => ({
    color: '#666666',
    fontWeight: 500,
    fontSize: '16px',
    paddingRight: '30px',
}));

//#endregion

const WhatsCovered = ({ icon_items }) => {
    const [expand, setExpand] = useState(false);

    if (icon_items.length > 8) {
        return (
            <WhatsCoveredRoot>
                <Container maxWidth="lg">
                    <SectionTitle subtitle="What's Covered?" hideArrow />

                    <br />

                    <Grid container spacing={6}>
                        {icon_items.slice(0, 8).map((icon_item, index) => (
                            <IconContainer item xs={12} sm={6} key={index}>
                                <ItemIcon src={icon_item['icon']} />
                                <TextContainer>
                                    <ItemTitle>{icon_item['title']}</ItemTitle>
                                    <ItemText>{icon_item['text']}</ItemText>
                                </TextContainer>
                            </IconContainer>
                        ))}
                    </Grid>

                    <Collapse in={expand} style={{ width: '100%', margin: '48px 0' }}>
                        <Grid container spacing={6}>
                            {icon_items.slice(8, icon_items.length).map((icon_item, index) => (
                                <IconContainer item xs={12} sm={6} key={index}>
                                    <ItemIcon src={icon_item['icon']} />
                                    <TextContainer>
                                        <ItemTitle>{icon_item['title']}</ItemTitle>
                                        <ItemText>{icon_item['text']}</ItemText>
                                    </TextContainer>
                                </IconContainer>
                            ))}
                        </Grid>
                    </Collapse>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: expand ? '0px' : '-48px',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setExpand(!expand)}
                            style={{ margin: '0 auto' }}
                        >
                            {expand ? 'View Less' : 'View More'}
                        </Button>
                    </div>

                    <Typography variant="body1" textAlign={`center`} marginTop={4}>
                        Download our key information document below to learn more
                    </Typography>
                </Container>
            </WhatsCoveredRoot>
        );
    } else {
        return (
            <WhatsCoveredRoot>
                <Container maxWidth="lg">
                    <SectionTitle subtitle="What's Covered?" hideArrow />

                    <br />

                    <Grid container spacing={6}>
                        {icon_items.map((icon_item, index) => (
                            <IconContainer item xs={12} sm={6} key={index}>
                                <ItemIcon src={icon_item['icon']} />
                                <TextContainer>
                                    <ItemTitle>{icon_item['title']}</ItemTitle>
                                    <ItemText>{icon_item['text']}</ItemText>
                                </TextContainer>
                            </IconContainer>
                        ))}
                    </Grid>

                    <Typography variant="body1" textAlign={`center`} marginTop={6}>
                        Download our key information document below to learn more
                    </Typography>
                </Container>
            </WhatsCoveredRoot>
        );
    }
};

export default WhatsCovered;
