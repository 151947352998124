// #region Imports

import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import AxiosRequest from '../../../../../../utils/axios/AxiosRequest';
import {
    Button,
    CircularProgress,
    Fade,
    Link,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import formModel from '../../../../../../validation/get-quote/homeowners/formModel';
import initialValues from '../../../../../../validation/get-quote/homeowners/initialValues';
import validationSchema from '../../../../../../validation/get-quote/homeowners/validationSchema';
import PropertyDetails from './PropertyDetails';
import QuotationDetails from './QuotationDetails';
import ContactDetails from './ContactDetails';
import QuoteSuccess from '../QuoteSuccess';
import RecaptchaField from '../../../../../../components/recaptcha/RecaptchaField';

// #endregion

// #region Imports

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const RecaptchaText = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    marginTop: '20px',
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D7412E',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D7412E',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#bcbcc4',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#bcbcc4',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#D7412E',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#D7412E',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

// #endregion

const steps = ['Property Details', 'Quotation Details', 'Contact Details'];

const HomeownersWizard = () => {
    const [formLoading, setFormLoading] = useState(false);
    const [formMessage, setFormMessage] = useState(null);
    const [activeValidation, setActiveValidation] = useState(false);
    const [skipQuote, setSkipQuote] = useState(false);
    const [conditionValue, setConditionValue] = useState(false);
    const [conditionMessage, setConditionMessage] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const fieldRef = useRef(null);
    const recaptchaRef = useRef();

    async function _submitForm(values, actions) {
        const token = await recaptchaRef.current.executeAsync();
        values['token'] = token;

        var result = await AxiosRequest.post('/api/getquote/homeowners', values);

        setFormLoading(false);

        if ('isSuccessful' in result && !result['isSuccessful']) {
            setFormMessage(result['message']);
            window.scrollTo(0, fieldRef.current.offsetTop - 100);
            actions.setSubmitting(false);
            return false;
        }

        window.scrollTo(0, fieldRef.current.offsetTop - 100);
        actions.setSubmitting(false);

        setActiveStep(activeStep + 1);
        setActiveValidation(false);
    }

    async function _handleSubmit(values, actions) {
        if (isLastStep) {
            if (conditionValue) {
                setFormLoading(true);
                _submitForm(values, actions);
            }
        } else {
            window.scrollTo(0, fieldRef.current.offsetTop - 100);

            if (activeStep + 1 === 1) {
                var referral_result = await AxiosRequest.post('/api/getquote', {
                    lat: parseFloat(values['gpsCoordinates'].split(',')[0]),
                    lng: parseFloat(values['gpsCoordinates'].split(',')[1]),
                });
                actions.setFieldValue('referralArea', referral_result.toString());

                var premium_result = await AxiosRequest.get(
                    `/api/getquote/${values['sumInsredAmount']}/${values['propertyQuestion5']}`
                );

                actions.setFieldValue(
                    'broadformHomeownersValue',
                    premium_result['homeownersValue'].toString()
                );
                actions.setFieldValue(
                    'broadformBuildingValue',
                    premium_result['buildingsValue'].toString()
                );

                if (
                    referral_result ||
                    values['propertyQuestion1'].toLowerCase() === 'no' ||
                    values['propertyQuestion2'].toLowerCase() === 'yes' ||
                    values['propertyQuestion3'].toLowerCase() === 'yes' ||
                    values['propertyQuestion4'].toLowerCase() === 'yes'
                ) {
                    setSkipQuote(true);
                    setActiveStep(2);
                    actions.setSubmitting(false);
                    actions.setTouched({});
                    return;
                }
            }

            if (activeStep + 1 === 2) {
                if (activeValidation && !conditionValue) {
                    setConditionMessage('*Please check the terms and conditions');
                    actions.setSubmitting(false);
                    actions.setTouched({});
                }
            }

            setSkipQuote(false);
            setActiveStep(activeStep + 1);
            actions.setSubmitting(false);
            actions.setTouched({});
        }
    }

    function _handleBack() {
        window.scrollTo(0, fieldRef.current.offsetTop - 100);

        if (activeStep - 1 === 1 && skipQuote) {
            setActiveStep(0);
            return;
        }

        setActiveStep(activeStep - 1);
    }

    const _renderStepContent = (step, rest) => {
        switch (step) {
            case 0:
                return <PropertyDetails formikProps={rest} activeValidation={activeValidation} />;
            case 1:
                return (
                    <QuotationDetails
                        formikProps={rest}
                        handleBack={_handleBack}
                        handleSubmit={_handleSubmit}
                    />
                );
            case 2:
                return (
                    <ContactDetails
                        formModel={formModel}
                        formikProps={rest}
                        skipQuote={skipQuote}
                        conditionValue={conditionValue}
                        handleCondition={(event) => {
                            if (activeValidation && !event.target.checked) {
                                setConditionMessage('*Please check the terms and conditions');
                            } else {
                                setConditionMessage(null);
                            }

                            setConditionValue(event.target.checked);
                        }}
                        conditionMessage={conditionMessage}
                    />
                );
            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <Fade in={true}>
            <div>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<QontoConnector />}
                    ref={fieldRef}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Fragment>
                    {activeStep === steps.length ? (
                        <QuoteSuccess />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidationSchema}
                            onSubmit={_handleSubmit}
                            validateOnChange={activeValidation}
                            validateOnBlur={activeValidation}
                        >
                            {({ isSubmitting, ...rest }) => (
                                <Form id="homeowners-quote">
                                    <RecaptchaField recaptchaRef={recaptchaRef} />

                                    {formMessage && (
                                        <Typography
                                            color="error"
                                            variant="h5"
                                            textAlign="center"
                                            marginTop={7}
                                        >
                                            {formMessage}
                                        </Typography>
                                    )}

                                    {_renderStepContent(
                                        activeStep,
                                        rest,
                                        _handleBack,
                                        _handleSubmit
                                    )}

                                    <RecaptchaText variant="body1" textAlign="center">
                                        This site is protected by reCAPTCHA and the{' '}
                                        <Link
                                            href="https://policies.google.com/privacy"
                                            target="_blank"
                                        >
                                            Google Privacy Policy
                                        </Link>{' '}
                                        and{' '}
                                        <Link
                                            href="https://policies.google.com/terms"
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </Link>{' '}
                                        apply.
                                    </RecaptchaText>

                                    <ButtonContainer>
                                        {activeStep !== 0 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={_handleBack}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Button
                                                disabled={isSubmitting || formLoading}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setActiveValidation(true);

                                                    if (activeStep === 2 && !conditionValue) {
                                                        setConditionMessage(
                                                            '*Please check the terms and conditions'
                                                        );
                                                    }
                                                }}
                                            >
                                                {isSubmitting || formLoading ? (
                                                    <CircularProgress size={24} />
                                                ) : isLastStep ? (
                                                    'Submit'
                                                ) : (
                                                    'Next'
                                                )}
                                            </Button>
                                        </div>
                                    </ButtonContainer>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Fragment>
            </div>
        </Fade>
    );
};

export default HomeownersWizard;
