import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import initialValues from '../../validation/request-insurance-certificate/initialValues';
import validationSchema from '../../validation/request-insurance-certificate/validationSchema';
import formModel from '../../validation/request-insurance-certificate/formModel';
import InputField from './form-fields/InputField';
import WebsiteForms from './WebsiteForms';

const RequestInsuranceCertificateFormRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const RequestInsuranceCertificateForm = () => {
    const {
        attorneysName,
        attorneysPostalAddress,
        attorneysTel,
        attorneysContactPerson,
        email,
        ownerName,
        bodyCorporateName,
        sections,
        ssNumber,
        sumInsured,
        bankName,
        bankAddress,
    } = formModel;

    return (
        <RequestInsuranceCertificateFormRoot>
            <WebsiteForms
                formUrl="api/forms/insurancecertificate"
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name={attorneysName.name}
                            label={attorneysName.label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name={attorneysPostalAddress.name}
                            label={attorneysPostalAddress.label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={attorneysTel.name} label={attorneysTel.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name={attorneysContactPerson.name}
                            label={attorneysContactPerson.label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={email.name} label={email.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={ownerName.name} label={ownerName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name={bodyCorporateName.name}
                            label={bodyCorporateName.label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={sections.name} label={sections.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={ssNumber.name} label={ssNumber.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={sumInsured.name} label={sumInsured.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={bankName.name} label={bankName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={bankAddress.name} label={bankAddress.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            As soon as CIA receives and processes this request, our system will
                            generate documentation including an invoice for the cost of the
                            certificate which will be sent to the email above. As soon as the proof
                            of payment is received, an insurance certificate will be sent to
                            yourselves as instructed above
                        </Typography>
                    </Grid>
                </Grid>
            </WebsiteForms>
        </RequestInsuranceCertificateFormRoot>
    );
};

export default RequestInsuranceCertificateForm;
