import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import SectionTitle from '../../../../components/SectionTitle';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <SectionTitle
                    subtitle="When will I need an insurance certificate?"
                    hideArrow={true}
                />

                <Typography variant="body1" marginY={2}>
                    Insurance Certificates are issued for bonded Sectional Title units. The
                    mortgagee, being the lending bank will request an insurance certificate before
                    registering a bond over a new property. The certificate serves as confirmation
                    of insurance for a specific unit, at a specified value and notes the
                    bondholder’s interests in the specific unit.
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
