// Libraries
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Loader } from '@googlemaps/js-api-loader';

// Analytics
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

import Navbar from '../components/navigation/navbar/Navbar';
import Footer from '../components/navigation/footer/Footer';
import { links_array } from '../constants/AppConstants';

const AppRoot = styled('div')(({ theme }) => ({}));

const AppContent = styled('div')(({ theme }) => ({
    paddingTop: '81px',
    paddingRight: '15px',
}));

const AppFooter = styled('div')(({ theme }) => ({}));

const Layout = (props) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-Q8X5D4ZFRF');
        hotjar.initialize(2105651, 6);
        hotjar.identify('USER_ID', { userProperty: 'value' });
    }, []);

    useEffect(() => {
        const category = links_array.find((x) => x.items.find((y) => y.link === location.pathname));
        let title, link;

        if (category) {
            title = category['items'].find((x) => x.link === location.pathname)['title'];
            link = category['items'].find((x) => x.link === location.pathname)['link'];
        }

        window.document.title = `${title} | CIA Building Insurance Specialists`;

        ReactGA.send({ hitType: 'pageview', page: link });

        if (hotjar.initialized()) {
            // Hotjar page change
            hotjar.stateChange(link);
        }
    }, [location]);

    useEffect(() => {
        // Live - AIzaSyBTEEh4unvCP6EDHrGfun2eg-jqjNL1WMI
        // Testing - AIzaSyCQMQ11q1LQcg7Ps-wYJS6qsKltOxFlQvw

        const loader = new Loader({
            apiKey: `AIzaSyCQMQ11q1LQcg7Ps-wYJS6qsKltOxFlQvw`,
            version: 'weekly',
            libraries: ['places', 'geometry'],
        });

        loader.load();
    }, []);

    return (
        <AppRoot>
            <div>
                <Navbar />
            </div>
            <AppContent>{props.children}</AppContent>
            <AppFooter>
                <Footer />
            </AppFooter>
        </AppRoot>
    );
};

export default Layout;
