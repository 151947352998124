import React, { useEffect, useRef, useState, Fragment, useLayoutEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';

const GoogleMapRoot = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const MapContainer = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
}));

const MapLoader = styled(CircularProgress)(({ theme }) => ({
    position: 'absolute',
}));

const GoogleMap = ({ markers, branches, returnMap, options }) => {
    const [ready, setReady] = useState(false);
    const infoWindow = useRef(null);
    const map = useRef(null);

    const addMap = async () => {
        if (!map.current && window.google && document.getElementById('map-container')) {
            map.current = new window.google.maps.Map(document.getElementById('map-container'), {
                zoom: options && 'zoom' in options ? options['zoom'] : 6,
                center:
                    options && 'center' in options
                        ? options['center']
                        : { lat: -27.675162, lng: 24.845158 },
                mapTypeId: options && 'mapTypeId' in options ? options['mapTypeId'] : 'roadmap',
                zoomControl: options && 'zoomControl' in options ? options['zoomControl'] : true,
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                streetViewControl:
                    options && 'streetViewControl' in options ? options['streetViewControl'] : true,
                streetViewControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                mapTypeControl:
                    options && 'mapTypeControl' in options ? options['mapTypeControl'] : true,
                mapTypeControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                scaleControl: options && 'scaleControl' in options ? options['scaleControl'] : true,
                rotateControl:
                    options && 'rotateControl' in options ? options['rotateControl'] : true,
                fullscreenControl: false,
                draggableCursor: 'default',
                tilt: 0,
                // gestureHandling: 'greedy',
            });

            returnMap && returnMap(map.current);
            setReady(true);
        }
    };

    useEffect(() => {
        async function StartFunc() {
            await addMap();

            var myInterval;
            myInterval = setInterval(() => {
                if (!map.current && window.google) {
                    addMap();

                    if (map.current) {
                        window.clearInterval(myInterval);
                    }
                } else {
                    window.clearInterval(myInterval);
                }
            }, 1000);
        }

        StartFunc();

        return () => {
            map.current = null;
        };
    }, []);

    useEffect(() => {
        if (window.google && map.current && markers) {
            if (!infoWindow.current) {
                infoWindow.current = new window.google.maps.InfoWindow();
            }

            var icon = {
                url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                scaledSize: new window.google.maps.Size(22, 22),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(10, 10),
            };

            markers.forEach((marker_item) => {
                if (typeof marker_item['location_Lat'] === 'number') {
                    var newMarker;

                    try {
                        newMarker = new window.google.maps.Marker({
                            position: {
                                lat: marker_item['location_Lat'],
                                lng: marker_item['location_Long'],
                            },
                            title: marker_item['display_Name'],
                            data: marker_item,
                            map: map.current,
                            icon: icon,
                            draggable: false,
                        });
                    } catch (error) {
                        console.log(marker_item);
                        console.log(error);
                    }

                    newMarker.addListener('click', function () {
                        console.log(newMarker['data']);

                        infoWindow.current.setContent(
                            `<div style="max-width: 320px">
                                <div style="background-color: #D7412E; font-size: 16px; font-weight: 500; color: #FFF; padding: 10px 40px 10px 10px">
                                    ${newMarker['data']['display_Name']}
                                </div>
                                <div style="font-size: 14px; color: #474747; padding: 10px">
                                    <p style="margin: 0 0 3px"><b>Address:</b></p>
                                    <span style="overflow-wrap: break-word; font-size: 15px;">${newMarker['data']['address']}</span>
                                    
                                    <p style="margin: 15px 0 3px"><b>Telephone:</b></p>
                                    <a style="overflow-wrap: break-word; font-size: 15px; color: #D7412E" href="tel:${newMarker['data']['telNo']}">${newMarker['data']['telNo']}</a>
                                    
                                    <p style="margin: 15px 0 3px"><b>Mobile:</b></p>
                                    <a style="overflow-wrap: break-word; font-size: 15px; color: #D7412E" href="tel:${newMarker['data']['cellNo']}">${newMarker['data']['cellNo']}</a>
                                    
                                    <p style="margin: 15px 0 3px"><b>Email:</b></p>
                                    <a style="overflow-wrap: break-word; font-size: 15px; color: #D7412E" href="mailto:${newMarker['data']['email']}">${newMarker['data']['email']}</a>
                                </div>
                            </div>`
                        );
                        infoWindow.current.setPosition(newMarker['position']);
                        infoWindow.current.open(map.current);
                    });
                }

                if (typeof marker_item['location_Lat'] !== 'number') {
                    // console.log(marker_item);
                }
            });
        }
    }, [markers, map.current]);

    useEffect(() => {
        if (window.google && map.current && branches) {
            if (!infoWindow.current) {
                infoWindow.current = new window.google.maps.InfoWindow();
            }

            var icon = {
                url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                scaledSize: new window.google.maps.Size(22, 22),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(10, 10),
            };

            branches.forEach((marker_item) => {
                if (typeof marker_item['lat'] === 'number') {
                    var newMarker;

                    try {
                        newMarker = new window.google.maps.Marker({
                            position: {
                                lat: marker_item['lat'],
                                lng: marker_item['lng'],
                            },
                            title: marker_item['title'],
                            data: marker_item,
                            map: map.current,
                            icon: icon,
                            draggable: false,
                        });
                    } catch (error) {
                        console.log(marker_item);
                        console.log(error);
                    }

                    newMarker.addListener('mouseover', function () {
                        console.log(newMarker['data']);

                        infoWindow.current.setContent(
                            `<div style="min-width: 300px; max-width: 450px">
                                <div style="background-color: #D7412E; font-size: 16px; font-weight: 500; color: #FFF; padding: 10px 40px 10px 10px">
                                    ${newMarker['data']['title']}
                                </div>
                                <div style="font-size: 14px; color: #474747; padding: 10px">
                                <p style="margin: 0px 0 3px"><b>${
                                    newMarker['data']['firstContact'].split(':')[0]
                                }:</b></p>
                                    <span style="overflow-wrap: break-word; font-size: 15px">${
                                        newMarker['data']['firstContact'].split(':')[1]
                                    }</span>
                                    
                                    <p style="margin: 15px 0 3px"><b>Email:</b></p>
                                    <a style="overflow-wrap: break-word; font-size: 15px; color: #D7412E" href="mailto:${
                                        newMarker['data']['email']
                                    }">${newMarker['data']['email']}</a>
                                    
                                    <p style="margin: 15px 0 3px"><b>Telephone:</b></p>
                                    <a style="overflow-wrap: break-word; font-size: 15px; color: #D7412E" href="tel:${
                                        newMarker['data']['telephone']
                                    }">${newMarker['data']['telephone']}</a>
                                    
                                    <p style="margin: 15px 0 3px"><b>Physical Address:</b></p>
                                    <span style="overflow-wrap: break-word; font-size: 15px;">${
                                        newMarker['data']['address']
                                    }</span>
                                </div>
                            </div>`
                        );
                        infoWindow.current.setPosition(newMarker['position']);
                        infoWindow.current.open(map.current);
                    });
                }

                if (typeof marker_item['location_Lat'] !== 'number') {
                    // console.log(marker_item);
                }
            });
        }
    }, [branches, map.current]);

    return (
        <GoogleMapRoot>
            <MapContainer id="map-container" />

            {!ready && <MapLoader />}
        </GoogleMapRoot>
    );
};

export default GoogleMap;
