import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';

// Icons
import SmartphoneIcon from '../../../assets/images/icons/smartphone.svg';
import HomeRepairIcon from '../../../assets/images/icons/home-repair.svg';
import CameraIcon from '../../../assets/images/icons/camera.svg';
import PoliceBadgeIcon from '../../../assets/images/icons/police-badge.svg';
import EmailIcon from '../../../assets/images/icons/email.svg';
import ApprovalIcon from '../../../assets/images/icons/approval.svg';
import SolidarityICon from '../../../assets/images/icons/solidarity.svg';

const ClaimStepsRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const ClaimStepsIntro = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingBottom: '16px',
}));

const IconContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#D7412E',
    height: '78px',
    width: '78px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    borderRadius: '100%',
}));

const StepIcon = styled('img')(({ theme }) => ({
    height: '45px',
    width: '45px',
    filter: 'invert(99%) sepia(0%) saturate(7500%) hue-rotate(231deg) brightness(102%) contrast(100%)',
}));

const claimSteps_array = [
    {
        title: 'Step 1',
        text: `If possible, try to minimise any further damage such as turning off the water mains or phoning the fire brigade.`,
        icon: SmartphoneIcon,
    },
    {
        title: 'Step 2',
        text: `Where necessary, effect any emergency or temporary repairs to prevent further injury, loss or damage.`,
        icon: HomeRepairIcon,
    },
    {
        title: 'Step 3',
        text: `Take photos of all the damage and any emergency repairs undertaken. Please keep all the damaged or replaced items.`,
        icon: CameraIcon,
    },
    {
        title: 'Step 4',
        text: `If the damage or loss was caused by theft, attempted theft, malicious damage, violent disorder, riots, or civil commotion, report the matter police as soon as possible and obtain a police case reference number.`,
        icon: PoliceBadgeIcon,
    },
    {
        title: 'Step 5',
        text: `Notify us of your claim as soon as reasonably possible after the event (online or submitting a claim form), but not later than 31 days.`,
        icon: EmailIcon,
    },
    {
        title: 'Step 6',
        text: `Please send us the documentation you’ve obtained such as repair quotations, damage report detailing the cause of the loss or damage as well as photos and further information requested.`,
        icon: ApprovalIcon,
    },
];

const ClaimSteps = (props) => {
    const { fieldRef } = props;

    return (
        <ClaimStepsRoot id="claim-steps" ref={fieldRef}>
            <ClaimStepsIntro>
                <SectionTitle subtitle="What to do in the event of a claim" />
                <Typography variant="body1" paddingX={3}>
                    Don't panic! This is why you have insurance - take a deep breath and follow
                    these steps.
                </Typography>
            </ClaimStepsIntro>

            <Container maxWidth="lg">
                <Grid container spacing={3} textAlign={`center`} marginTop={1}>
                    {claimSteps_array.map((item, index) => (
                        <Grid item xs={12} sm={12} md={4} key={index}>
                            <IconContainer>
                                <StepIcon src={item['icon']} alt={item['title']} />
                            </IconContainer>

                            <Typography variant="h4" marginY={2}>
                                {item['title']}
                            </Typography>

                            <Typography variant="body1">{item['text']}</Typography>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <IconContainer>
                            <StepIcon src={SolidarityICon} alt={`Step 7`} />
                        </IconContainer>

                        <Typography variant="h4" marginY={2}>
                            Step 7
                        </Typography>

                        <Typography variant="body1">Let us take care of the rest.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </ClaimStepsRoot>
    );
};

export default ClaimSteps;
