import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Divider, Grid, Link, Typography } from '@mui/material';
import SearchContainer from './newsletter-sidebar/SearchContainer';

const NewsletterSidebarRoot = styled('div')(({ theme }) => ({}));

const AboutContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#f8f9fa',
    padding: '24px',
}));

const SocialContainer = styled('div')(({ theme }) => ({
    // backgroundColor: '#f8f9fa',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
}));

const NewsletterSidebar = (props) => {
    const { handlePostClick } = props;

    return (
        <NewsletterSidebarRoot>
            <AboutContainer>
                <Typography
                    variant="h4"
                    fontSize={20}
                    color="#D7412E"
                    fontStyle={`italic`}
                    marginY={1}
                >
                    About
                </Typography>
                <Typography variant="body1">
                    Join more than 12,000 subscribers and stay up to date with "must know" insurance
                    topics, advice from the experts, industry related news and special
                    announcements.
                </Typography>
            </AboutContainer>

            <SearchContainer handlePostClick={handlePostClick} />

            <SocialContainer>
                <Typography
                    variant="h4"
                    fontSize={20}
                    color="#D7412E"
                    fontStyle={`italic`}
                    marginY={1}
                >
                    Social Media
                </Typography>
                <Link
                    href="https://www.linkedin.com/company/cia-building-insurance-specialists"
                    target={`_blank`}
                    marginY={1}
                >
                    LinkedIn
                </Link>

                <Link href="https://www.facebook.com/CIA.co.za" target={`_blank`}>
                    Facebook
                </Link>
            </SocialContainer>
        </NewsletterSidebarRoot>
    );
};

export default NewsletterSidebar;
