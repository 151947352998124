import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Button, Container, Typography, Tab, Box, Grid, useMediaQuery } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import SectionTitle from '../../../components/SectionTitle';

import HomeownersImage from '../../../assets/images/cia-house-cropped.jpg';
import CommunityImage from '../../../assets/images/community-home-cropped.jpg';
import CommercialImage from '../../../assets/images/commercial-building-cropped.jpg';
import FidelityImage from '../../../assets/images/fidelity-computer-crime-cropped.jpg';

const ProductInfoRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const TabListStyled = styled(TabList)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
}));

const TabItem = styled(Tab)(({ theme }) => ({
    fontSize: '18px',
    textTransform: 'none',
    borderRadius: '5px 5px 0 0',
    '&.Mui-selected': {
        fontWeight: 600,
        borderBottom: '3px solid #D7412E',
    },
}));

const TabContent = styled(TabPanel)(({ theme }) => ({
    padding: '24px 0',
    textAlign: 'start',
    minHeight: '330px',
}));

const ImageGrid = styled(Grid)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
    },
}));

const TextContentGrid = styled(Grid)(({ theme }) => ({
    padding: '0 35px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: '0',
    },
}));

const ProductImages = styled('img')(({ theme }) => ({
    width: '100%',
    boxShadow: '0 2px 10px -2px rgb(0 0 0 / 60%)',
}));

const ProductTitle = styled('div')(({ theme }) => ({
    fontSize: '25px',
    fontWeight: 600,
    color: '#474747',
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
    },
}));

const ProductText = styled(Typography)(({ theme }) => ({
    margin: '30px 0',
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
    },
}));

const ProductActions = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
}));

const ProductBtn = styled(Button)(({ theme }) => ({
    margin: '5px',
    minWidth: '180px',
}));

const productItems = [
    {
        title: 'Homeowners Building Insurance',
        shortTitle: 'Homeowners',
        text: 'Offering some of the widest cover available on the market, our flagship Homeowners All Risk policy offers our clients complete peace of mind knowing that their homes are fully covered.',
        link: '/homeowners-building-insurance',
        quoteSection: '#homeowners-insurance',
        image: HomeownersImage,
    },
    {
        title: 'Community Living Insurance',
        shortTitle: 'Community Schemes',
        text: 'Our Community Living Insurance Policy has been designed to cover both residential and commercial community living schemes including Sectional Title, Bodies Corporate, Share Blocks, Home Owners Associations and Retirement Villages.',
        link: '/community-living-insurance',
        quoteSection: '#community-insurance',
        image: CommunityImage,
    },
    {
        title: 'Commercial Building Insurance',
        shortTitle: 'Commercial Buildings',
        text: 'The CIA Commercial Building Insurance Policy provides All Risk cover for commercial, industrial and residential buildings, such as churches, schools, hospitals, office parks, factories, warehouses and shopping centres.',
        link: '/commercial-building-insurance',
        quoteSection: '#commercial-insurance',
        image: CommercialImage,
    },
    {
        title: 'Fidelity & Computer Crime Insurance',
        shortTitle: 'Fidelity Guarantee',
        text: 'Our Fidelity and Computer Crime Insurance Policy is based on the requirements of the Sectional Titles Management and Community Schemes Ombud Services Acts and enhanced with additional cover provided for computer-crime related loss.',
        link: '/fidelity-computer-crime-insurance',
        quoteSection: '#fidelity-computer-crime-insurance',
        image: FidelityImage,
    },
];

const ProductInfo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const history = useHistory();
    const [value, setValue] = useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ProductInfoRoot>
            <SectionTitle title="Our Products" subtitle="Choose a product" />

            <Container maxWidth="lg">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabListStyled
                            onChange={handleChange}
                            aria-label="products-tabs"
                            variant="fullWidth"
                            orientation={isMobile ? 'vertical' : 'horizontal'}
                        >
                            {productItems.map((item, index) => (
                                <TabItem
                                    label={item['shortTitle']}
                                    value={index.toString()}
                                    key={index}
                                />
                            ))}
                        </TabListStyled>
                    </Box>
                    {productItems.map((item, index) => (
                        <TabContent value={index.toString()} key={index}>
                            <Grid container>
                                <ImageGrid item sm={5}>
                                    <ProductImages
                                        src={item['image']}
                                        alt={item['shortTitle']}
                                        loading="lazy"
                                    />
                                </ImageGrid>
                                <TextContentGrid item sm={7}>
                                    <ProductTitle>{item['title']}</ProductTitle>

                                    <ProductText>{item['text']}</ProductText>

                                    <ProductActions>
                                        <ProductBtn
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                history.push({
                                                    pathname: item['link'],
                                                    search: '?section=whats-covered',
                                                })
                                            }
                                        >
                                            What's Covered{' '}
                                            <FontAwesomeIcon
                                                style={{ marginLeft: '5px', fontSize: '18px' }}
                                                icon={faAlignRight}
                                            />
                                        </ProductBtn>

                                        <ProductBtn
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                history.push(`/get-quote${item['quoteSection']}`)
                                            }
                                        >
                                            Online Quote
                                            <FontAwesomeIcon
                                                style={{ marginLeft: '5px', fontSize: '18px' }}
                                                icon={faPaperPlane}
                                            />
                                        </ProductBtn>
                                    </ProductActions>
                                </TextContentGrid>
                            </Grid>
                        </TabContent>
                    ))}
                </TabContext>
            </Container>
        </ProductInfoRoot>
    );
};

export default ProductInfo;
