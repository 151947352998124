import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import SectionTitle from '../../../../components/SectionTitle';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <SectionTitle subtitle="Register for free online training" hideArrow={true} />

                <Typography variant="body1" marginY={2}>
                    In order for us to register you on our online training platform please fill in
                    the below details.
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
