import * as Yup from 'yup';
import formModel from './formModel';
import { APP_ALLOWED_FILE_TYPES } from '../../constants/AppConstants';

const { firstName, lastName, email } = formModel;

const validationSchema = Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string()
        .email('Incorrect email format')
        .required(`${email.requiredErrorMsg}`),
});

export default validationSchema;
