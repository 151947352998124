import AxiosBase from './modules/AxiosBase';

/**
 * @param {function} get Function async
 * @param {function} post Function async
 * @param {function} put Function async
 * @param {function} delete Function async
 */
const AxiosRequest = {
    /**
     * @param {string} url String
     * @param {object} options Object
     */
    get: async function (url, options = {}) {
        if (!url) {
            throw Object.assign(new Error('GET method needs an endpoint'));
        }

        return await AxiosBase({
            ...options,
            method: 'GET',
            url: url,
        });
    },
    /**
     * @param {string} url String
     * @param {any} data Any
     * @param {object} options Object
     */
    post: async function (url, data = null, options = null) {
        if (!url) {
            throw Object.assign(new Error('POST method needs an endpoint'));
        }

        return await AxiosBase({
            ...options,
            method: 'POST',
            url: url,
            data: data,
        });
    },
    /**
     * @param {string} url String
     * @param {any} data Any
     * @param {object} options Object
     */
    put: async function (url, data = null, options = null) {
        if (!url) {
            throw Object.assign(new Error('PUT method needs an endpoint'));
        }

        return await AxiosBase({
            ...options,
            method: 'PUT',
            url: url,
            data: data,
        });
    },
    /**
     * @param {string} url String
     * @param {any} data Any
     * @param {object} options Object
     */
    delete: async function (url, data = null, options = null) {
        if (!url) {
            throw Object.assign(new Error('DELETE method needs an endpoint'));
        }

        return await AxiosBase({
            ...options,
            method: 'DELETE',
            url: url,
            data: data,
        });
    },
};

export default AxiosRequest;
