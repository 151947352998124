import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const RecaptchaField = (props) => {
    // Live - 6LfXcAsaAAAAAE04lMqvtBU-Y-3De13Yc0JpGK0a
    // Testing - 6LfOpiUdAAAAANomnE7wUyf8s1tPo9FgaZxtKhI8

    return (
        <ReCAPTCHA
            ref={props.recaptchaRef}
            size="invisible"
            sitekey="6LfXcAsaAAAAAE04lMqvtBU-Y-3De13Yc0JpGK0a"
        />
    );
};

export default RecaptchaField;
