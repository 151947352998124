// #region Imports

// Libraries
import { createTheme } from '@mui/material/styles';

// Constants
import * as THEME from '../../constants/AppConstants';

// #endregion

const ThemeBuilderOptions = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 992,
            md: 1200,
            lg: 1365,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: THEME.APP_PRIMARY_COLOR,
        },
        secondary: {
            main: THEME.APP_SECONDARY_COLOR,
        },
        error: {
            main: '#a61c1c',
        },
    },
    typography: {
        fontFamily: THEME.APP_FONT_FAMILY,
        h1: {
            fontSize: '30px',
            fontWeight: 600,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        h2: {
            fontSize: '30px',
            fontWeight: 500,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        h3: {
            fontSize: '23px',
            fontWeight: 600,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        h4: {
            fontSize: '23px',
            fontWeight: 500,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        h5: {
            fontSize: '16px',
            fontWeight: 600,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        h6: {
            fontSize: '16px',
            fontWeight: 500,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        caption: {
            fontSize: '16px',
            fontWeight: 600,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 600,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        subtitle2: {
            fontSize: '16px',
            fontWeight: 500,
            color: THEME.APP_PRIMARY_TEXT_COLOR,
        },
        body1: {
            fontSize: '16px',
            fontWeight: 500,
            color: THEME.APP_SECONDARY_TEXT_COLOR,
        },
        body2: {
            fontSize: '16px',
            fontWeight: 500,
            color: THEME.APP_SECONDARY_TEXT_COLOR,
        },
    },
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#474747',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '16px',
                    '&.Mui-focused': {
                        color: '#474747',
                        fontWeight: 600,
                    },
                    '&.MuiFormLabel-filled': {
                        color: '#474747',
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&.MuiInput-underline:before': {
                        borderBottom: '1px solid #999',
                    },
                    '&.MuiInput-underline:hover:before': {
                        borderBottom: '1px solid #999',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#888888',
                    '& .MuiInputBase-input + fieldset': {},
                    '& .MuiInputBase-input:hover + fieldset': {
                        borderColor: '#a6a6a6',
                        borderWidth: 0,
                    },
                    '& .MuiInputBase-input:focus + fieldset': {
                        borderColor: '#a6a6a6',
                        borderWidth: 0,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '.25rem',
                    '& .MuiInputBase-input:hover + fieldset': {
                        borderColor: '#a6a6a6',
                        borderWidth: 1,
                    },
                    '& .MuiInputBase-input:focus + fieldset': {
                        borderColor: '#a6a6a6',
                        borderWidth: 1,
                    },
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        fontWeight: 500,
                        textTransform: 'none',
                        borderRadius: '1.2rem',
                        minWidth: '130px',
                        maxHeight: '36px',
                        fontSize: '16px',
                        border: '2px solid #D7412E',
                        boxShadow: 'none',
                        ':hover': {
                            backgroundColor: '#FFF',
                            color: '#D7412E',
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: { color: 'secondary' },
                    style: {
                        fontWeight: 500,
                        textTransform: 'none',
                        borderRadius: '1.2rem',
                        minWidth: '130px',
                        maxHeight: '36px',
                        fontSize: '16px',
                        border: '2px solid #D7412E',
                        boxShadow: 'none',
                        backgroundColor: '#FFF',
                        color: '#D7412E',
                        ':hover': {
                            backgroundColor: '#D7412E',
                            color: '#FFF',
                            boxShadow: 'none',
                        },
                    },
                },
            ],
        },
    },
});

export default ThemeBuilderOptions;
