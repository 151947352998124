import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import GoogleSearchField from '../../../../../../components/forms/form-fields/GoogleSearchField';
import formModel from '../../../../../../validation/get-quote/commercial/formModel';
import RangeInput from '../../../../../../components/forms/form-fields/RangeInput';
import InputField from '../../../../../../components/forms/form-fields/InputField';

const PropertyDetailsRoot = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const QuestionContainer = styled('div')(({ theme }) => ({
    minHeight: '60px',
}));

const QuestionBtn = styled(Button)(({ theme }) => ({
    borderRadius: '5px',
    fontWeight: 600,
    margin: '5px',
    maxHeight: '40px',
    color: '#474747',
    border: '1px solid #D7412E',
    '&.active': {
        backgroundColor: '#D7412E',
        color: '#FFFFFF',
    },
}));

const PropertyDetails = (props) => {
    const {
        propertyLocation,
        sumInsredAmount,
        propertyQuestion1,
        buildingOccupation,
        insured,
        roofConstruction,
        wallConstruction,
    } = formModel;

    const { formikProps, activeValidation } = props;

    const questionArray = [
        {
            item: propertyQuestion1,
        },
    ];

    const handleLocation = async (locationData) => {
        var address = locationData['address_components'];
        var zipcode = address[address.length - 1]['long_name'];

        formikProps.setFieldValue('postalCode', zipcode);
        formikProps.setFieldValue(
            'gpsCoordinates',
            `${locationData['geometry']['location'].lat()},${locationData['geometry'][
                'location'
            ].lng()}`
        );
    };

    const handleValueChange = async (value) => {
        formikProps.setFieldValue('sumInsredAmount', value);
    };

    return (
        <PropertyDetailsRoot>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <GoogleSearchField
                        name={propertyLocation['name']}
                        label={propertyLocation['label']}
                        fullWidth
                        setFieldValue={formikProps.setFieldValue}
                        values={formikProps.values}
                        autoFocus
                        locationBtn
                        style={{ margin: '20px 0' }}
                        handleLocation={handleLocation}
                    />

                    <RangeInput
                        name={sumInsredAmount['name']}
                        label={sumInsredAmount['label']}
                        value={props['formikProps']['values']['sumInsredAmount']}
                        setFieldValue={formikProps.setFieldValue}
                        handleValueChange={handleValueChange}
                        fullWidth
                    />

                    <Grid container spacing={3} style={{ marginTop: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <InputField label={insured['label']} name={insured['name']} fullWidth />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputField
                                label={buildingOccupation['label']}
                                name={buildingOccupation['name']}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth style={{ marginTop: 20 }}>
                                <InputLabel id="package-select-label">
                                    {roofConstruction['label']}
                                </InputLabel>
                                <Select
                                    labelId="package-select-label"
                                    id="package-select"
                                    value={formikProps['values']['roofConstruction']}
                                    label={roofConstruction['label']}
                                    size="small"
                                    variant="standard"
                                    defaultValue={formikProps['values']['roofConstruction']}
                                    onChange={(event) =>
                                        formikProps.setFieldValue(
                                            'roofConstruction',
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="Standard (Tiles / IBR / Concrete)">
                                        Standard (Tiles / IBR / Concrete)
                                    </MenuItem>
                                    <MenuItem value="Thatch">Thatch</MenuItem>
                                    <MenuItem value="Reed">Reed</MenuItem>
                                    <MenuItem value="Wood">Wood</MenuItem>
                                    <MenuItem value="Shingles">Shingles</MenuItem>
                                    <MenuItem value="Slate">Slate</MenuItem>
                                    <MenuItem value="Asbestos">Asbestos</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth style={{ marginTop: 20 }}>
                                <InputLabel id="package-select-label">
                                    {wallConstruction['label']}
                                </InputLabel>
                                <Select
                                    labelId="package-select-label"
                                    id="package-select"
                                    value={formikProps['values']['wallConstruction']}
                                    label={wallConstruction['label']}
                                    size="small"
                                    variant="standard"
                                    defaultValue={formikProps['values']['wallConstruction']}
                                    onChange={(event) =>
                                        formikProps.setFieldValue(
                                            'wallConstruction',
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="Standard (Brick / Concrete)">
                                        Standard (Brick / Concrete)
                                    </MenuItem>
                                    <MenuItem value="Wood">Wood</MenuItem>
                                    <MenuItem value="Nutech">Nutech</MenuItem>
                                    <MenuItem value="Rammed Earth Walls">
                                        Rammed Earth Walls
                                    </MenuItem>
                                    <MenuItem value="Asbestos">Asbestos</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {activeValidation && !formikProps['values']['propertyQuestion1'] && (
                        <Typography color="error" marginTop={3}>
                            *Please select all options below
                        </Typography>
                    )}

                    <Typography variant="h5" marginTop={3} marginBottom={2}>
                        Please confirm the following with regards to the property:
                    </Typography>

                    {questionArray.map((question, index) => (
                        <QuestionContainer key={index}>
                            <Grid container>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="body1" marginBottom={2}>
                                        {question['item']['label']}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
                                    <QuestionBtn
                                        className={
                                            formikProps['values'][question['item']['name']] ===
                                                'Yes' && 'active'
                                        }
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            formikProps.setFieldValue(
                                                question['item']['name'],
                                                'Yes'
                                            );
                                        }}
                                    >
                                        Yes
                                    </QuestionBtn>
                                    <QuestionBtn
                                        className={
                                            formikProps['values'][question['item']['name']] ===
                                                'No' && 'active'
                                        }
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                            formikProps.setFieldValue(
                                                question['item']['name'],
                                                'No'
                                            )
                                        }
                                    >
                                        No
                                    </QuestionBtn>
                                </Grid>
                            </Grid>
                        </QuestionContainer>
                    ))}
                </Grid>
            </Grid>
        </PropertyDetailsRoot>
    );
};

export default PropertyDetails;
