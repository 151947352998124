import formModel from './formModel';

const {
    propertyLocation,
    postalCode,
    gpsCoordinates,
    sumInsredAmount,
    propertyQuestion1,
    complexName,
    numberOfUnits,
    buildingOccupation,
    roofConstruction,
    wallConstruction,
    contactPerson,
    contactNumber,
    email,
    yourBroker,
    preferredBranch,
} = formModel;

const initialValues = {
    [propertyLocation.name]: '',
    [postalCode.name]: '',
    [gpsCoordinates.name]: '',
    [sumInsredAmount.name]: '2500000',
    [propertyQuestion1.name]: '',
    [complexName.name]: '',
    [numberOfUnits.name]: '',
    [buildingOccupation.name]: 'Residential Only',
    [roofConstruction.name]: 'Standard (Tiles / IBR / Concrete)',
    [wallConstruction.name]: 'Standard (Brick / Concrete)',
    [contactPerson.name]: '',
    [contactNumber.name]: '',
    [email.name]: '',
    [yourBroker.name]: '',
    [preferredBranch.name]: 'Johannesburg',
};

export default initialValues;
