import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import Cards from './components/Cards';
import ClaimSteps from './components/ClaimSteps';

const ClaimRoot = styled('div')(({ theme }) => ({}));

const Claim = () => {
    const fieldRef = useRef(null);

    useEffect(() => {
        if (window.location.hash === '#claim-steps' && fieldRef.current) {
            window.scrollTo(0, fieldRef.current.offsetTop - 50);
        }
    }, []);

    return (
        <Fade in={true}>
            <ClaimRoot>
                <Introduction />

                <Cards />

                <ClaimSteps fieldRef={fieldRef} />
            </ClaimRoot>
        </Fade>
    );
};

export default Claim;
