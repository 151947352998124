import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../../components/SectionTitle';
import { Button, Collapse, Container, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const IntroductionContainer = styled(Container)(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '0 30px 0 20px',
    },
}));

const Introduction = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <IntroductionRoot>
            <SectionTitle title="Our People" subtitle="The driving force behind our success" />

            <IntroductionContainer maxWidth="lg">
                <Typography variant="body1">
                    At CIA Building Insurance Specialists, we understand that a building has
                    community, livelihood and family at its core. Its value is so much more than the
                    replacement cost of its roof and four walls. Our purpose at CIA is to keep the
                    roof over your head; to ensure that your buildings and the people closest to you
                    are protected from the devastating effects of fire, floods and other disasters.
                </Typography>

                <Typography variant="body1" style={{ marginTop: '20px' }}>
                    With our roots in a family-run business started in the late 1990's we have
                    emerged as a respected market-leader in our industry, while still upholding the
                    culture and ideals with which our company was founded.
                </Typography>

                <Collapse in={showMore}>
                    <Typography variant="body1" style={{ marginTop: '20px' }}>
                        It is this understanding that has fueled the development of our flagship
                        building insurance solutions in conjunction with ongoing research, a
                        constant drive to innovate and entrenched partnerships with our broker
                        network. This enables CIA to offer insurance products, cover and benefits
                        for residential, commercial and industrial property owners that are the
                        benchmark in the industry.
                    </Typography>

                    <Typography variant="body1" style={{ marginTop: '20px' }}>
                        CIA has a national footprint with 6 branches countrywide, each branch
                        equipped with highly skilled and approachable decision-makers. As a result,
                        we are renowned for consistent service delivery and the accessibility and
                        personal touch of all our employees. Our extensive network of brokers means
                        that we can connect you with an intermediary highly-trained in our range of
                        products and services. Whatever your requirement, we pride ourselves on
                        delivering passionate, personable and productive service.
                    </Typography>
                </Collapse>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: '20px' }}
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? 'Read Less' : 'Read More'}{' '}
                    <FontAwesomeIcon
                        icon={faAlignRight}
                        style={{ marginLeft: '5px', fontSize: '18px' }}
                    />
                </Button>
            </IntroductionContainer>
        </IntroductionRoot>
    );
};

export default Introduction;
