import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';

import FidelityImage from '../../assets/images/product-pages/fidelity.jpg';
import WhatsCovered from './components/WhatsCovered';
import Downloads from './components/Downloads';

// Icons
import MoneyIcon from '../../assets/images/icons/money.svg';
import EmployeeIcon from '../../assets/images/icons/employee.png';
import ComputerIcon from '../../assets/images/icons/computer.svg';
import FakeNewsIcon from '../../assets/images/icons/fake-news.png';

// Documents
import FidelityInsurance from '../../assets/documents/products-key-information/fidelity/Fidelity_and_Computer_Crime_Insurance_Policy_1_March_2023.pdf';
import SummaryChanges from '../../assets/documents/products-key-information/fidelity/Summary_of_Changes_to_Fidelity_Computer_Crime_Policy_October_2022.pdf';
import QuotationProposal from '../../assets/documents/products-key-information/fidelity/Fidelity-Computer-Crime-Quotation-Proposal-Form.pdf';
import Declaration from '../../assets/documents/products-key-information/fidelity/CIA-Fidelity-Computer-Crime-Declaration-ManagingAgent.pdf';

const FidelityComputerCrimeInsuranceRoot = styled('div')(({ theme }) => ({}));

const content = {
    image: FidelityImage,
    title: 'Fidelity & Computer Crime Insurance',
    paragraph_one: (
        <Fragment>
            The Fidelity and Computer Crime Insurance Policy is based on the requirements of the
            Sectional Title Schemes Management and Community Schemes Ombud Services Acts, namely
            that “…every community scheme must insure against the risk of loss of money belonging to
            the community scheme…sustained as a result of any act of fraud or dishonesty committed
            by any insurable person.”
        </Fragment>
    ),
    paragraph_two: (
        <Fragment>
            The CIA Fidelity Policy is augmented through additional cover provided for
            computer-crime related loss i.e. loss of funds due to fraudulent manipulation of a
            computer network. This product guarantees peace of mind to the community scheme
            and/managing agent through its compliance with new regulations as well as cover that
            addresses most of the potential criminal acts that a community scheme faces.
        </Fragment>
    ),
    btnText: 'Get a Quote',
    btnUrl: '/get-quote#fidelity-computer-crime-insurance',
};

const icon_items = [
    {
        title: 'Direct Loss',
        text: 'Direct financial loss of the schemes funds.',
        icon: MoneyIcon,
    },
    {
        title: 'Theft & Fraud',
        text: 'Theft & fraud committed by scheme employees, agents or 3rd parties.',
        icon: EmployeeIcon,
    },
    {
        title: 'Computer Hacking',
        text: 'Computer hacking and data damage expenses.',
        icon: ComputerIcon,
    },
    {
        title: 'Fraudulent Alteration',
        text: 'Fraudulent alterations and transfer instructions.',
        icon: FakeNewsIcon,
    },
];

const document_items = [
    {
        title: 'Fidelity and Computer Crime Insurance Policy',
        text: 'Document size: 327KB',
        document: FidelityInsurance,
    },
    {
        title: 'Summary of Changes to Fidelity and Computer Crime Wording ',
        text: 'Document size: 129KB',
        document: SummaryChanges,
    },
    {
        title: 'Fidelity and Computer Crime Proposal Form',
        text: 'Document size: 266KB',
        document: QuotationProposal,
    },
    {
        title: 'Declaration by Managing Agent Fidelity and Computer Crime - New Business and Renewals',
        text: 'Document size: 91.9KB',
        document: Declaration,
    },
];

const FidelityComputerCrimeInsurance = () => {
    return (
        <Fade in={true}>
            <FidelityComputerCrimeInsuranceRoot>
                <Introduction content={content} />

                <WhatsCovered icon_items={icon_items} />

                <Downloads document_items={document_items} />
            </FidelityComputerCrimeInsuranceRoot>
        </Fade>
    );
};

export default FidelityComputerCrimeInsurance;
