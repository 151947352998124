import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';

import PdfIcon from '../../../assets/images/icons/pdf.svg';
import SectionTitle from '../../../components/SectionTitle';

const DownloadsRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const DownloadsContainer = styled('div')(({ theme }) => ({}));

const DownloadsItem = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: '30px',
    cursor: 'pointer',
    ':hover': {
        '& .item-title': {
            color: '#D7412E',
        },
    },
}));

const ItemIcon = styled('img')(({ theme }) => ({
    height: '40px',
    width: '40px',
    marginRight: '10px',
    filter: 'invert(43%) sepia(100%) saturate(3298%) hue-rotate(341deg) brightness(85%) contrast(97%)',
}));

const TextContainer = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 600,
    fontSize: '15px',
}));

const ItemTitle = styled('div')(({ theme }) => ({
    color: '#474747',
    fontWeight: 600,
    fontSize: '16px',
}));

const ItemText = styled('div')(({ theme }) => ({
    color: '#666666',
    fontWeight: 500,
    fontSize: '16px',
    marginTop: '5px',
}));

const Downloads = ({ document_items }) => {
    return (
        <DownloadsRoot>
            <Container maxWidth="lg">
                <SectionTitle subtitle="Downloads" align="left" hideArrow />

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            Access our plain language key information document, policy wording and
                            other relevant forms here:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {document_items.map((document_item, index) => (
                            <DownloadsItem
                                onClick={() => window.open(document_item['document'], '_blank')}
                                key={index}
                            >
                                <ItemIcon src={PdfIcon} />
                                <TextContainer>
                                    <ItemTitle className="item-title">
                                        {document_item['title']}
                                    </ItemTitle>
                                    <ItemText>{document_item['text']}</ItemText>
                                </TextContainer>
                            </DownloadsItem>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </DownloadsRoot>
    );
};

export default Downloads;
