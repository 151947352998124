import * as Yup from 'yup';
import formModel from './formModel';

const {
    attorneysName,
    attorneysPostalAddress,
    attorneysTel,
    attorneysContactPerson,
    email,
    ownerName,
    bodyCorporateName,
    sections,
    ssNumber,
    sumInsured,
    bankName,
    bankAddress,
} = formModel;

const validationSchema = Yup.object().shape({
    [attorneysName.name]: Yup.string().required(`${attorneysName.requiredErrorMsg}`),
    [attorneysPostalAddress.name]: Yup.string().required(
        `${attorneysPostalAddress.requiredErrorMsg}`
    ),
    [attorneysTel.name]: Yup.string().required(`${attorneysTel.requiredErrorMsg}`),
    [attorneysContactPerson.name]: Yup.string().required(
        `${attorneysContactPerson.requiredErrorMsg}`
    ),
    [email.name]: Yup.string()
        .email('Incorrect email format')
        .required(`${email.requiredErrorMsg}`),
    [ownerName.name]: Yup.string().required(`${ownerName.requiredErrorMsg}`),
    [bodyCorporateName.name]: Yup.string().required(`${bodyCorporateName.requiredErrorMsg}`),
    [sections.name]: Yup.string().required(`${sections.requiredErrorMsg}`),
    [ssNumber.name]: Yup.string().required(`${ssNumber.requiredErrorMsg}`),
    [sumInsured.name]: Yup.string().required(`${sumInsured.requiredErrorMsg}`),
    [bankName.name]: Yup.string().required(`${bankName.requiredErrorMsg}`),
    [bankAddress.name]: Yup.string().required(`${bankAddress.requiredErrorMsg}`),
});

export default validationSchema;
