import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import OnlineTrainingDescription from './components/OnlineTrainingDescription';

const OnlineBrokerTrainingRoot = styled('div')(({ theme }) => ({}));

const OnlineBrokerTraining = () => {
    return (
        <Fade in={true}>
            <OnlineBrokerTrainingRoot>
                <Introduction />

                <OnlineTrainingDescription />
            </OnlineBrokerTrainingRoot>
        </Fade>
    );
};

export default OnlineBrokerTraining;
