import React from 'react';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../../components/SectionTitle';
import { Button, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CompassImage from '../../../assets/images/compass-1.png';
import LireasImage from '../../../assets/images/LHLogo.png';
import HannoverImage from '../../../assets/images/Hannover_Logo.png';

const OurPartnersRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
}));

const AboutDesc = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '1200px',
    padding: '0 40px',
}));

const OurPartnersContainer = styled(Container)(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        marginTop: '50px',
    },
}));

const OurPartnersImage = styled('img')(({ theme }) => ({}));

const GridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        padding: '70px 0 0',
    },
}));

const ourPartners_array = [
    {
        image: CompassImage,
        link: 'http://www.compass.co.za/',
        alt: 'Compass',
        height: '70px',
    },
    {
        image: LireasImage,
        link: 'https://www.lireas.co.za/',
        alt: 'Lireas',
        height: '70px',
    },
    {
        image: HannoverImage,
        link: 'https://www.hannover-re.com/265794/hannover-re-in-south-africa-property-amp-casualty',
        alt: 'Hannover',
        height: '60px',
    },
];

const OurPartners = () => {
    return (
        <OurPartnersRoot>
            <SectionTitle title="About" subtitle="Our partners" />

            <AboutDesc variant="body1">
                CIA is proudly underwritten by Compass Insurance Co. Limited (awarded an AA-(ZA)
                rating by Global Credit Ratings), a subsidiary of the Hannover Reinsurance Group -
                one of the largest reinsurers in the world. CIA is part of the Lireas Holdings Group
                of companies, which in turn is a subsidiary of the Hannover Reinsurance Group Africa
                Limited.
            </AboutDesc>

            <OurPartnersContainer maxWidth="lg">
                <Grid container>
                    {ourPartners_array.map((item, index) => (
                        <GridItem item xs={12} md={4} key={index}>
                            <Grid item xs={12}>
                                <OurPartnersImage
                                    src={item['image']}
                                    alt={item['alt']}
                                    style={{ height: item['height'] }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: '20px' }}
                                    onClick={() => window.open(item['link'], '_blank')}
                                >
                                    Visit Site
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        style={{ marginLeft: '5px', fontSize: '18px' }}
                                    />
                                </Button>
                            </Grid>
                        </GridItem>
                    ))}
                </Grid>
            </OurPartnersContainer>
        </OurPartnersRoot>
    );
};

export default OurPartners;
