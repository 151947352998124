import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { links_array } from '../../../constants/AppConstants';

const FooterRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .02)',
    color: '#474747',
}));

const FooterInner = styled('div')(({ theme }) => ({
    padding: '60px 0',
    // [theme.breakpoints.down('md')]: {
    //     padding: '60px 20px',
    // },
}));

const FooterBreadContainer = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const BreadCrumLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    color: '#474747',
    textDecoration: 'none',
    ':hover': {
        color: '#D7412E',
    },
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: '20px 0px',
}));

const FooterItemTitle = styled(Typography)(({ theme }) => ({
    marginBottom: '16px',
}));

const FooterItemText = styled(Typography)(({ theme }) => ({
    marginBottom: '16px',
    cursor: 'pointer',
    ':hover': {
        color: '#D7412E',
    },
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#e6e6e6',
    margin: '5px',
    height: '45px',
    width: '45px',
    borderRadius: '100%',
    ':hover': {
        color: '#D7412E',
        backgroundColor: '#e6e6e6',
    },
}));

const Footer = () => {
    const history = useHistory();
    const [breadCrum, setBreadCrum] = useState(null);

    const GenerateBreadcrum = () => {
        var linkItem;

        try {
            linkItem = links_array
                .find((x) => x.items.some((item) => item.link === window.location.pathname))
                ['items'].find((x) => x['link'] === window.location.pathname);
        } catch (error) {}

        if (!linkItem) {
            return null;
        }

        if (window.location.pathname === '/') {
            return null;
        }

        if ('breadCrum' in linkItem) {
            return (
                <Fragment>
                    <span style={{ margin: '0 3px' }}>/</span>
                    <div>{linkItem['breadCrum']}</div>
                    <span style={{ margin: '0 3px' }}>/</span>
                    <BreadCrumLink onClick={() => history.push(linkItem['link'])}>
                        {linkItem['title']}
                    </BreadCrumLink>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <span style={{ margin: '0 3px' }}>/</span>
                <BreadCrumLink onClick={() => history.push(linkItem['link'])}>
                    {linkItem['title']}
                </BreadCrumLink>
            </Fragment>
        );
    };

    useEffect(() => {
        setBreadCrum(GenerateBreadcrum());

        return history.listen((location) => {
            setBreadCrum(GenerateBreadcrum());
        });
    }, [history]);

    return (
        <FooterRoot>
            <Container maxWidth="lg">
                <FooterInner>
                    <FooterBreadContainer>
                        <BreadCrumLink onClick={() => history.push('/')}>Home</BreadCrumLink>
                        {breadCrum}
                    </FooterBreadContainer>

                    <Divider style={{ margin: '16px 0' }} />

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {links_array.map((item, item_index) => (
                            <GridItem item xs={6} lg={3} key={item_index}>
                                <FooterItemTitle variant="h5">{item['category']}</FooterItemTitle>
                                {item['items'].map((links, link_index) =>
                                    links['hidden'] ? null : (
                                        <FooterItemText
                                            variant="h6"
                                            onClick={() => {
                                                if (links['external'])
                                                    window.location.href = links['link'];
                                                else history.push(links['link']);
                                            }}
                                            key={link_index}
                                        >
                                            {links['title']}
                                        </FooterItemText>
                                    )
                                )}
                            </GridItem>
                        ))}
                    </Grid>

                    <Divider
                        style={{ margin: '16px 0' }}
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                        }}
                    />

                    <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <SocialIcon
                                onClick={() =>
                                    window.open('https://www.facebook.com/CIA.co.za', '_blank')
                                }
                            >
                                <FontAwesomeIcon icon={faFacebookF} />
                            </SocialIcon>
                            <SocialIcon
                                onClick={() =>
                                    window.open(
                                        'https://www.linkedin.com/company/cia-building-insurance-specialists',
                                        '_blank'
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </SocialIcon>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ margin: '16px 0' }}>
                                Contact Our Head Office:{' '}
                                <Link href="tel:+27861242777">0861 242 777</Link>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                        >
                            <Typography variant="body1" style={{ maxWidth: '1100px' }}>
                                Commercial & Industrial Acceptances (Pty) Ltd is an Authorised FSP
                                No: 13890. Copyright © Commercial & Industrial Acceptances (Pty) Ltd
                                All Rights Reserved
                            </Typography>
                        </Grid>
                    </Grid>
                </FooterInner>
            </Container>
        </FooterRoot>
    );
};

export default Footer;
