// #region Imports

// Libraries
import React, { useEffect, useState } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    Box,
    Button,
    Hidden,
    IconButton,
    InputAdornment,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InputField from './InputField';

// #endregion

// #region Styles

const RangeInputRoot = styled('div')(({ theme }) => ({
    minHeight: '124px',
}));

const ValueContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 5px',
}));

const UseValueBtn = styled(IconButton)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '5px',
    marginBottom: '5px',
    color: '#D7412E',
}));

// #endregion

function numFormatter(num) {
    if (num < 1) {
        return num * 1000000;
    } else if (num >= 1) {
        return num * 1000000;
    }
}

const RangeInput = (props) => {
    const [value, setValue] = useState(() => {
        if (parseInt(props['value']) < 1000000) {
            return parseInt(props['value']) / 1000000;
        } else if (parseInt(props['value']) >= 1000000) {
            return parseInt(props['value']) / 1000000;
        }
    });

    const [showCustomValue, setShowCustomValue] = useState(false);
    const [message, setMessage] = useState('');
    const [validValue, setValidValue] = useState(true);
    const [customValue, setCustomValue] = useState(parseInt(props['value']));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCustomValue(numFormatter(newValue).toString());
        props.setFieldValue(props['name'], numFormatter(newValue).toString());
    };

    const validateCutomValue = (event) => {
        let customValue = parseInt(event.target.value);
        setMessage('');
        setValidValue(true);

        if (!customValue) {
            console.log('Error');
            setValue(2.5);
            return;
        }

        if (customValue < parseInt(props['minValue'])) {
            setMessage(`Value cannot be smaller than ${props['minValue'].toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`);
            setValidValue(false);
        } else if (customValue > 50000000) {
            setMessage('Value cannot be bigger than 50,000,000');
            setValidValue(false);
        }

        if (customValue < 1000000) {
            setValue(customValue / 1000000);
        } else if (customValue >= 1000000) {
            setValue(customValue / 1000000);
        }

        setCustomValue(customValue);
        props.setFieldValue(props['name'], customValue.toString());
    };

    return !showCustomValue ? (
        <RangeInputRoot>
            <Typography variant="body1" marginTop={4}>
                Building Replacement Value
            </Typography>

            <ValueContainer>
                <Typography color="#D7412E" fontSize={28} fontWeight={600}>
                    {`R ${numFormatter(value)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setShowCustomValue(true)}
                >
                    Custom Value
                </Button>
            </ValueContainer>

            <Slider
                value={value}
                defaultValue={2.5}
                step={0.5}
                marks
                min={parseInt(props['minValue']) / 1000000}
                max={50}
                onChange={handleChange}
                aria-labelledby="non-linear-slider"
            />
        </RangeInputRoot>
    ) : (
        <RangeInputRoot style={{ margin: '20px 0' }}>
            <TextField
                label={props.label}
                type="number"
                fullWidth
                variant="standard"
                onChange={validateCutomValue}
                value={customValue}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <UseValueBtn onClick={() => validValue && setShowCustomValue(false)}>
                                Use Value
                            </UseValueBtn>
                        </InputAdornment>
                    ),
                }}
            />

            <Typography variant="body1" color="error">
                {message}
            </Typography>
        </RangeInputRoot>
    );
};

export default RangeInput;
