import * as Pages from '../pages/ExportPages';

// Product Icons
import HouseIcon from '../assets/images/icons/house.svg';
import ApartmentIcon from '../assets/images/icons/apartment.svg';
import ShopIcon from '../assets/images/icons/shop.svg';
import ComputerNetworkIcon from '../assets/images/icons/computer-network.svg';

// Useful Info Icons
import RegulationIcon from '../assets/images/icons/regulation.svg';
import EnvelopeIcon from '../assets/images/icons/envelope.svg';
import LightBulbIcon from '../assets/images/icons/light-bulb.svg';

// Self Assist Icons
import MultitaskingIcon from '../assets/images/icons/multitasking.svg';
import LaptopIcon from '../assets/images/icons/laptop.svg';
import FindMyFriendIcon from '../assets/images/icons/find-my-friend.svg';
import InsurancePolicyIcon from '../assets/images/icons/insurance-policy.svg';
import PolicyIcon from '../assets/images/icons/policy.svg';
import ProcessIcon from '../assets/images/icons/process.svg';

// #region Application Styling

export const APP_FONT_FAMILY = 'Verdana, Geneva, Tahoma, sans-serif'; // Poppins
export const APP_PRIMARY_COLOR = '#D7412E';
export const APP_SECONDARY_COLOR = '#448FA3';
export const APP_PRIMARY_TEXT_COLOR = '#474747';
export const APP_SECONDARY_TEXT_COLOR = '#666666';

// #endregion

// #region Application Info

export const APP_FADE_TIMEOUT = 400;
export const APP_ALLOWED_FILE_TYPES = ['doc', 'docx', 'application/pdf'];

// #endregion

// #region Application Links

export const links_array = [
    {
        category: 'Products',
        items: [
            {
                title: 'Homeowners Building Insurance',
                text: 'Cover your home with our flagship Homeowners All Risk Policy',
                link: '/homeowners-building-insurance',
                component: Pages.HomeownersBuildingInsurance,
                icon: HouseIcon,
                breadCrum: 'Products',
            },
            {
                title: 'Community Living Insurance',
                text: 'Cover for Residential and Community living schemes',
                link: '/community-living-insurance',
                component: Pages.CommunityLivingInsurance,
                icon: ApartmentIcon,
                breadCrum: 'Products',
            },
            {
                title: 'Commercial Building Insurance',
                text: 'Cover for Commercial, Industrial and Residential buildings',
                link: '/commercial-building-insurance',
                component: Pages.CommercialBuildingInsurance,
                icon: ShopIcon,
                breadCrum: 'Products',
            },
            {
                title: 'Fidelity & Computer Crime Insurance',
                text: 'Based on the requirements of the Sectional Titles Management and Community Schemes',
                link: '/fidelity-computer-crime-insurance',
                component: Pages.FidelityComputerCrimeInsurance,
                icon: ComputerNetworkIcon,
                breadCrum: 'Products',
            },
        ],
    },
    {
        category: 'Useful Info',
        items: [
            {
                title: 'National Building Regulations',
                text: 'Requirements for design and construction to which all buildings must comply',
                link: '/national-building-regulations',
                component: Pages.NationalBuildingRegulations,
                icon: RegulationIcon,
                breadCrum: 'Useful Info',
            },
            {
                title: 'Newsletters',
                text: 'Stay updated with our latest news',
                link: '/newsletters',
                component: Pages.Newsletters,
                icon: EnvelopeIcon,
                breadCrum: 'Useful Info',
            },
            {
                title: 'What to do in the event of a claim',
                text: 'Follow these steps in case of a claim',
                link: '/claim#claim-steps',
                component: Pages.Claim,
                icon: LightBulbIcon,
                breadCrum: 'Useful Info',
            },
        ],
    },
    {
        category: 'Self Assist',
        items: [
            {
                title: 'Request Insurance Certificate',
                text: 'Issue an online Insurance Certificate',
                link: '/request-insurance-certificate',
                component: Pages.RequestInsuranceCertificate,
                icon: MultitaskingIcon,
                breadCrum: 'Self Assist',
            },
            {
                title: 'Online Broker Training',
                text: 'Access our CPD accredited online broker portal',
                link: '/online-broker-training',
                component: Pages.OnlineBrokerTraining,
                icon: LaptopIcon,
                breadCrum: 'Self Assist',
            },
            {
                title: 'Find a Broker',
                text: 'Browse our database to find the nearest broker',
                link: '/find-a-broker',
                component: Pages.FindBroker,
                icon: FindMyFriendIcon,
                breadCrum: 'Self Assist',
            },
            {
                title: 'Broker Agency Application',
                text: 'Get the info you need to work with us',
                link: '/broker-agency-application',
                component: Pages.BrokerAgencyApplication,
                icon: InsurancePolicyIcon,
                breadCrum: 'Self Assist',
            },
            {
                title: 'Existing Policy Schedule',
                text: 'Upload your policy schedule for quick assistance',
                link: '/policy-schedule',
                component: Pages.ExistingPolicySchedule,
                icon: PolicyIcon,
                breadCrum: 'Self Assist',
            },
            {
                title: 'Track a Claim',
                text: 'Track your claim in seconds',
                link: '/track-a-claim',
                component: Pages.TrackClaim,
                icon: ProcessIcon,
                breadCrum: 'Self Assist',
            },
        ],
    },
    {
        category: 'Quick Links',
        items: [
            {
                title: 'Home',
                text: null,
                link: '/',
                component: Pages.Home,
                hidden: true,
            },
            {
                title: 'Claim',
                text: null,
                link: '/claim',
                component: Pages.Claim,
            },
            {
                title: 'About Us',
                text: null,
                link: '/about-us',
                component: Pages.AboutUs,
            },
            {
                title: 'Contact Us',
                text: null,
                link: '/contact-us',
                component: Pages.ContactUs,
            },
            {
                title: 'Lodge a Claim',
                text: null,
                link: 'https://online.cia.co.za/GrailWeb',
                external: true,
                component: null,
            },
            {
                title: 'Get a Quote',
                text: null,
                link: '/get-quote',
                component: Pages.GetQuote,
            },
            {
                title: 'Register Online Training',
                text: null,
                link: '/register-online-training',
                component: Pages.RegisterOnlineBrokerTraining,
            },
            {
                title: 'Request Call Back',
                text: null,
                link: '/request-a-call-back',
                component: Pages.RequestCallBack,
            },
            {
                title: 'Legal',
                text: null,
                link: '/legal',
                component: Pages.Legal,
            },
            {
                title: 'Downloads',
                text: null,
                link: '/downloads',
                component: Pages.Downloads,
            },
            {
                title: 'Post',
                text: null,
                link: '/newsletter/post',
                component: Pages.Post,
                hidden: true,
            },
            {
                title: 'Nama Giveaway',
                text: null,
                link: '/prizes/nama-giveaway',
                component: Pages.NamaGiveaway,
                hidden: true,
            },
        ],
    },
];

// #endregion
