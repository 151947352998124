import React from 'react';
import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import { Container, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import SectionTitle from '../../../components/SectionTitle';

const TestimonialsRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    // '& .slick-arrow.slick-prev': {
    //     backgroundColor: '#D7412E',
    //     color: 'blue',
    // },
}));

const SlickContainer = styled(Container)(({ theme }) => ({
    padding: '0 100px',
    [theme.breakpoints.down('md')]: {
        padding: '0 50px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
    },
}));

const SlickSlider = styled(Slider)(({ theme }) => ({}));

const SlickItem = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

const NextButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#D7412E',
    opacity: 0.5,
    color: '#fff',
    height: '25px',
    width: '25px',
    fontSize: '16px',
    ':hover': {
        opacity: 1,
        backgroundColor: '#D7412E',
    },
    position: 'absolute',
    right: 250,
    marginTop: '50px',
}));

const PreviousButton = styled(IconButton)(({ theme }) => ({
    opacity: 0.5,
    // position: 'absolute',
    marginTop: '',
    padding: 0,
    // transform: 'translate(0, -50%)',
    height: '20px',
    width: '20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#D7412E',
    ':hover': {
        opacity: 1,
        backgroundColor: '#D7412E',
    },
}));

const testemonial_array = [
    {
        intro: null,
        text: '"Thank you so much Sindi. You are like lighting. Awesome service"',
        from: 'Annetjie Hall',
        company: 'Stepp Insurance Brokers',
    },
    {
        intro: '"Hi Mpho,',
        text: 'Many thanks. You are always very helpful and I appreciate your kind assistance"',
        from: 'Retha Schreuder',
        company: 'Osro CC',
    },
    {
        intro: null,
        text: '"Would like to thank you ladies and CIA for excellent service received"',
        from: 'Pauline Cooper',
        company: 'Unidel Carriers (Pty) Ltd',
    },
    {
        intro: '"Dear Mpho',
        text: 'You are the best!!!! Thank you so much for excellent service and your prompt response"',
        from: 'Mary-Ann Jonck',
        company: 'Hillside Risk Management (Pty) Ltd',
    },
    {
        intro: null,
        text: '"Thank you for the excellent service. This claim was paid on the same day that it was submitted."',
        from: 'Mariana van Zyl',
        company: 'NWIB',
    },
    {
        intro: '"Good day Sindi/Susan,',
        text: 'Thanking you for outstanding service. Client happy with the services from Karabo 😊"',
        from: 'Angie',
        company: 'HSS Brokers',
    },
    {
        intro: '"Morning Kobus',
        text: 'Thank you, we received the payment notification this morning. I also just want to say that the service I am receiving on the geyser claims is really great. Very quick and efficient. Please say thank you to the staff."',
        from: 'Heather MacMillan',
        company: 'De Wet De Villiers',
    },
    {
        intro: '"Hello Sophi',
        text: 'It is such a pleasure to work with you and your company, always great service!"',
        from: 'Nicky Greyling',
        company: 'APBCO Brokers',
    },
    {
        intro: '"Dear Jimmie',
        text: `Just wanted to let you know that your online video training course is absolutely amazing. Such an asset to learning about CIA's products - thank you so much for creating this platform for the industry - so helpful!”`,
        from: 'Jane Frances Pitt',
        company: 'Stepp Insurance Brokers',
    },
    {
        intro: '"Hi Suritha',
        text: 'I would like to thank you for your super and efficient services. Please can you send me the quote with the AOL that I can send it to Chairperson and tell them to authorise the contractor to go ahead."',
        from: 'Anja Schroeder',
        company: 'PSG Bryanston',
    },
    {
        intro: '"Hi Michelle,',
        text: 'Thank you for your excellent service and assistance with my geyser claim. I really appreciate it; my husband is also VERY IMPRESSED. Thanks once again."',
        from: 'Karen Jonker',
        company: 'FFG',
    },
    {
        intro: '"Good morning Ethel,',
        text: `Thank you for your reply on the claim update. We as Origin Financial want to thank you for the brilliant service that you've till date delivered to us as the broker and to our insured. You really went the extra mile over and above your daily duties. We thank you"`,
        from: 'Christo Oelsen',
        company: 'Origin Financial Services',
    },
    {
        intro: '"Good morning Suritha,',
        text: `Thank you so much! I understand this wasn't the most pleasant claim, but I really appreciate the effort you put in."`,
        from: 'Marlene Oberholzer',
        company: 'Fairhurst Risk Solutions',
    },
    {
        intro: '"Hi Riana,',
        text: 'Thank you so much! You are a star! all good and in order. Much appreciated for all your efficiency and kindness as always."',
        from: 'Annie',
        company: 'CCA Brokers',
    },
    {
        intro: '"Marc,',
        text: 'Ek wil julle graag met hierdie skrywe geluk wens met julle uitstekend eise diens en veral hierdie jaar met die inperking asook die bo gemiddelde aantal eise wat hierdie winter hanteer en aangemeld is. Julle het regtig alle verwagtinge oortref en is ons dankbaar vir CIA en elke lid van jou eise span."',
        from: 'Jannie Jacobs',
        company: 'Elite Makelaars',
    },
    {
        intro: '"Hi Roxanne,',
        text: 'Once again, many thanks for keeping us informed of progress - your friendly professionalism is very much appreciated and a refreshing change when dealing with big companies!"',
        from: 'John & Ingrid Waltham',
        company: 'Riverland Resort',
    },
];

const Testemonials = () => {
    const slider = React.useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <TestimonialsRoot>
            <SectionTitle
                title="Our Reviews"
                subtitle="See what our brokers and policyholders say"
            />

            <SlickContainer maxWidth="md">
                {/* <PreviousButton onClick={() => slider?.current?.slickPrev()}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </PreviousButton>
                <NextButton onClick={() => slider?.current?.slickNext()}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </NextButton> */}

                <SlickSlider ref={slider} {...settings}>
                    {testemonial_array.map((slick_item, index) => (
                        <SlickItem key={index}>
                            <FontAwesomeIcon
                                style={{ color: '#D7412E', fontSize: '25px' }}
                                icon={faQuoteRight}
                            />

                            {slick_item['intro'] && (
                                <Typography variant="body1" style={{ marginBottom: '15px' }}>
                                    {slick_item['intro']}
                                </Typography>
                            )}

                            <Typography variant="body1" style={{ marginBottom: '15px' }}>
                                {slick_item['text'] && slick_item['text']}
                            </Typography>

                            <Typography variant="subtitle1" style={{ color: '#666666' }}>
                                {slick_item['from'] && slick_item['from']}
                            </Typography>

                            <Typography variant="body1" style={{ marginBottom: '15px' }}>
                                {slick_item['company'] && slick_item['company']}
                            </Typography>
                        </SlickItem>
                    ))}
                </SlickSlider>
            </SlickContainer>
        </TestimonialsRoot>
    );
};

export default Testemonials;
