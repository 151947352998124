import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const ExtractsRegulationsRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const PageText = styled(Typography)(({ theme }) => ({
    marginBottom: '20px',
}));

const PageLinks = styled('div')(({ theme }) => ({
    marginBottom: '20px',
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // borderBottom: `1px solid ${theme.palette.divider}`,
    '& .Mui-expanded .MuiTypography-root': {
        color: '#D7412E',
        fontWeight: 600,
    },
    '& .Mui-expanded': {
        color: '#D7412E',
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexDirection: 'row-reverse',
    '&.Mui-expanded': {
        backgroundColor: 'rgba(0,0,0,0.03)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const ExtractsRegulations = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <ExtractsRegulationsRoot>
            <Container maxWidth="lg">
                <PageText variant="body1" m>
                    It is a fundamental requirement of the cover we provide that all buildings and
                    subsequent extensions have been built according to the national building
                    regulations in force at the time of construction.
                </PageText>
                <PageText variant="body1">
                    The regulations are extensive and cover all aspects of building design and
                    construction. Below is an extract of certain regulations that are particularly
                    important to residential buildings.
                </PageText>
                <PageText variant="body1">
                    Please note the information is provided below for general awareness purposes
                    only. The advice of a professional architect, quantity surveyor or engineer
                    should always be sought when embarking any building construction or extension
                    project.
                </PageText>
                <PageText variant="body1">
                    Further information can be obtained from the following websites:
                </PageText>
                <PageLinks>
                    <Link href="https://www.sans10400.co.za/" target="_blank" marginRight={5}>
                        www.sans10400.co.za
                    </Link>
                    <Link href="https://www.nrcs.org.za/" target="_blank">
                        www.nrcs.org.za
                    </Link>
                </PageLinks>

                <Typography variant="h4" marginBottom={1}>
                    Extracts from the National Building Regulations:
                </Typography>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Free-standing, Boundary & Garden Walls</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PageText variant="body1">
                            Unfortunately, we often find that boundary walls are not built in
                            compliance with legislation. This can result in a very unsafe situation
                            - especially in the vicinity of children.
                        </PageText>
                        <Typography variant="body1">
                            As per SANS 10400-K there are varying requirements for walls depending
                            on the width and the height of the wall. For example, a single brick
                            free standing wall can only be built to a maximum height of 1.4m and
                            needs double brick support pillars every 1.8 meters to comply with the
                            regulations.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Retaining Walls</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            In terms of legislation any wall that retains more than 30cm of soil is
                            considered to be a retaining wall. Retaining walls (which may also be
                            operating as boundary walls) are required to have adequate drainage by
                            weep holes. Weep holes of at least 50mm diameters, must be installed at
                            every 1.5 meters of the wall. Any retaining wall higher than 1.2 meters
                            must be designed and signed off by a professional Structural Engineer.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Electrical Compliance Certificates</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h5" marginBottom={1}>
                            Additions and alterations to buildings:
                        </Typography>
                        <Typography variant="body1">
                            In terms of SANS 10142 where any alteration or work on the electrical
                            reticulation of a building is done, a Certificate of Compliance must be
                            obtained in respect of the work that has been done.
                        </Typography>

                        <Typography variant="h5" marginY={2}>
                            Change of ownership:
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            When purchasing a new property, the buyer must ensure that they receive
                            a current (not older than two years) Certificate of Compliance from the
                            seller. A Certificate of Compliance is valid for two years but there is
                            no requirement to obtain a new one unless you alter the electrical
                            reticulation or sell your building.
                        </Typography>
                        <Typography variant="body1">
                            In other words, if you purchase a building with the appropriate
                            electrical certificate of compliance and make no electrical changes, you
                            need not obtain a new certificate until you sell the building.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Gas Installations</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" marginBottom={1}>
                            Gas stoves or any other gas installations like gas geysers or gas
                            fireplaces must be installed in accordance with the SABS and SANS (South
                            African National Standards) regulations. An installation certificate
                            must be issued by a SAQCC approved installer.
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            As per these regulations gas bottles larger than 19kg must be stored in
                            a well ventilated separate cage outside the building. The cage housing
                            the containers must be more than 1m away from any window, drain or door.
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            Dangerous gas work can be deadly. Badly fitted and poorly serviced
                            appliances can cause the following gas safety risks:
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            - Gas leaks
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            - Fires
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            - Explosion
                        </Typography>
                        <Typography variant="body1" marginBottom={1}>
                            - Carbon monoxide poisoning
                        </Typography>
                        <Typography variant="body1">
                            To ensure your safety and compliance with regulations only use a
                            registered installer to install, fix or maintain your appliances.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>Balustrades</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            In terms of SANS 10400 Part D: 4.2 the edge of any balcony, bridge, flat
                            roof or similar place more than 1 m above the adjacent ground or floor
                            level must be provided with a balustrade or parapet wall not less than
                            1m in height. In the case of an interior balcony or mezzanine floor,
                            like lofts, hand railings or balustrades must be fitted to the open
                            section of these floors or balconies. The balustrade or railing should
                            not have openings wider than 100mm.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </ExtractsRegulationsRoot>
    );
};

export default ExtractsRegulations;
