import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <Typography variant="h1" style={{ margin: '0 auto' }}>
                    Broker Agency Application
                </Typography>
                <Typography variant="body1" style={{ margin: '20px auto 0' }}>
                    Join over 1 600 brokers across South Africa and experience our specialist
                    service. No minimum book size or monthly targets applicable, our focus is
                    building enduring relationships with brokers who share our passion for insurance
                    and customer service.
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
