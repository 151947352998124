const formModel = {
    propertyLocation: {
        name: 'propertyLocation',
        label: 'Search Property*',
        requiredErrorMsg: 'This field is required',
    },
    postalCode: {
        name: 'postalCode',
    },
    gpsCoordinates: {
        name: 'gpsCoordinates',
    },
    sumInsredAmount: {
        name: 'sumInsredAmount',
        label: 'Building Replacement Value',
    },
    propertyQuestion1: {
        name: 'propertyQuestion1',
        label: `Are there any lapa's or other non-standard structures (such as wooden cabins or wendy houses) larger than 10m² situated on the property?`,
        requiredErrorMsg: 'This field is required',
    },
    complexName: {
        name: 'complexName',
        label: 'Complex Name/Insured Name*',
        requiredErrorMsg: 'This field is required',
        autocomplete: 'new-password',
    },
    numberOfUnits: {
        name: 'numberOfUnits',
        label: 'Number of Units*',
        requiredErrorMsg: 'This field is required',
        autocomplete: 'off',
    },
    buildingOccupation: {
        name: 'buildingOccupation',
        label: 'Building Occupation*',
        requiredErrorMsg: 'This field is required',
    },
    roofConstruction: {
        name: 'roofConstruction',
        label: 'Roof Construction*',
        requiredErrorMsg: 'This field is required',
    },
    wallConstruction: {
        name: 'wallConstruction',
        label: 'Wall Construction*',
        requiredErrorMsg: 'This field is required',
    },
    contactPerson: {
        name: 'contactPerson',
        label: 'Contact Person*',
        requiredErrorMsg: 'This field is required',
    },
    contactNumber: {
        name: 'contactNumber',
        label: 'Contact Number*',
        requiredErrorMsg: 'This field is required',
    },
    email: {
        name: 'email',
        label: 'Email Address*',
        requiredErrorMsg: 'This field is required',
    },
    yourBroker: {
        name: 'yourBroker',
        label: 'Your Broker',
        requiredErrorMsg: 'This field is required',
    },
    preferredBranch: {
        name: 'preferredBranch',
        label: 'Preferred Branch',
        requiredErrorMsg: 'This field is required',
    },
};

export default formModel;
