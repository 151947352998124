import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@mui/material';

import SectionTitle from '../../../components/SectionTitle';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
    textAlign: 'center',
}));

const IntroLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
}));

const Introduction = () => {
    const history = useHistory();

    return (
        <IntroductionRoot>
            <SectionTitle subtitle="Select your cover" hideArrow />
            <Typography variant="body1" paddingX={3}>
                or <IntroLink onClick={() => history.push('/policy-schedule')}>upload</IntroLink>{' '}
                your existing schedule:
            </Typography>
        </IntroductionRoot>
    );
};

export default Introduction;
