// #region Imports

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Box,
    Container,
    FormControl,
    Hidden,
    InputLabel,
    MenuItem,
    Select,
    Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FidelityCover from './quote-wizard/fidelity/FidelityCover';
import HomeownersWizard from './quote-wizard/homeowners/HomeownersWizard';
import CommunityWizard from './quote-wizard/community/CommunityWizard';
import CommercialWizard from './quote-wizard/commercial/CommercialWizard';

// #endregion

// #region Styles

const GetQuoteWizardRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const WizardTabList = styled(TabList)(({ theme }) => ({
    '& .MuiTabs-indicator': {},
}));

const WizardTab = styled(Tab)(({ theme }) => ({
    textTransform: 'capitalize',
    fontSize: '17px',
    fontWeight: '600',
    color: '#586069',
    borderRadius: '5px',
    '&.Mui-selected': {
        backgroundColor: '#F5F5F5',
    },
}));

const WizardTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: '24px 0px',
    [theme.breakpoints.down('sm')]: {
        padding: '24px 5px',
    },
}));

// #endregion

const GetQuoteWizard = () => {
    const location = useLocation();
    const history = useHistory();

    const [value, setValue] = useState(() => {
        switch (location.hash) {
            case '#homeowners-insurance':
                return '1';
            case '#community-insurance':
                return '2';
            case '#commercial-insurance':
                return '3';
            case '#fidelity-computer-crime-insurance':
                return '4';
            default:
                return '1';
        }
    });

    const handleTabsChange = (event, newValue) => {
        setValue(newValue);

        switch (newValue) {
            case '1':
                history.push('/get-quote#homeowners-insurance');
                break;
            case '2':
                history.push('/get-quote#community-insurance');
                break;
            case '3':
                history.push('/get-quote#commercial-insurance');
                break;
            case '4':
                history.push('/get-quote#fidelity-computer-crime-insurance');
                break;
            default:
                break;
        }
    };

    return (
        <GetQuoteWizardRoot>
            <Container maxWidth="lg">
                <TabContext value={value}>
                    <Hidden smUp>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="quote-wizard-select-label">
                                    Choose Cover:
                                </InputLabel>
                                <Select
                                    labelId="quote-wizard-select-label"
                                    id="quote-wizard-select"
                                    value={value}
                                    label="Choose Cover:"
                                    onChange={(event) => setValue(event.target.value)}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value="1">Homeowners</MenuItem>
                                    <MenuItem value="2">Community</MenuItem>
                                    <MenuItem value="3">Commercial</MenuItem>
                                    <MenuItem value="4">Fidelity & Computer Crime</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Hidden>
                    <Hidden smDown>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <WizardTabList
                                onChange={handleTabsChange}
                                aria-label="quote-wizard"
                                variant="fullWidth"
                            >
                                <WizardTab label="Homeowners" value="1" />
                                <WizardTab label="Community" value="2" />
                                <WizardTab label="Commercial" value="3" />
                                <WizardTab label="Fidelity & Computer Crime" value="4" />
                            </WizardTabList>
                        </Box>
                    </Hidden>
                    <WizardTabPanel value="1">
                        <HomeownersWizard />
                    </WizardTabPanel>
                    <WizardTabPanel value="2">
                        <CommunityWizard />
                    </WizardTabPanel>
                    <WizardTabPanel value="3">
                        <CommercialWizard />
                    </WizardTabPanel>
                    <WizardTabPanel value="4">
                        <FidelityCover />
                    </WizardTabPanel>
                </TabContext>
            </Container>
        </GetQuoteWizardRoot>
    );
};

export default GetQuoteWizard;
