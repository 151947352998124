import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Container, Grid, Link, Typography } from '@mui/material';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import RecaptchaText from '../RecaptchaText';
import FormMessage from './FormMessage';
import RecaptchaField from '../recaptcha/RecaptchaField';

const WebsiteFormsRoot = styled('div')(({ theme }) => ({
    // margin: '24px 0 0',
}));

const WebsiteFormsContainer = styled(Container)(({ theme }) => ({
    padding: '0 24px',
}));

const WebsiteForms = (props) => {
    const { children, formUrl, initialValues, validationSchema, btnText, handleResponse } = props;

    const [activeValidation, setActiveValidation] = useState(false);
    const [formResponse, setFormResponse] = useState(null);
    const recaptchaRef = useRef();

    const submit = async (values, actions) => {
        const timeout = setTimeout(() => {
            setFormResponse({
                isSuccessful: false,
                message: 'Your submission could not be sent. Please try again later.',
            });
            setActiveValidation(false);
            actions.setSubmitting(false);
            return;
        }, 60000);

        const token = await recaptchaRef.current.executeAsync();
        values['token'] = token;

        var result = await AxiosRequest.post(formUrl, values);

        clearTimeout(timeout);

        setFormResponse(result);
        handleResponse(result);
        setActiveValidation(false);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    return (
        <WebsiteFormsRoot>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={activeValidation}
                validateOnBlur={activeValidation}
                onSubmit={(values, actions) => {
                    submit(values, actions);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <RecaptchaField recaptchaRef={recaptchaRef} />
                        </div>

                        <WebsiteFormsContainer maxWidth="lg">
                            <FormMessage response={formResponse} />

                            <div style={{ margin: '20px 0' }}>{children}</div>

                            <div style={{ display: 'flex' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ margin: '0 auto' }}
                                    onClick={() => {
                                        recaptchaRef.current.reset();
                                        setActiveValidation(true);
                                    }}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            color="secondary"
                                            sx={{
                                                color: '#FFFFFF',
                                            }}
                                            size={20}
                                        />
                                    ) : (
                                        btnText
                                    )}
                                </Button>
                            </div>

                            <RecaptchaText />
                        </WebsiteFormsContainer>
                    </Form>
                )}
            </Formik>
        </WebsiteFormsRoot>
    );
};

WebsiteForms.defaultProps = {
    initialValues: {},
    validationSchema: {},
    btnText: 'Submit',
};

export default WebsiteForms;
