const formModel = {
    name: {
        name: 'name',
        label: 'Name*',
        requiredErrorMsg: 'Name is required',
    },
    email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email is required',
    },
    company: {
        name: 'company',
        label: 'Company*',
        requiredErrorMsg: 'Company is required',
    },
};

export default formModel;
