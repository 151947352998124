import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import BrokerApplicationForm from '../../../../assets/documents/Broker-Application-Form.pdf';

const DownloadAreaRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
    textAlign: 'center',
}));

const DownloadArea = () => {
    return (
        <DownloadAreaRoot>
            <Container maxWidth="lg">
                <Typography variant="h4" style={{ margin: '0 auto' }}>
                    Download the broker application from{' '}
                    <Link href={BrokerApplicationForm} target="_blank">
                        here
                    </Link>
                </Typography>
                <Typography variant="body1" marginY={2}>
                    Once completed please send the completed form to{' '}
                    <Link href="mailto:estelle@cia.co.za">estelle@cia.co.za</Link>
                </Typography>
                <Typography variant="h5">We'd love to work with you!</Typography>
            </Container>
        </DownloadAreaRoot>
    );
};

export default DownloadArea;
