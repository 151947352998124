import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { CircularProgress, Divider, Typography } from '@mui/material';
import AxiosRequest from '../../../utils/axios/AxiosRequest';
import NewsletterPagination from './components/NewsletterPagination';

const ListPostsRoot = styled('div')(({ theme }) => ({}));

const PostListContainer = styled('div')(({ theme }) => ({
    marginBottom: '20px',
}));

const PostContainer = styled('div')(({ theme }) => ({
    padding: '15px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: '250ms',
    ':hover': {
        boxShadow: '0px 1px 27px -8px rgb(0 0 0 / 20%)',
    },
}));

const PostTitle = styled('div')(({ theme }) => ({}));

const LoadingContainer = styled('div')(({ theme }) => ({
    minHeight: '600px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ListPosts = () => {
    const history = useHistory();

    const [ready, setReady] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [posts, setPosts] = useState([]);

    const parser = useRef(new DOMParser());

    const getPosts = async () => {
        var result = await AxiosRequest.get('api/newsletter');

        setPosts(result);
        setTotalCount(result.length);
        setReady(true);
    };

    const getDate = (date) => {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        var newDate = new Date(date.replace('T', ' '));
        return `${monthNames[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
    };

    useEffect(() => {
        getPosts();
    }, []);

    return ready ? (
        <ListPostsRoot>
            <PostListContainer id="post-container">
                {posts
                    .slice(
                        currentPage * pageSize - pageSize,
                        currentPage * pageSize - pageSize + pageSize
                    )
                    .map((post, post_index) => (
                        <Fragment key={post_index}>
                            <PostContainer
                                onClick={() =>
                                    history.push({
                                        pathname: '/newsletter/post',
                                        search: `?query=${post['id']}`,
                                        state: { detail: post },
                                    })
                                }
                            >
                                <PostTitle>
                                    <Typography variant="h3" fontSize={20} color={`#4D4D4D`}>
                                        {post['title']}
                                    </Typography>
                                    <Typography variant="body1" marginY={1}>
                                        {getDate(post['dateCreated'])}
                                    </Typography>
                                    <div
                                        style={{
                                            height: '4px',
                                            width: '40px',
                                            backgroundColor: '#D7412E',
                                        }}
                                    ></div>
                                </PostTitle>

                                <Typography
                                    variant="body1"
                                    marginY={2}
                                    dangerouslySetInnerHTML={{ __html: post['intro'] }}
                                ></Typography>
                            </PostContainer>

                            <Divider />
                        </Fragment>
                    ))}
            </PostListContainer>
            <NewsletterPagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={(value) => {
                    window.scrollTo(
                        0,
                        window.pageYOffset +
                            document.getElementById('post-container').getBoundingClientRect().top -
                            82
                    );

                    setCurrentPage(value);
                }}
            />
        </ListPostsRoot>
    ) : (
        <LoadingContainer>
            <CircularProgress />
        </LoadingContainer>
    );
};

export default ListPosts;
