import React from 'react';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../../components/SectionTitle';
import { Typography } from '@mui/material';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <SectionTitle subtitle="Request a call back" hideArrow />
            <Typography variant="body1">
                Please provide us with your details so we can get in touch.
            </Typography>
        </IntroductionRoot>
    );
};

export default Introduction;
