import formModel from './formModel';

const {
    attorneysName,
    attorneysPostalAddress,
    attorneysTel,
    attorneysContactPerson,
    email,
    ownerName,
    bodyCorporateName,
    sections,
    ssNumber,
    sumInsured,
    bankName,
    bankAddress,
} = formModel;

const initialValues = {
    [attorneysName.name]: '',
    [attorneysPostalAddress.name]: '',
    [attorneysTel.name]: '',
    [attorneysContactPerson.name]: '',
    [email.name]: '',
    [ownerName.name]: '',
    [bodyCorporateName.name]: '',
    [sections.name]: '',
    [ssNumber.name]: '',
    [sumInsured.name]: '',
    [bankName.name]: '',
    [bankAddress.name]: '',
};

export default initialValues;
