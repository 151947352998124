import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography } from '@mui/material';
import initialValues from '../../validation/prizes/nama/initialValues';
import validationSchema from '../../validation/prizes/nama/validationSchema';
import formModel from '../../validation/prizes/nama/formModel';
import InputField from './form-fields/InputField';
import WebsiteForms from './WebsiteForms';

const NamaGiveawayFormFormRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const NamaGiveawayFormForm = () => {
    const { name, email, company } = formModel;

    const [response, setResponse] = useState(undefined);

    if (response && response.isSuccessful) {
        return (
            <NamaGiveawayFormFormRoot>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '0px 30px',
                    }}
                >
                    <Typography variant="h1" fontSize="25px" marginBottom={4}>
                        Success, time to claim your gift!
                    </Typography>

                    <Typography variant="subtitle2">
                        Thanks for signing up. To claim your gift, you can:
                    </Typography>

                    <Typography variant="subtitle2" maxWidth={450}>
                        <ul>
                            <li>Take a screenshot of this page</li>
                            <li>Show us the confirmation email sent to the address you provided</li>
                        </ul>
                    </Typography>

                    <Typography variant="subtitle2">
                        Come collect your gift from our stand (Stand No. 8).
                    </Typography>
                </Box>
            </NamaGiveawayFormFormRoot>
        );
    }

    return (
        <NamaGiveawayFormFormRoot>
            <WebsiteForms
                formUrl="api/forms/prizes/nama-giveaway"
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleResponse={(response) => setResponse(response)}
            >
                <Grid container spacing={7}>
                    <Grid item xs={12} sm={6}>
                        <InputField name={name.name} label={name.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={email.name} label={email.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField name={company.name} label={company.label} fullWidth />
                    </Grid>
                </Grid>
            </WebsiteForms>
        </NamaGiveawayFormFormRoot>
    );
};

export default NamaGiveawayFormForm;
