import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Divider, Fade, Button, Typography, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons';

import TrackClaimForm from '../../../components/forms/TrackClaimForm';

const TrackClaimRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
    textAlign: 'center',
}));

const ClaimDataRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const ClaimDataHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

const ClaimDataHeaderBtn = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
}));

const TrackClaim = () => {
    const [showTracking, setShowTracking] = useState(false);
    const [contactHandler, setContactHandler] = useState(null);
    const [escalate, setEscalate] = useState(null);
    const [claimData, setClaimData] = useState([]);
    const [claimNumber, setClaimNumber] = useState(null);

    const getText = (key, value) => {
        switch (key) {
            case 'claim_Grouping_Detailed':
                return 'Type of Claim';
            case 'claim_Status_Grouped':
                return 'Claim Status';
            case 'date_Notified':
                return 'Date Notified';
            case 'date_of_Loss':
                return 'Date of Loss';
            case 'entry_Date':
                return 'Entry Date';
            case 'handler':
                return 'Handler';
            case 'handler_Email':
                setContactHandler(value);
                setEscalate(value);
                return 'Handler Email';
            case 'handler_Tel_No':
                return 'Handler Telephone';
            case 'insured':
                return 'Insured';
            case 'loss_Adjuster_Appointed':
                return 'Loss Adjuster Appointed';
            case 'plumber_Appointed':
                return 'Plumber Appointed';
            case 'policyNo':
                return 'Policy Number';
            case 'unit_Number':
                return 'Unit Number';
            default:
                break;
        }
    };

    const filterDate = (value) => {
        const reg = new RegExp(/^\d+$/);
        let testDate = new Date(value.toString());

        if (!reg.test(value.toString()) && testDate instanceof Date && !isNaN(testDate))
            return `${testDate.toLocaleDateString('en-ZA').replaceAll('/', '-')}`;

        return value;
    };

    const handleTracking = (result) => {
        var tempArray = [];
        for (const key in result['payload']) {
            if (key !== 'claimNo') {
                if (Object.hasOwnProperty.call(result['payload'], key)) {
                    const element = result['payload'][key];
                    tempArray.push({
                        text: getText(key, element),
                        value: element,
                    });
                }
            } else {
                setClaimNumber(result['payload'][key]);
            }
        }

        setClaimData(tempArray);
        setShowTracking(true);
    };

    return !showTracking ? (
        <Fade in={true}>
            <TrackClaimRoot>
                <Typography variant="h1">Let's Start Here</Typography>
                <Typography variant="body1" marginTop={1}>
                    Please enter your policy and claim number.
                </Typography>

                <TrackClaimForm handleTracking={handleTracking} />
            </TrackClaimRoot>
        </Fade>
    ) : (
        <Fade in={true}>
            <ClaimDataRoot>
                <Container maxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid
                            item
                            xs={12}
                            alignItems="center"
                            justifyContent="center"
                            display="flex"
                        >
                            <ClaimDataHeaderBtn
                                variant="contained"
                                color="primary"
                                onClick={() => setShowTracking(false)}
                            >
                                Track Another Claim
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlassLocation}
                                    fontSize="16px"
                                    style={{ marginLeft: '5px' }}
                                />
                            </ClaimDataHeaderBtn>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" marginLeft={2}>
                                Claim Number: {claimNumber}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider style={{ margin: '10px 0' }} />

                    {claimData.map((item, index) => (
                        <div key={index}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" marginLeft={2}>
                                        {item['text']}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6" marginLeft={2}>
                                        {filterDate(item['value'])}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: '7px 0' }} />
                        </div>
                    ))}

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1" fontStyle="italic" marginY={2}>
                                For assistance on this claim, please contact the claims handler or
                                alternatively escalate to a manager by clicking below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    (window.location.href = `mailto:${contactHandler}?subject=Claim Number: ${claimNumber}`)
                                }
                            >
                                Contact Handler
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    (window.location.href = `mailto:${escalate}?subject=Claim Number: ${claimNumber}`)
                                }
                                style={{ marginLeft: '10px' }}
                            >
                                Escalate
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </ClaimDataRoot>
        </Fade>
    );
};

export default TrackClaim;
