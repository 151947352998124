import formModel from './formModel';

const {
    propertyLocation,
    postalCode,
    gpsCoordinates,
    broadformBuildingValue,
    broadformHomeownersValue,
    packageOption,
    sumInsredAmount,
    propertyQuestion1,
    propertyQuestion2,
    propertyQuestion3,
    propertyQuestion4,
    propertyQuestion5,
    firstname,
    surname,
    contactNumber,
    email,
    yourBroker,
    publicizeOption,
    referralArea,
} = formModel;

const initialValues = {
    [propertyLocation.name]: '',
    [postalCode.name]: '',
    [gpsCoordinates.name]: '',
    [broadformBuildingValue.name]: '',
    [broadformHomeownersValue.name]: '',
    [packageOption.name]: 'Broadform Homeowners',
    [sumInsredAmount.name]: '2500000',
    [propertyQuestion1.name]: '',
    [propertyQuestion2.name]: '',
    [propertyQuestion3.name]: '',
    [propertyQuestion4.name]: '',
    [propertyQuestion5.name]: '',
    [firstname.name]: '',
    [surname.name]: '',
    [contactNumber.name]: '',
    [email.name]: '',
    [yourBroker.name]: '',
    [publicizeOption.name]: '',
    [referralArea.name]: '',
};

export default initialValues;
