import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import DownloadArea from './components/DownloadArea';

const BrokerAgencyApplicationRoot = styled('div')(({ theme }) => ({}));

const BrokerAgencyApplication = () => {
    return (
        <Fade in={true}>
            <BrokerAgencyApplicationRoot>
                <Introduction />

                <DownloadArea />
            </BrokerAgencyApplicationRoot>
        </Fade>
    );
};

export default BrokerAgencyApplication;
