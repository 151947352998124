import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <SectionTitle subtitle="Submit a claim" />
            <Typography variant="body1" paddingX={3}>
                Need to claim? Start your claim online, contact us for your geyser emergency or
                download a claim form and submit it in your own time.
            </Typography>
        </IntroductionRoot>
    );
};

export default Introduction;
