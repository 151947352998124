import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Link, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import initialValues from '../../validation/track-claim/initialValues';
import validationSchema from '../../validation/track-claim/validationSchema';
import formModel from '../../validation/track-claim/formModel';
import InputField from './form-fields/InputField';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import FormMessage from './FormMessage';
import RecaptchaField from '../recaptcha/RecaptchaField';

const NewsletterFormRoot = styled('div')(({ theme }) => ({
    margin: '24px 0 0',
}));

const NewsletterFormContainer = styled(Container)(({ theme }) => ({
    padding: '0 24px',
}));

const RecaptchaText = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    marginTop: '20px',
}));

const NewsletterForm = (props) => {
    const { handleTracking } = props;
    const { policyNumber, claimNumber } = formModel;

    const [activeValidation, setActiveValidation] = useState(false);
    const [formResponse, setFormResponse] = useState(null);
    const recaptchaRef = useRef();

    const submit = async (values, actions) => {
        setFormResponse(null);

        values = {
            policyNumber: parseInt(values.policyNumber.replace(/\D/g, '').replace(/^0+/, '')),
            claimNumber: parseInt(values.claimNumber.replace(/\D/g, '').replace(/^0+/, '')),
        };

        if (!values.policyNumber || !values.claimNumber) {
            actions.setSubmitting(false);
            setFormResponse({ isSuccessful: false, message: 'Invalid Policy or Claim Number' });
            return;
        }

        // const token = await recaptchaRef.current.executeAsync();
        // values['token'] = token;

        var result = await AxiosRequest.post(`api/trackclaim`, values);

        if (result['isSuccessful']) {
            handleTracking(result);
        }

        setFormResponse(result);
        setActiveValidation(false);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    return (
        <NewsletterFormRoot>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={activeValidation}
                validateOnBlur={activeValidation}
                onSubmit={submit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <RecaptchaField recaptchaRef={recaptchaRef} />

                        <NewsletterFormContainer maxWidth="lg">
                            <FormMessage response={formResponse} />

                            <Grid container spacing={5}>
                                <Grid
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                    }}
                                    md={2}
                                />
                                <Grid item xs={12} md={4}>
                                    <InputField
                                        name={policyNumber.name}
                                        label={policyNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputField
                                        name={claimNumber.name}
                                        label={claimNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                    }}
                                    md={2}
                                />
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: '0 auto' }}
                                        onClick={() => {
                                            recaptchaRef.current.reset();
                                            setActiveValidation(true);
                                        }}
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                color="secondary"
                                                sx={{
                                                    color: '#FFFFFF',
                                                }}
                                                size={20}
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>

                            <RecaptchaText variant="body1">
                                This site is protected by reCAPTCHA and the{' '}
                                <Link href="https://policies.google.com/privacy" target="_blank">
                                    Google Privacy Policy
                                </Link>{' '}
                                and{' '}
                                <Link href="https://policies.google.com/terms" target="_blank">
                                    Terms of Service
                                </Link>{' '}
                                apply.
                            </RecaptchaText>
                        </NewsletterFormContainer>
                    </Form>
                )}
            </Formik>
        </NewsletterFormRoot>
    );
};

export default NewsletterForm;
