const formModel = {
    attorneysName: {
        name: 'attorneysName',
        label: 'Firm of Attorneys Name*',
        requiredErrorMsg: 'Attorneys Name is required',
    },
    attorneysPostalAddress: {
        name: 'attorneysPostalAddress',
        label: 'Attorney Postal Address*',
        requiredErrorMsg: 'This field is required',
    },
    attorneysTel: {
        name: 'attorneysTel',
        label: 'Attorney Telephone Number*',
        requiredErrorMsg: 'This field is required',
    },
    attorneysContactPerson: {
        name: 'attorneysContactPerson',
        label: 'Attorney Contact Person*',
        requiredErrorMsg: 'This field is required',
    },
    email: {
        name: 'email',
        label: 'Email Address*',
        requiredErrorMsg: 'This field is required',
    },
    ownerName: {
        name: 'ownerName',
        label: 'Name of Owner / Bondholder*',
        requiredErrorMsg: 'This field is required',
    },
    bodyCorporateName: {
        name: 'bodyCorporateName',
        label: 'Name of Body Corporate*',
        requiredErrorMsg: 'This field is required',
    },
    sections: {
        name: 'sections',
        label: 'Sections*',
        requiredErrorMsg: 'This field is required',
    },
    ssNumber: {
        name: 'ssNumber',
        label: 'SS Number*',
        requiredErrorMsg: 'This field is required',
    },
    sumInsured: {
        name: 'sumInsured',
        label: 'Sum Insured Required*',
        requiredErrorMsg: 'This field is required',
    },
    bankName: {
        name: 'bankName',
        label: 'Name of Bank / Lender*',
        requiredErrorMsg: 'This field is required',
    },
    bankAddress: {
        name: 'bankAddress',
        label: 'Contact Address of Bank*',
        requiredErrorMsg: 'This field is required',
    },
};

export default formModel;
