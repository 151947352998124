import React, { useEffect, useState } from 'react';
import { Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const FormMessageRoot = styled('div')(({ theme }) => ({
    margin: '20px auto 0px',
    maxWidth: '600px',
}));

const FormMessage = (props) => {
    const { response } = props;
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
    }, [response]);

    return (
        response && (
            <FormMessageRoot>
                <Collapse in={open}>
                    <Alert
                        severity={response['isSuccessful'] ? 'success' : 'error'}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {response['message']}
                    </Alert>
                </Collapse>
            </FormMessageRoot>
        )
    );
};

export default FormMessage;
