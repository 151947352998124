import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { IconButton } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const PaginationContainer = styled('div')(({ theme }) => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    listStyleType: 'none',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
        margin: '0 auto 0 0',
        position: 'relative',
        left: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
    },
}));

const PaginationItem = styled(IconButton)(({ theme, selected }) => ({
    borderRadius: '5px',
    padding: '5px',
    height: '30px',
    minWidth: '30px',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
    backgroundColor: selected ? '#f0f0f0' : 'transperant',
}));

const DOTS = '...';

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
    start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        /*
        We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
};

const NewsletterPagination = (props) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <PaginationContainer>
            <PaginationItem
                aria-label="previous"
                size="small"
                disabled={currentPage === 1}
                onClick={onPrevious}
            >
                <ChevronLeft />
            </PaginationItem>

            {paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                    return (
                        <PaginationItem aria-label="dots" size="small" disabled={true} key={i}>
                            &#8230;
                        </PaginationItem>
                    );
                }

                return (
                    <PaginationItem
                        aria-label="number"
                        size="small"
                        onClick={() => onPageChange(pageNumber)}
                        selected={pageNumber === currentPage}
                        key={i}
                    >
                        {pageNumber}
                    </PaginationItem>
                );
            })}

            <PaginationItem
                aria-label="next"
                size="small"
                disabled={currentPage === lastPage}
                onClick={onNext}
            >
                <ChevronRight />
            </PaginationItem>
        </PaginationContainer>
    );
};

export default NewsletterPagination;
