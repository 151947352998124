import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';

const OnlineBrokerTrainingRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const OnlineTrainingDescription = () => {
    return (
        <OnlineBrokerTrainingRoot>
            <Container maxWidth="lg">
                <Typography variant="h1">Broker training workshops</Typography>

                <Typography variant="body1" marginY={3}>
                    CIA regularly provides product-specific training detailing the benefits, terms,
                    conditions, limitations and exclusions which apply to our plain language Assets
                    All Risks products. Our training workshops have been approved by the Insurance
                    Institute of South Africa (IISA) for members of the IISA for whom Continuous
                    Professional Development (CPD) hours are required to maintain their membership.
                </Typography>

                <Typography variant="body1">
                    Training workshops can be arranged for groups or on a one on one basis by
                    contacting the broker consultant at the branch in your area. Please contact{' '}
                    <Link href="mailto:kayla@cia.co.za">kayla@cia.co.za</Link> for more information.
                </Typography>
            </Container>
        </OnlineBrokerTrainingRoot>
    );
};

export default OnlineTrainingDescription;
