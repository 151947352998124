import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Breadcrumbs, Link, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import NewsletterContainer from './NewsletterContainer';
import NewsletterSidebar from './NewsletterSidebar';
import AxiosRequest from '../../../utils/axios/AxiosRequest';

const PostRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const LoadingContainer = styled('div')(({ theme }) => ({
    minHeight: '600px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ShareBtn = styled(IconButton)(({ theme }) => ({
    fontSize: '20px',
    height: '34px',
    width: '34px',
    borderRadius: '5px',
    margin: '0 5px',
    backgroundColor: '#F1F1F1',
}));

const ShareContainer = styled('div')(({ theme }) => ({}));

const ShareButtons = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const Post = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [ready, setReady] = useState(false);
    const [component, setComponent] = useState(null);
    const [compMessage, setCompMessage] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');

    // Meta tags states
    const [metaImage, setMetaImage] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaUrl, setMetaUrl] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaType, setMetaType] = useState('');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const renderPost = (postDetails) => {
        try {
            import(`./post-components/posts/${postDetails['component']}`).then((c) => {
                setComponent(c.default);

                if (postDetails && 'title' in postDetails) {
                    setMetaTitle(postDetails['title']);
                }

                if (postDetails && 'fileName' in postDetails) {
                    const baseUrl = (`/newsletter-pdfs/${postDetails['fileName']}`);
                    console.log('test', baseUrl)
                    setPdfUrl(baseUrl);
                }
                
                if (postDetails && 'intro' in postDetails) {
                    setMetaDescription(postDetails['intro']);
                }

                setMetaUrl(window.location.href);

                setReady(true);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getPost = async () => {
            var result = await AxiosRequest.get(`api/newsletter/${params['query']}`);

            if (result && 'isSuccessful' in result && !result['isSuccessful']) {
                setCompMessage(result['message']);
                setReady(true);
                return;
            }

            renderPost(result['payload']);
    };

    useEffect(() => {
        ready && setReady(false);
        setCompMessage('');

        if (params && Object.keys(params).length > 0 && params.constructor === Object) {
            if (location.state && 'detail' in location.state && 'query' in params) {
                renderPost(location.state.detail);
            } else {
                getPost();
            }
        }
    }, [location]);

    const handlePostClick = (item) => {
        setReady(false);
        if (item){
            setReady(false);
            try {
                import(`./post-components/posts/${item['component']}`).then((c) => {
                    setComponent(c.default);
                    setReady(true);
                    history.push(`/newsletter/post?query=${item['id']}`);
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <Fade in={true}>
            <PostRoot>
                <Container maxWidth="lg">
                    <Grid container spacing={6}>
                        <Grid item xs={12} lg={8}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ padding: '15px 8px' }}>
                                <Link
                                    underline="hover"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => history.push('/')}
                                >
                                    Home
                                </Link>
                                <Link
                                    underline="hover"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => history.push('/newsletters')}
                                >
                                    Newsletters
                                </Link>
                                <Typography variant="h6">Post</Typography>
                            </Breadcrumbs>
                            {!ready ? (
                                <LoadingContainer>
                                    <CircularProgress />
                                </LoadingContainer>
                            ) : compMessage ? (
                                <div
                                    style={{
                                        minHeight: '600px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h5" textAlign="center">
                                        {compMessage}
                                    </Typography>
                                </div>
                            ) : (
                                component
                            )}
                            <Divider style={{ margin: '20px 0' }} />
                            <ShareContainer>
                                <Typography variant="h5" marginBottom={1}>
                                    Share on:
                                </Typography>

                                <ShareButtons>
                                    <ShareBtn>
                                        <FacebookShareButton url={window.location.href}>
                                            <FontAwesomeIcon icon={faFacebookF} color="#4267B2" />
                                        </FacebookShareButton>
                                    </ShareBtn>
                                    <ShareBtn>
                                        <LinkedinShareButton url={window.location.href}>
                                            <FontAwesomeIcon icon={faLinkedinIn} color="#0072b1" />
                                        </LinkedinShareButton>
                                    </ShareBtn>
                                    <ShareBtn>
                                        <WhatsappShareButton url={window.location.href}>
                                            <FontAwesomeIcon icon={faWhatsapp} color="#25D366" />
                                        </WhatsappShareButton>
                                    </ShareBtn>
                                    <ShareBtn>
                                        <EmailShareButton
                                            subject={`CIA Building Insurance Specialist Newsletter - ${metaTitle}`}
                                            url={window.location.href}
                                        >
                                            <FontAwesomeIcon icon={faEnvelope} color="#D7412E" />
                                        </EmailShareButton>
                                    </ShareBtn>
                                    <ShareBtn>
                                             {pdfUrl ? (
                                                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                                             <FontAwesomeIcon icon={faFilePdf} color="#D7412E" />
                                                </a>
                                             ) : (
                                          <FontAwesomeIcon icon={faFilePdf} color="#D7412E" />
                                             )}
                                       </ShareBtn>
                                </ShareButtons>
                            </ShareContainer>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <NewsletterSidebar handlePostClick={handlePostClick} />
                        </Grid>
                    </Grid>
                </Container>
            </PostRoot>
        </Fade>
    );
};

export default Post;
