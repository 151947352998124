import * as Yup from 'yup';
import formModel from './formModel';

const {
    propertyLocation,
    postalCode,
    gpsCoordinates,
    broadformBuildingValue,
    broadformHomeownersValue,
    packageOption,
    sumInsredAmount,
    propertyQuestion1,
    propertyQuestion2,
    propertyQuestion3,
    propertyQuestion4,
    propertyQuestion5,
    firstname,
    surname,
    contactNumber,
    email,
    yourBroker,
    publicizeOption,
    referralArea,
} = formModel;

const validationSchema = [
    Yup.object().shape({
        [propertyLocation.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [postalCode.name]: Yup.string(),
        [gpsCoordinates.name]: Yup.string(),
        [broadformBuildingValue.name]: Yup.string(),
        [broadformHomeownersValue.name]: Yup.string(),
        [sumInsredAmount.name]: Yup.string(),
        [propertyQuestion1.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [propertyQuestion2.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [propertyQuestion3.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [propertyQuestion4.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [propertyQuestion5.name]: Yup.string().required(`${propertyLocation.requiredErrorMsg}`),
        [referralArea.name]: Yup.string(),
    }),
    Yup.object().shape({}),
    Yup.object().shape({
        [packageOption.name]: Yup.string(),
        [firstname.name]: Yup.string().required(`${firstname.requiredErrorMsg}`),
        [surname.name]: Yup.string().required(`${surname.requiredErrorMsg}`),
        [contactNumber.name]: Yup.string().required(`${contactNumber.requiredErrorMsg}`),
        [email.name]: Yup.string()
            .email('Incorrect email format')
            .required(`${email.requiredErrorMsg}`),
        [yourBroker.name]: Yup.string(),
        [publicizeOption.name]: Yup.string(),
    }),
];

export default validationSchema;
