import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Typography } from '@mui/material';
import GoogleMap from '../../components/website-map/GoogleMap';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import GoogleSearchField from '../../components/forms/form-fields/GoogleSearchField';
import { Form, Formik } from 'formik';

const FindBrokerRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 'calc(100vh - 82px)',
}));

const MapContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
}));

const SidebarRoot = styled('div')(({ theme }) => ({
    width: '950px',
    [theme.breakpoints.down('lg')]: {
        width: '850px',
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const Sidebarheader = styled('div')(({ theme }) => ({
    backgroundColor: '#D7412E',
    color: '#FFF',
    padding: '30px',
}));

const SidebarInputContainer = styled('div')(({ theme }) => ({
    padding: '30px',
}));

const MobileInputContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '350px',
    padding: '15px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    top: 90,
    left: 10,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const FindBroker = () => {
    const [brokers, setBrokers] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const timeout = useRef();

    const getMarkers = async () => {
        var result = await AxiosRequest.get(`api/brokers`);

        setMarkers(result);
    };

    useEffect(() => {
        getMarkers();
    }, []);

    const returnMap = (tempMap) => {
        setMap(tempMap);
    };

    const itemChange = (value) => {
        if ('broker_Code' in value) {
            if (map) {
                map.setCenter({ lat: value['location_Lat'], lng: value['location_Long'] });
                map.setZoom(19);
            }

            return;
        }

        if (map) {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(
                {
                    address: value['description'],
                },
                function (results, status) {
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        var latitude = results[0].geometry.location.lat();
                        var longitude = results[0].geometry.location.lng();

                        map.setCenter({ lat: latitude, lng: longitude });
                        map.setZoom(19);
                    }
                }
            );
        }
    };

    const handleChange = async (text) => {
        setBrokers([]);
        clearTimeout(timeout.current);

        timeout.current = setTimeout(async () => {
            var result = await AxiosRequest.post(`api/brokers`, { filterQuery: text });

            if (result) {
                setBrokers(result);
                return;
            }

            brokers && setBrokers(result);
        }, 1000);
    };

    const handleLocation = async (locationObj) => {
        if (map) {
            map.setCenter(locationObj['geometry']['location']);
            map.setZoom(19);
        }
    };

    return (
        <Fade in={true}>
            <FindBrokerRoot>
                <MapContainer>
                    <MobileInputContainer>
                        <Formik
                            initialValues={{
                                googleSearch: '',
                            }}
                        >
                            {({ isSubmitting, setFieldValue, values }) => (
                                <Form>
                                    <GoogleSearchField
                                        name="googleSearch"
                                        label="Search Address or Broker"
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        handleChange={handleChange}
                                        itemChange={itemChange}
                                        brokers={brokers}
                                        setBrokers={() => setBrokers([])}
                                        fullWidth
                                        locationBtn
                                        handleLocation={handleLocation}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </MobileInputContainer>

                    <GoogleMap markers={markers} returnMap={returnMap} />
                </MapContainer>

                <SidebarRoot>
                    <Sidebarheader>
                        <Typography variant="body1" color={`#FFF`}>
                            Insurance Brokers play an essential role in the insurance industry.
                            Besides providing professional advice to ensure you get the best
                            coverage suited to you specific needs, they also assist in minimising
                            administration and can be of great help during the claim process.
                        </Typography>
                        <Typography variant="body1" color={`#FFF`} marginY={2}>
                            Experience the joy of having an Insurance Broker who cares. At CIA we
                            work with over 1,600 independent FSCA registered Brokers across South
                            Africa who can help you with all your insurance needs.
                        </Typography>
                        <Typography variant="body1" color={`#FFF`}>
                            Browse our database to find a Broker near you today!
                        </Typography>
                    </Sidebarheader>

                    <SidebarInputContainer>
                        <Formik
                            initialValues={{
                                googleSearch: '',
                            }}
                        >
                            {({ isSubmitting, setFieldValue, values }) => (
                                <Form>
                                    <GoogleSearchField
                                        name="googleSearch"
                                        label="Search Address or Broker"
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        handleChange={handleChange}
                                        itemChange={itemChange}
                                        brokers={brokers}
                                        setBrokers={() => setBrokers([])}
                                        fullWidth
                                        locationBtn
                                        handleLocation={handleLocation}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </SidebarInputContainer>
                </SidebarRoot>
            </FindBrokerRoot>
        </Fade>
    );
};

export default FindBroker;
