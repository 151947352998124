import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';

import CommunityImage from '../../assets/images/product-pages/community.jpg';
import WhatsCovered from './components/WhatsCovered';
import Downloads from './components/Downloads';

// Icons
import HousFireIcon from '../../assets/images/icons/housefire.svg';
import StormIcon from '../../assets/images/icons/storm.svg';
import AccidentIcon from '../../assets/images/icons/accident.svg';
import ElectricIcon from '../../assets/images/icons/electric.svg';
import StorageTankIcon from '../../assets/images/icons/storage-tank.svg';
import LandslideIcon from '../../assets/images/icons/landslide.svg';
import RegulationIcon from '../../assets/images/icons/regulation.svg';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import PatientIcon from '../../assets/images/icons/patient.svg';
import ElevatorIcon from '../../assets/images/icons/elevator.svg';
import ViolenceIcon from '../../assets/images/icons/violence.svg';
import WashingMachineIcon from '../../assets/images/icons/washing-machine.svg';
import GraffitiIcon from '../../assets/images/icons/graffiti.svg';
import HonestyIcon from '../../assets/images/icons/honesty.svg';

// Documents
import KeyInformation from '../../assets/documents/products-key-information/community/Community-Living-Key-Information-Document-April-2023.pdf';
import CommunityInsurance from '../../assets/documents/products-key-information/community/Community-Living-Insurance-Policy-April-2023.pdf';
import AverageWaiver from '../../assets/documents/products-key-information/community/Average-Waiver-Extension.pdf';
import EnhancedGeyser from '../../assets/documents/products-key-information/community/Enhanced-Geyser-Benefit.pdf';
import InsuranceProposal from '../../assets/documents/products-key-information/Insurance-Proposal-Form 2024.pdf';

const CommunityLivingInsuranceRoot = styled('div')(({ theme }) => ({}));

const content = {
    image: CommunityImage,
    title: 'Community Living Insurance',
    paragraph_one: (
        <Fragment>
            As one of South Africa's largest Community Schemes insurance providers, CIA understands
            the unique and growing challenges faced by today's Body Corporate's their trustees and
            managing agents.
        </Fragment>
    ),
    paragraph_two: (
        <Fragment>
            Our Community Living Insurance Policy has been designed to cover all types of
            residential, commercial and industrial community living schemes including Sectional
            Title Developments, Share Blocks, Home Owners Associations and Retirement Villages.
        </Fragment>
    ),
    btnText: 'Get a Quote',
    btnUrl: '/get-quote#community-insurance',
};

const icon_items = [
    {
        title: 'Fire',
        text: 'Cover for loss or damage to buildings caused by fire and includes fires due to arson.',
        icon: HousFireIcon,
    },
    {
        title: 'Natural Disasters',
        text: 'Such a hailstorms, floods, tornados, lightning, earthquakes and tsunamis.',
        icon: StormIcon,
    },
    {
        title: 'Accidental Damage',
        text: 'Unforeseen and unintentional damage such as spilling paint on a carpet or drilling through a pipe in a wall.',
        icon: AccidentIcon,
    },
    {
        title: 'Loss of Rent',
        text: 'Loss of rent, removal costs and alternative accommodation.',
        icon: WalletIcon,
    },
    {
        title: 'Geysers',
        text: 'Geyser and maintenance cover, including heatpumps and solar installations. Choose your preferred limit.',
        icon: StorageTankIcon,
    },
    {
        title: 'Subsidence and Landslide',
        text: 'Limited cover (excluding dolomite and limestone areas).',
        icon: LandslideIcon,
    },
    {
        title: 'Public Liability',
        text: 'Up to R100 million, including prior acts coverage.',
        icon: RegulationIcon,
    },
    {
        title: 'Power Surge',
        text: 'R50,000 included.',
        icon: ElectricIcon,
    },
    {
        title: 'Employers Liability',
        text: 'Up to R10 million.',
        icon: PatientIcon,
    },
    {
        title: 'Machinery Breakdown',
        text: 'R50,000 included.',
        icon: ElevatorIcon,
    },
    {
        title: 'Personal Accident',
        text: 'Cover for voluntary workers, assault and specified persons.',
        icon: ViolenceIcon,
    },
    {
        title: 'Contents belonging to the scheme',
        text: 'Such as laundry room equipment, gardening tools etc. Vehicles belonging to the scheme can also be added.',
        icon: WashingMachineIcon,
    },
    {
        title: 'Theft and Malicious Damage',
        text: 'Full cover for theft and malicious damage following forcible and violent entry into the building.',
        icon: GraffitiIcon,
    },
    {
        title: 'Trustees and directors indemnity',
        text: 'R5 million included.',
        icon: HonestyIcon,
    },
];

const document_items = [
    {
        title: 'Community Living Insurance Policy',
        text: 'Document size: 608KB',
        document: CommunityInsurance,
    },

    {
        title: 'Community Living Insurance Policy - Key Information Document',
        text: 'Document size: 34KB',
        document: KeyInformation,
    },
    {
        title: 'Average Waiver Extension',
        text: 'Document size: 132KB',
        document: AverageWaiver,
    },
    {
        title: 'Enhanced Geyser Benefit',
        text: 'Document size: 92KB',
        document: EnhancedGeyser,
    },
    {
        title: 'Insurance Proposal Form',
        text: 'Document size: 235KB',
        document: InsuranceProposal,
    },
];

const CommunityLivingInsurance = () => {
    return (
        <Fade in={true}>
            <CommunityLivingInsuranceRoot>
                <Introduction content={content} />

                <WhatsCovered icon_items={icon_items} />

                <Downloads document_items={document_items} />
            </CommunityLivingInsuranceRoot>
        </Fade>
    );
};

export default CommunityLivingInsurance;
