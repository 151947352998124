import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import formModel from '../../../../../../validation/get-quote/community/formModel';
import InputField from '../../../../../../components/forms/form-fields/InputField';

const ContactDetailsRoot = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const ContactDetails = (props) => {
    const location = useLocation();

    const { contactPerson, contactNumber, email, yourBroker, preferredBranch } = formModel;

    const { formikProps } = props;

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const key = urlParams.get('brokername');

        if (key) {
            formikProps.setFieldValue('yourBroker', key);
        }
    }, []);

    return (
        <ContactDetailsRoot>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant="h6" textAlign="center" marginTop={3}>
                        Please provide your contact details below and one of our friendly staff
                        members will contact you shortly.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={contactPerson.name} label={contactPerson.label} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={contactNumber.name} label={contactNumber.label} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={email.name} label={email.label} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField name={yourBroker.name} label={yourBroker.label} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="preferred-branch-label">
                            {preferredBranch['label']}
                        </InputLabel>
                        <Select
                            labelId="preferred-branch-label"
                            id="preferred-branch"
                            value={formikProps['values']['preferredBranch']}
                            label={preferredBranch['label']}
                            size="small"
                            variant="standard"
                            onChange={(event) =>
                                formikProps.setFieldValue('preferredBranch', event.target.value)
                            }
                        >
                            <MenuItem value="Johannesburg">Johannesburg</MenuItem>
                            <MenuItem value="Pretoria">Pretoria</MenuItem>
                            <MenuItem value="Durban">Durban</MenuItem>
                            <MenuItem value="Bloemfontein">Bloemfontein</MenuItem>
                            <MenuItem value="Gqeberha">Gqeberha</MenuItem>
                            <MenuItem value="Cape Town">Cape Town</MenuItem>
                            <MenuItem value="Garden Route">Garden Route</MenuItem>                     
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </ContactDetailsRoot>
    );
};

export default ContactDetails;
