import React from 'react';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const RecaptchaTextRoot = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    marginTop: '20px',
    textAlign: 'center',
}));

const RecaptchaText = () => {
    return (
        <RecaptchaTextRoot variant="body1">
            This site is protected by reCAPTCHA and the{' '}
            <Link href="https://policies.google.com/privacy" target="_blank">
                Google Privacy Policy
            </Link>{' '}
            and{' '}
            <Link href="https://policies.google.com/terms" target="_blank">
                Terms of Service
            </Link>{' '}
            apply.
        </RecaptchaTextRoot>
    );
};

export default RecaptchaText;
