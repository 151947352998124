import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import GoogleSearchField from '../../../../../../components/forms/form-fields/GoogleSearchField';
import formModel from '../../../../../../validation/get-quote/homeowners/formModel';
import RangeInput from '../../../../../../components/forms/form-fields/RangeInput';
import GoogleMap from '../../../../../../components/website-map/GoogleMap';

const PropertyDetailsRoot = styled('div')(({ theme }) => ({
    padding: '20px 0',
}));

const MapContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '200px',
    borderRadius: '5px',
    overflow: 'hidden',
}));

const QuestionContainer = styled('div')(({ theme }) => ({
    minHeight: '60px',
    [theme.breakpoints.down('sm')]: {
        marginBottom: '25px',
    },
}));

const QuestionBtn = styled(Button)(({ theme }) => ({
    borderRadius: '5px',
    fontWeight: 600,
    margin: '5px',
    maxHeight: '40px',
    color: '#474747',
    border: '1px solid #D7412E',
    '&.active': {
        backgroundColor: '#D7412E',
        color: '#FFFFFF',
    },
}));

const PropertyDetails = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [map, setMap] = useState(null);
    const mapMarker = useRef();
    const [mapOptions, setMapOptions] = useState({
        zoom: 6,
        mapTypeId: 'hybrid',
        center: {
            lat: -28.76496,
            lng: 24.74198,
        },
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
    });

    const {
        propertyLocation,
        sumInsredAmount,
        propertyQuestion1,
        propertyQuestion2,
        propertyQuestion3,
        propertyQuestion4,
        propertyQuestion5,
    } = formModel;

    const { formikProps, activeValidation } = props;
    const { values } = formikProps;

    const questionArray = [
        {
            item: propertyQuestion1,
        },
        {
            item: propertyQuestion2,
        },
        {
            item: propertyQuestion3,
        },
        {
            item: propertyQuestion4,
        },
        {
            item: propertyQuestion5,
        },
    ];

    const handleLocation = async (locationData) => {
        var address = locationData['address_components'];
        var zipcode = address[address.length - 1]['long_name'];

        formikProps.setFieldValue('postalCode', zipcode);
        formikProps.setFieldValue(
            'gpsCoordinates',
            `${locationData['geometry']['location'].lat()},${locationData['geometry'][
                'location'
            ].lng()}`
        );
    };

    const handleValueChange = async (value) => {
        formikProps.setFieldValue('sumInsredAmount', value);
    };

    const returnMap = (tempMap) => {
        setMap(tempMap);
    };

    useEffect(() => {
        console.log('Test 1');
        if (
            parseFloat(values['gpsCoordinates'].split(',')[0]) &&
            parseFloat(values['gpsCoordinates'].split(',')[0]) &&
            map
        ) {
            console.log('Test 2');
            var icon = {
                url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                scaledSize: new window.google.maps.Size(33, 33),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 30),
            };

            if (mapMarker.current) {
                mapMarker.current.setMap(null);
            }

            mapMarker.current = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(values['gpsCoordinates'].split(',')[0]),
                    lng: parseFloat(values['gpsCoordinates'].split(',')[1]),
                },
                map: map,
                icon: icon,
                draggable: false,
            });

            map.setCenter({
                lat: parseFloat(values['gpsCoordinates'].split(',')[0]),
                lng: parseFloat(values['gpsCoordinates'].split(',')[1]),
            });
            map.setZoom(19);
        }
    }, [values['gpsCoordinates'], map]);

    return (
        <PropertyDetailsRoot>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <GoogleSearchField
                        name={propertyLocation['name']}
                        label={propertyLocation['label']}
                        fullWidth
                        setFieldValue={formikProps.setFieldValue}
                        values={formikProps.values}
                        autoFocus
                        locationBtn
                        style={{ margin: '20px 0' }}
                        handleLocation={handleLocation}
                    />

                    <MapContainer>
                        <GoogleMap options={mapOptions} returnMap={returnMap} />
                    </MapContainer>

                    <RangeInput
                        name={sumInsredAmount['name']}
                        label={sumInsredAmount['label']}
                        value={props['formikProps']['values']['sumInsredAmount']}
                        setFieldValue={formikProps.setFieldValue}
                        handleValueChange={handleValueChange}
                        fullWidth
                    />

                    {activeValidation &&
                        (!formikProps['values']['propertyQuestion1'] ||
                            !formikProps['values']['propertyQuestion2'] ||
                            !formikProps['values']['propertyQuestion3'] ||
                            !formikProps['values']['propertyQuestion4'] ||
                            !formikProps['values']['propertyQuestion5']) && (
                            <Typography color="error" marginTop={3}>
                                *Please select all options below
                            </Typography>
                        )}

                    <Typography variant="h5" marginTop={3} marginBottom={2}>
                        Please confirm the following with regards to the property:
                    </Typography>

                    {questionArray.map((question, index) => (
                        <QuestionContainer key={index}>
                            <Grid container>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="body1" marginBottom={2}>
                                        {question['item']['label']}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    display="flex"
                                    justifyContent={isMobile ? 'center' : 'flex-end'}
                                    alignItems={'center'}
                                >
                                    <QuestionBtn
                                        className={
                                            formikProps['values'][question['item']['name']] ===
                                                'Yes' && 'active'
                                        }
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            formikProps.setFieldValue(
                                                question['item']['name'],
                                                'Yes'
                                            );
                                        }}
                                    >
                                        Yes
                                    </QuestionBtn>
                                    <QuestionBtn
                                        className={
                                            formikProps['values'][question['item']['name']] ===
                                                'No' && 'active'
                                        }
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                            formikProps.setFieldValue(
                                                question['item']['name'],
                                                'No'
                                            )
                                        }
                                    >
                                        No
                                    </QuestionBtn>
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: '10px 0' }} hidden={!isMobile} />
                        </QuestionContainer>
                    ))}
                </Grid>
            </Grid>
        </PropertyDetailsRoot>
    );
};

export default PropertyDetails;
