import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import RequestInsuranceCertificateForm from '../../../components/forms/RequestInsuranceCertificateForm';

const RequestInsuranceCertificateRoot = styled('div')(({ theme }) => ({}));

const RequestInsuranceCertificate = () => {
    return (
        <RequestInsuranceCertificateRoot>
            <Introduction />

            <RequestInsuranceCertificateForm />
        </RequestInsuranceCertificateRoot>
    );
};

export default RequestInsuranceCertificate;
