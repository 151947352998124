import React from 'react';
import { Grid } from '@mui/material';
import initialValues from '../../validation/newsletter/initialValues';
import validationSchema from '../../validation/newsletter/validationSchema';
import formModel from '../../validation/newsletter/formModel';
import InputField from './form-fields/InputField';
import WebsiteForms from './WebsiteForms';

const NewsletterForm = () => {
    const { name, email } = formModel;

    return (
        <WebsiteForms
            formUrl="api/forms/newsletter"
            initialValues={initialValues}
            validationSchema={validationSchema}
            btnText="Subscribe"
        >
            <Grid container spacing={5}>
                <Grid
                    item
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                    }}
                    md={2}
                />
                <Grid item xs={12} md={4}>
                    <InputField name={name.name} label={name.label} fullWidth />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputField name={email.name} label={email.label} fullWidth />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                    }}
                    md={2}
                />
            </Grid>
        </WebsiteForms>
    );
};

export default NewsletterForm;
