import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt, faEnvelopeSquare, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';

import GeyserClaimForm from '../../../assets/documents/CIA-Fast-Track-Geyser-Claim-Form.pdf';
import GeneralClaimForm from '../../../assets/documents/CIA-Claim-Form.pdf';
import FidelityClaimForm from '../../../assets/documents/CIA-Fidelity-Computer-Crime-Claim-Form.pdf';

const CardsRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const CardContainer = styled(Grid)(({ theme }) => ({
    border: '1px solid #999',
    borderRadius: '5px',
    padding: '20px 20px 40px',
    minHeight: '360px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
        height: '410px',
    },
    [theme.breakpoints.down('md')]: {
        margin: '0 30px',
    },
}));

const CardList = styled('ul')(({ theme }) => ({
    padding: '0 0 0 30px',
}));

const CardListItem = styled('li')(({ theme }) => ({
    margin: '4px 0',
}));

const CardButtons = styled('div')(({ theme }) => ({
    display: 'flex',
    marginTop: 'auto',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
    },
    // margin: '0 auto',
}));

const CardButton = styled(Button)(({ theme }) => ({
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
        margin: '10px auto',
    },
}));

const CardIconContainer = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '3px 0',
}));

const CardIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    fontSize: '30px',
    marginRight: '10px',
}));

const Cards = () => {
    const history = useHistory();

    return (
        <CardsRoot>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <CardContainer>
                            <Typography variant="h3">Claim Online</Typography>
                            <Typography marginY={2}>
                                Submit a claim now with our quick and easy online process.
                            </Typography>

                            <Typography variant="h5">Please have the following ready:</Typography>

                            <CardList>
                                <CardListItem>Your policy number</CardListItem>
                                <CardListItem>Details of the incident</CardListItem>
                                <CardListItem>
                                    Supporting documents e.g. quotes, invoices
                                </CardListItem>
                            </CardList>

                            <CardButtons>
                                <CardButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        window.open(`https://online.cia.co.za/GrailWeb`, `_blank`)
                                    }
                                >
                                    Start Online Claim
                                </CardButton>

                                <CardButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => history.push('/track-a-claim')}
                                >
                                    Track Your Claim
                                </CardButton>
                            </CardButtons>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardContainer>
                            <Typography variant="h3">Geyser Emergencies</Typography>
                            <Typography marginY={2}>
                                Give us a call 24/7 and one of our advisors will ensure that you
                                receive prompt assistance.
                            </Typography>

                            <CardIconContainer variant="h5">
                                <CardIcon icon={faPhoneSquareAlt} color="#D7412E" />
                                <Link href="tel:+27861242911">0861 242 911</Link>
                            </CardIconContainer>

                            <CardIconContainer variant="h5">
                                <CardIcon icon={faWhatsappSquare} color="#25D366" />
                                <Link href="tel:+27665867763">066 586 7763</Link>
                            </CardIconContainer>

                            <CardIconContainer variant="h5">
                                <CardIcon icon={faEnvelopeSquare} color="#D7412E" />
                                <Link href="mailto:assist@cia.co.za">assist@cia.co.za</Link>
                            </CardIconContainer>

                            <CardButtons>
                                <CardButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => history.push('/request-a-call-back')}
                                >
                                    Request a Call Back
                                </CardButton>
                            </CardButtons>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CardContainer>
                            <Typography variant="h3">Claim Form Downloads</Typography>
                            <Typography marginY={2}>
                                Download a claim form below and submit it in your own time with all
                                your supporting documentation.
                            </Typography>

                            <CardIconContainer variant="h5">
                                <CardIcon icon={faFilePdf} color="#D7412E" />
                                <Link href={GeyserClaimForm} target="_blank">
                                    Fast Track Geyser Claim Form
                                </Link>
                            </CardIconContainer>

                            <CardIconContainer variant="h5">
                                <CardIcon icon={faFilePdf} color="#D7412E" />
                                <Link href={GeneralClaimForm} target="_blank">
                                    General Claim Form
                                </Link>
                            </CardIconContainer>
                            <CardIconContainer variant="h5">
                                <CardIcon icon={faFilePdf} color="#D7412E" />
                                <Link href={FidelityClaimForm} target="_blank">
                                    Fidelity Claim Form
                                </Link>
                            </CardIconContainer>
                        </CardContainer>
                    </Grid>
                </Grid>
            </Container>
        </CardsRoot>
    );
};

export default Cards;
