import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Fade, Typography } from '@mui/material';
import GoogleMap from '../../components/website-map/GoogleMap';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import GoogleSearchField from '../../components/forms/form-fields/GoogleSearchField';
import { Form, Formik } from 'formik';
import Branches from './components/Branches';

const FindBrokerRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 'calc(100vh - 82px)',
}));

const MapContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
}));

const SidebarRoot = styled('div')(({ theme }) => ({
    width: '950px',
    [theme.breakpoints.down('lg')]: {
        width: '850px',
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const Sidebarheader = styled('div')(({ theme }) => ({
    backgroundColor: '#D7412E',
    color: '#FFF',
    padding: '30px',
}));

const branch_array = [
    {
        title: 'Bloemfontein Branch',
        firstContact: 'Broker Consultant: Riana Ferreira',
        email: 'riana@cia.co.za',
        telephone: '0861 242 222',
        address: 'Office No.3 situated at 34-38 Graaf Reinet Street, Dan Pienaar, Bloemfontein',
        lat: -29.108298,
        lng: 26.206986,
    },
    {
        title: 'Pretoria Branch',
        firstContact: 'Branch Manager: Gerhard van Schoor',
        email: 'gerhard@cia.co.za',
        telephone: '0861 242 999',
        address: 'Atterbury Estate, 19 Frikkie De Beer Street, Menlyn, Pretoria, South Africa',
        lat: -25.78351889999999,
        lng: 28.27839670000003,
    },
    {
        title: 'Johannesburg Head Office',
        firstContact: 'Managing Director: Douglas Haig',
        email: 'douglas@cia.co.za',
        telephone: '0861 242 999',
        address: '13e Riley Rd, Bedfordview, Germiston, 2008, South Africa ',
        postalAddress: 'PO Box 615 Bedfordview 2008',
        lat: -26.1663528,
        lng: 28.15019910000001,
    },
    {
        title: 'Durban Branch',
        firstContact: 'Branch Manager: Conrad Baasden',
        email: 'conrad@cia.co.za',
        telephone: '0861 242 555',
        address: `41 Richefond Circle Strauss Daly Building Suite 304, 3rd Floor Umhlanga Ridge Umhlanga Durban`,
        lat: -29.733750180245757,
        lng: 31.069378418022183,
    },
    {
        title: 'Gqeberha Branch',
        firstContact: 'Broker Consultant: Lester Pienaar',
        email: 'lester@cia.co.za',
        telephone: '0861 242 333',
        address: '33 Pickering Street, Newton Park, Gqeberha, South Africa',
        lat: -33.9465,
        lng: 25.56768999999997,
    },
    {
        title: 'Cape Town Branch',
        firstContact: 'Branch Manager: Jimmie Louw',
        email: 'jimmie@cia.co.za',
        telephone: '0861 242 111',
        address:
            'Level 6, East Wing Cento Building, Bella Rosa Village, 21C Durbanville Avenue, Rosendal, Bellville, 7441',
        lat: -33.863208,
        lng: 18.641736,
    },
    {
        title: 'Garden Route',
        firstContact: 'Junior Broker Consultant: Melanie Wolmarans ',
        email: 'melaniew@cia.co.za',
        telephone: '0418164801',
        address:
            '21 Ananda Singel, Vyf Brakke Fonteinen, Mossel Bay 6506',
        lat: -34.14950,
        lng: 22.09669,
    },
];

const ContactUs = () => {
    return (
        <Fade in={true}>
            <FindBrokerRoot>
                <MapContainer>
                    <GoogleMap branches={branch_array} />
                </MapContainer>

                <SidebarRoot>
                    <Sidebarheader>
                        <Typography variant="body1" color={`#FFF`}>
                            CIA Building Insurance has a national footprint with 7 branches
                            countrywide, each branch equipped with highly skilled and approachable
                            decision-makers. As a result, we are renowned for consistent service
                            delivery and the accessibility and personal touch of all our personnel.
                        </Typography>
                        <Typography variant="body1" color={`#FFF`} marginY={2}>
                            Whatever your requirement, we look forward to delivering passionate,
                            personable and productive service.
                        </Typography>
                    </Sidebarheader>

                    <Branches />
                </SidebarRoot>
            </FindBrokerRoot>
        </Fade>
    );
};

export default ContactUs;
