import formModel from './formModel';

const {
    propertyLocation,
    email,
    name,
    contactNumber,
    alternativeContactNumber,
    policyNumber,
    nameOfInsured,
    unitNumber,
    comments,
} = formModel;

const initialValues = {
    [propertyLocation.name]: '',
    [email.name]: '',
    [name.name]: '',
    [contactNumber.name]: '',
    [alternativeContactNumber.name]: '',
    [policyNumber.name]: '',
    [nameOfInsured.name]: '',
    [unitNumber.name]: '',
    [comments.name]: '',
};

export default initialValues;
