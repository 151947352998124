import formModel from './formModel';

const { policyNumber, claimNumber } = formModel;

const initialValues = {
    [policyNumber.name]: '',
    [claimNumber.name]: '',
};

export default initialValues;
