import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import initialValues from '../../validation/register-online-broker-training/initialValues';
import validationSchema from '../../validation/register-online-broker-training/validationSchema';
import formModel from '../../validation/register-online-broker-training/formModel';
import InputField from './form-fields/InputField';
import RadioField from './form-fields/RadioField';
import WebsiteForms from './WebsiteForms';

const RegisterOnlineBrokerFormRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const RegisterOnlineBrokerForm = () => {
    const {
        firstName,
        lastName,
        idNumber,
        email,
        contactNumber,
        fspNumber,
        companyName,
        registeredAcDevelop,
    } = formModel;

    return (
        <RegisterOnlineBrokerFormRoot>
            <WebsiteForms
                formUrl="api/forms/registeronlinebrokertraining"
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                <Grid container spacing={7}>
                    <Grid item xs={12} sm={6}>
                        <InputField name={firstName.name} label={firstName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={lastName.name} label={lastName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={idNumber.name} label={idNumber.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={email.name} label={email.label} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name={contactNumber.name}
                            label={contactNumber.label}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name={fspNumber.name} label={fspNumber.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField name={companyName.name} label={companyName.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioField
                            name={registeredAcDevelop.name}
                            label={registeredAcDevelop.label}
                        />
                    </Grid>
                </Grid>
            </WebsiteForms>
        </RegisterOnlineBrokerFormRoot>
    );
};

export default RegisterOnlineBrokerForm;
