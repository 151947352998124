import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const BranchesRoot = styled('div')(({ theme }) => ({}));

const TextTitle = styled(Typography)(({ theme }) => ({
    color: '#666666',
    marginRight: '5px',
}));

const TextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    // overflow: 'hidden',
    flexWrap: 'wrap',
    margin: '10px 0',
}));

const TextLink = styled(Link)(({ theme }) => ({
    fontSize: '16px',
    cursor: 'pointer',
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // borderBottom: `1px solid ${theme.palette.divider}`,
    '& .Mui-expanded .MuiTypography-root': {
        color: '#D7412E',
        fontWeight: 600,
    },
    '& .Mui-expanded': {
        color: '#D7412E',
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexDirection: 'row-reverse',
    '&.Mui-expanded': {
        backgroundColor: 'rgba(0,0,0,0.03)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Branches = () => {
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <BranchesRoot>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Johannesburg Head Office</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">13E Riley Road Bedfordview</Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242777">0861 242 777</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0116012300">011 601 2300</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Email:</TextTitle>
                        <TextLink href="mailto:mail@cia.co.za">mail@cia.co.za</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Managing Director:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Douglas Haig
                        </Typography>
                        <TextLink href="mailto:douglas@cia.co.za">(douglas@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Head of Business Development:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Sharne van Schoor
                        </Typography>
                        <TextLink href="mailto:sharne@cia.co.za">(sharne@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">General Manager:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Estelle Henry
                        </Typography>
                        <TextLink href="mailto:estelle@cia.co.za">(estelle@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Head of Claims:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Marc Odendaal
                        </Typography>
                        <TextLink href="mailto:marc@cia.co.za">(marc@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Head of Technical Underwriting:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Andrew Miles
                        </Typography>
                        <TextLink href="mailto:andrew@cia.co.za">(andrew@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">National Underwriting Manager:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Marna Correia
                        </Typography>
                        <TextLink href="mailto:marna@cia.co.za">(marna@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Michelle Bekker
                        </Typography>
                        <TextLink href="mailto:michelle@cia.co.za">(michelle@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Wendel Alexander
                        </Typography>
                        <TextLink href="mailto:wendel@cia.co.za">(wendel@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Pretoria</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                            Atterbury Estate 19 Frikkie de Beer Street
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242999">0861 242 999</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Branch Manager:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Gerhard van Schoor
                        </Typography>
                        <TextLink href="mailto:gerhard@cia.co.za">(gerhard@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Marizka Ras
                        </Typography>
                        <TextLink href="mailto:marizka@cia.co.za">(marizka@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Junior Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Sophi Conradie
                        </Typography>
                        <TextLink href="mailto:sophi@cia.co.za">(sophi@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Bloemfontein</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                            Office No.3 situated at 34-38 Graaf Reinet Street, Dan Pienaar,
                            Bloemfontein
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242222">0861 242 222</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Riana Ferreira
                        </Typography>
                        <TextLink href="mailto:riana@cia.co.za">(riana@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography>Durban</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                            41 Richefond Circle Strauss Daly Building Suite 304, 3rd Floor Umhlanga
                            Ridge Umhlanga Durban
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242555">0861 242 555</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Branch Manager:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Conrad Baasden
                        </Typography>
                        <TextLink href="mailto:conrad@cia.co.za">(conrad@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Carey Savopoulos
                        </Typography>
                        <TextLink href="mailto:carey@cia.co.za">(carey@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography>Gqeberha</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                            33 Pickering Street Newton Park Gqeberha
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242333">0861 242 333</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Lester Pienaar
                        </Typography>
                        <TextLink href="mailto:lester@cia.co.za">(lester@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography>Cape Town</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                            Level 6, East Wing Cento Building, Bella Rosa Village, 21C Durbanville
                            Avenue, Rosendal, Bellville, 7441
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0861242111">0861 242 111</TextLink>
                    </TextContainer>               
                    <br />

                    <TextContainer>
                        <TextTitle variant="h5">Branch Manager:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Jimmie Louw
                        </Typography>
                        <TextLink href="mailto:jimmie@cia.co.za">(jimmie@cia.co.za)</TextLink>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                            Johan Olwage
                        </Typography>
                        <TextLink href="mailto:johan@cia.co.za">(johan@cia.co.za)</TextLink>
                    </TextContainer>
                </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography>Garden Route</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextContainer>
                        <TextTitle variant="h5">Physical Address:</TextTitle>
                        <Typography variant="body1">
                               21 Ananda Singel, Vyf Brakke Fonteinen, Mossel Bay 6506
                        </Typography>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Tel:</TextTitle>
                        <TextLink href="tel:0418164801">0418164801</TextLink>
                    </TextContainer>

                    <br />

                    <TextContainer>
                    </TextContainer>
                    <TextContainer>
                        <TextTitle variant="h5">Junior Broker Consultant:</TextTitle>
                        <Typography variant="body1" marginRight={1}>
                               Melanie Wolmarans
                        </Typography>
                        <TextLink href="mailto:melaniew@cia.co.za">(melaniew@cia.co.za)</TextLink>
                    </TextContainer>
                    </AccordionDetails>
            </Accordion>
        </BranchesRoot>
    );
};

export default Branches;
