import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import initialValues from '../../validation/careers/initialValues';
import validationSchema from '../../validation/careers/validationSchema';
import formModel from '../../validation/careers/formModel';
import InputField from './form-fields/InputField';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import UploadInput from './form-fields/UploadInput';
import { APP_ALLOWED_FILE_TYPES } from '../../constants/AppConstants';
import RecaptchaText from '../RecaptchaText';
import RecaptchaField from '../recaptcha/RecaptchaField';
import FormMessage from './FormMessage';

const ExistingPolicyScheduleFormRoot = styled('div')(({ theme }) => ({
    margin: '24px 0 0',
}));

const ExistingPolicyScheduleFormContainer = styled('div')(({ theme }) => ({
    padding: '0 54px',
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
    marginTop: '10px',
    color: theme.palette.error.main,
}));

const ExistingPolicyScheduleForm = () => {
    const { firstName, lastName, email } = formModel;
    const [activeValidation, setActiveValidation] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [attachmentError, setAttachmentError] = useState(null);
    const [formResponse, setFormResponse] = useState(null);
    const recaptchaRef = useRef();

    const submit = async (values, actions) => {
        const timeout = setTimeout(() => {
            setFormResponse({
                isSuccessful: false,
                message: 'Your submission could not be sent. Please try again later.',
            });
            setActiveValidation(false);
            actions.setSubmitting(false);
            return;
        }, 60000);

        if (!attachment) {
            setAttachmentError('File is required');
            return;
        }

        const token = await recaptchaRef.current.executeAsync();

        const getFormData = (object) =>
            Object.keys(object).reduce((formData, key) => {
                formData.append(key, object[key]);
                return formData;
            }, new FormData());

        var uploadData = getFormData({ ...values, file: attachment, token });

        var result = await AxiosRequest.post(`api/forms/existingpolicyschedule`, uploadData);

        clearTimeout(timeout);

        setFormResponse(result);
        setActiveValidation(false);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    const handleChange = (event) => {
        setAttachment(null);

        if (
            !APP_ALLOWED_FILE_TYPES.includes(event.target.files[0].type) &&
            !APP_ALLOWED_FILE_TYPES.includes(event.target.files[0].name.split('.').pop())
        ) {
            if (event.target.files[0].type) {
                setAttachmentError(`File type ${event.target.files[0].type} not allowed.`);
                return;
            }

            setAttachmentError(
                `File type .${event.target.files[0].name.split('.').pop()} not allowed.`
            );
            return;
        }

        if (event.target.files[0].size >= 5242880) {
            setAttachmentError('File is too big.');
            return;
        }

        const files = Array.from(event.target.files);
        const [file] = files;

        setAttachmentError(null);
        setAttachment(file);
    };

    const validateFile = () => {
        if (!attachment) {
            setAttachmentError('File is required');
            return;
        }
    };

    return (
        <ExistingPolicyScheduleFormRoot>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={activeValidation}
                validateOnBlur={activeValidation}
                onSubmit={(values, actions) => {
                    submit(values, actions);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <RecaptchaField recaptchaRef={recaptchaRef} />

                        <ExistingPolicyScheduleFormContainer>
                            <FormMessage response={formResponse} />

                            <Grid container spacing={5}>
                                <Grid item xs={12} md={4}>
                                    <InputField
                                        name={firstName.name}
                                        label={firstName.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputField
                                        name={lastName.name}
                                        label={lastName.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputField name={email.name} label={email.label} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <b>Upload Schedule (Word or PDF documents only)</b>
                                    </Typography>

                                    <UploadInput name="file" label="File" onChange={handleChange} />
                                    <Typography variant="h6" marginTop={'10px'}>
                                        {attachment && 'name' in attachment && attachment.name}
                                    </Typography>

                                    <ErrorMessage variant="h6">
                                        {attachmentError && attachmentError}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: '0 auto' }}
                                        onClick={() => {
                                            recaptchaRef.current.reset();
                                            validateFile();
                                            setActiveValidation(true);
                                        }}
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                color="secondary"
                                                sx={{
                                                    color: '#FFFFFF',
                                                }}
                                                size={20}
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>

                            <RecaptchaText />
                        </ExistingPolicyScheduleFormContainer>
                    </Form>
                )}
            </Formik>
        </ExistingPolicyScheduleFormRoot>
    );
};

export default ExistingPolicyScheduleForm;
