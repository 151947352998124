import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Link, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import initialValues from '../../validation/request-callback/initialValues';
import validationSchema from '../../validation/request-callback/validationSchema';
import formModel from '../../validation/request-callback/formModel';
import AxiosRequest from '../../utils/axios/AxiosRequest';
import RecaptchaField from '../recaptcha/RecaptchaField';
import FormMessage from './FormMessage';
import GoogleSearchField from './form-fields/GoogleSearchField';
import InputField from './form-fields/InputField';

const RequestCallbackFormRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const RequestCallbackFormContainer = styled(Container)(({ theme }) => ({
    textAlign: 'center',
}));

const RecaptchaText = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    marginTop: '20px',
}));

const RequestCallbackForm = () => {
    const {
        propertyLocation,
        email,
        name,
        contactNumber,
        alternativeContactNumber,
        policyNumber,
        nameOfInsured,
        unitNumber,
        comments,
    } = formModel;

    const [activeValidation, setActiveValidation] = useState(false);
    const [formResponse, setFormResponse] = useState(null);
    const recaptchaRef = useRef();

    const submit = async (values, actions) => {
        const token = await recaptchaRef.current.executeAsync();
        values['token'] = token;

        var result = await AxiosRequest.post(`api/forms/requestcallback`, values);

        setFormResponse(result);
        setActiveValidation(false);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    return (
        <RequestCallbackFormRoot>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={activeValidation}
                validateOnBlur={activeValidation}
                onSubmit={submit}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <RecaptchaField recaptchaRef={recaptchaRef} />

                        <RequestCallbackFormContainer maxWidth="lg">
                            <FormMessage response={formResponse} />

                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <GoogleSearchField
                                        name={propertyLocation.name}
                                        label={propertyLocation['label']}
                                        fullWidth
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        autoFocus
                                        locationBtn
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField name={email.name} label={email.label} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField name={name.name} label={name.label} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField
                                        name={contactNumber.name}
                                        label={contactNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField
                                        name={alternativeContactNumber.name}
                                        label={alternativeContactNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField
                                        name={policyNumber.name}
                                        label={policyNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField
                                        name={nameOfInsured.name}
                                        label={nameOfInsured.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputField
                                        name={unitNumber.name}
                                        label={unitNumber.label}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField
                                        name={comments.name}
                                        label={comments.label}
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={6}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: '0 auto' }}
                                        onClick={() => {
                                            recaptchaRef.current.reset();
                                            setActiveValidation(true);
                                        }}
                                    >
                                        {isSubmitting ? (
                                            <CircularProgress
                                                color="secondary"
                                                sx={{
                                                    color: '#FFFFFF',
                                                }}
                                                size={20}
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>

                            <RecaptchaText variant="body1">
                                This site is protected by reCAPTCHA and the{' '}
                                <Link href="https://policies.google.com/privacy" target="_blank">
                                    Google Privacy Policy
                                </Link>{' '}
                                and{' '}
                                <Link href="https://policies.google.com/terms" target="_blank">
                                    Terms of Service
                                </Link>{' '}
                                apply.
                            </RecaptchaText>
                        </RequestCallbackFormContainer>
                    </Form>
                )}
            </Formik>
        </RequestCallbackFormRoot>
    );
};

export default RequestCallbackForm;
