import formModel from './formModel';

const { name, email, company } = formModel;

const initialValues = {
    [name.name]: '',
    [email.name]: '',
    [company.name]: '',
};

export default initialValues;
