import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Container, Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import CiaLogo from '../assets/images/logos/cia_logo.png';

const NotFoundRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '96px',
}));

const NotFoundImage = styled('img')(({ theme }) => ({
    width: '415px',
}));

const NotFound = () => {
    const history = useHistory();

    return (
        <NotFoundRoot>
            <NotFoundImage src={CiaLogo} />
            <Typography variant="h1" style={{ margin: '48px 0' }}>
                Page not found.
            </Typography>
            <Typography variant="body1">
                Oops, the page you requested could not be found.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
                onClick={() => history.push('/')}
            >
                Back to Home
            </Button>
        </NotFoundRoot>
    );
};

export default NotFound;
