import * as Yup from 'yup';
import formModel from './formModel';

const {
    firstName,
    lastName,
    idNumber,
    email,
    contactNumber,
    fspNumber,
    companyName,
    registeredAcDevelop,
} = formModel;

const validationSchema = Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [idNumber.name]: Yup.number()
        .typeError('This field can only contain numbers.')
        .required(`${idNumber.requiredErrorMsg}`),
    [email.name]: Yup.string()
        .email('Incorrect email format')
        .required(`${email.requiredErrorMsg}`),
    [contactNumber.name]: Yup.number()
        .typeError('This field can only contain numbers.')
        .required(`${contactNumber.requiredErrorMsg}`),
    [fspNumber.name]: Yup.number()
        .typeError('This field can only contain numbers.')
        .required(`${fspNumber.requiredErrorMsg}`),
    [companyName.name]: Yup.string().required(`${companyName.requiredErrorMsg}`),
    [registeredAcDevelop.name]: Yup.string().required(`${registeredAcDevelop.requiredErrorMsg}`),
});

export default validationSchema;
