import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import NewsletterForm from '../../../components/forms/NewsletterForm';

const NewsletterRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
}));

const TitleContainer = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

const Newsletter = () => {
    return (
        <NewsletterRoot>
            <TitleContainer>
                <Typography variant="h1" style={{ margin: '0 auto' }}>
                    Our Newsletter
                </Typography>
            </TitleContainer>

            <NewsletterForm />
        </NewsletterRoot>
    );
};

export default Newsletter;
