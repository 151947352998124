import React from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import ExistingPolicyScheduleForm from '../../../../components/forms/ExistingPolicyScheduleForm';

const IntroductionRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const UploadForm = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <ExistingPolicyScheduleForm />
            </Container>
        </IntroductionRoot>
    );
};

export default UploadForm;
