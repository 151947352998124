import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    ClickAwayListener,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
    CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBuilding, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';

const GoogleSearchFieldRoot = styled('div')(({ theme }) => ({
    width: '100%',
    overflow: 'hidden',
}));

const PredictionsRoot = styled('div')(({ theme }) => ({
    position: 'absolute',
    textAlign: 'start',
    backgroundColor: '#FFF',
    boxShadow: '0 2px 6px rgb(0 0 0 / 30%)',
    borderRadius: '0 0 10px 10px',
    zIndex: 9,
    width: 'calc(100vw - 50px)',
    maxHeight: '300px',
    overflow: 'hidden overlay',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
}));

const PredictionItem = styled(Typography)(({ theme }) => ({
    padding: '8px 6px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
        color: '#D7412E',
        backgroundColor: '#F0F0F0',
    },
}));

const LocationButton = styled(IconButton)(({ theme }) => ({
    borderRadius: '5px',
    marginBottom: '15px',
    color: '#D7412E',
    fontSize: '22px',
}));

const GoogleSearchField = (props) => {
    const {
        errorText,
        setFieldValue,
        values,
        itemChange,
        handleChange,
        brokers,
        setBrokers,
        locationBtn,
        handleLocation,
        ...rest
    } = props;
    const [field, meta] = useField(props);

    const [predictions, setPredictions] = useState([]);
    const [value, setValue] = useState('');
    const [loadingLocation, setLoadingLocation] = useState(false);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    const handleSearch = async (text) => {
        setFieldValue(rest['name'], text);
        handleChange && handleChange(text);

        if (!text) {
            setPredictions([]);
            setBrokers && setBrokers();
            return false;
        }

        if (window.google) {
            const autocomplete = new window.google.maps.places.AutocompleteService();

            autocomplete.getPlacePredictions(
                {
                    input: text,
                    types: ['establishment', 'geocode'],
                    componentRestrictions: { country: 'ZA' },
                },
                (predictions) => {
                    setPredictions(predictions);
                }
            );
        }
    };

    const itemClick = (item) => {
        if ('broker_Code' in item) {
            setFieldValue(rest['name'], item['display_Name']);
            handleChange && itemChange(item);
            setPredictions([]);
            setBrokers && setBrokers();
            return;
        }

        if (navigator.geolocation) {
            var geocoder = new window.google.maps.Geocoder();

            geocoder
                .geocode({ address: item['description'] })
                .then((result) => {
                    const { results } = result;
                    handleLocation && handleLocation(results[0]);
                    setFieldValue(rest['name'], results[0]['formatted_address']);

                    handleChange && itemChange(item);
                    setPredictions([]);
                    setBrokers && setBrokers();
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            setLoadingLocation(true);
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    var geocoder = new window.google.maps.Geocoder();

                    var latlng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    geocoder.geocode({ location: latlng }, function (results, status) {
                        if (status === 'OK') {
                            if (results[0]) {
                                setFieldValue(rest['name'], results[0].formatted_address);
                                handleLocation && handleLocation(results[0]);
                                setLoadingLocation(false);
                            } else {
                                console.log('No results found');
                                setLoadingLocation(false);
                            }
                        } else {
                            console.log('Geocoder failed due to: ' + status);
                            setLoadingLocation(false);
                        }
                    });
                },
                function () {
                    // handleLocationError(true, infoWindow, map.getCenter());
                    setLoadingLocation(false);
                }
            );
        } else {
            // Browser doesn't support Geolocation
            // handleLocationError(false, infoWindow, map.getCenter());
            setLoadingLocation(false);
        }
    };

    return (
        <GoogleSearchFieldRoot>
            <TextField
                type="text"
                variant="standard"
                size="small"
                error={meta.touched && meta.error && true}
                helperText={_renderHelperText()}
                {...field}
                {...rest}
                value={values && values[rest['name']]}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={
                    locationBtn && {
                        endAdornment: (
                            <InputAdornment position="start">
                                <LocationButton title="Use my location" onClick={getLocation}>
                                    {loadingLocation ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                    )}
                                </LocationButton>
                            </InputAdornment>
                        ),
                    }
                }
            />
            {(predictions || brokers) && (
                <ClickAwayListener
                    onClickAway={() => {
                        setPredictions([]);
                        setBrokers && setBrokers();
                    }}
                >
                    <PredictionsRoot>
                        {brokers &&
                            brokers.map((item, index) => (
                                <PredictionItem key={index} onClick={() => itemClick(item)}>
                                    <FontAwesomeIcon icon={faBuilding} /> {item['display_Name']}
                                </PredictionItem>
                            ))}
                        {predictions &&
                            predictions.map((item, index) => (
                                <PredictionItem key={index} onClick={() => itemClick(item)}>
                                    <FontAwesomeIcon icon={faLocationDot} /> {item['description']}
                                </PredictionItem>
                            ))}
                    </PredictionsRoot>
                </ClickAwayListener>
            )}
        </GoogleSearchFieldRoot>
    );
};

export default GoogleSearchField;
