import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import CiaPrivacyPolicy from '../assets/documents/legal/CIA-Privacy-Policy.pdf';
import CIAObjectionRequestPOPIForm from '../assets/documents/legal/CIA-Objection-Request-POPI-Form.pdf';
import PromotionofAccesstoInformation from '../assets/documents/legal/Promotion-of-Access-to-Information.pdf';
import CodeofConduct2022 from '../assets/documents/legal/Code-of-Conduct-2022.pdf';
import DisclosureNotice from '../assets/documents/legal/Disclosure-Notice.pdf';
import TreatingCustomersFairlyNov2020 from '../assets/documents/legal/Treating-Customers-Fairly-Nov-2020.pdf';
import ConflictofInterestManagementPolicyNov2020 from '../assets/documents/legal/Conflict-of-Interest-Management-Policy-Nov-2020.pdf';
import ComplaintsPolicy from '../assets/documents/legal/Complaints-Policy.pdf';

const LegalRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const LegalLink = styled(Link)(({ theme }) => ({
    marginBottom: '20px',
}));

const LinkContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const Legal = () => {
    return (
        <LegalRoot>
            <Container maxWidth="lg">
                <Typography variant="h3" marginBottom={3}>
                    Legal Page
                </Typography>

                <LinkContainer>
                    <LegalLink href={CiaPrivacyPolicy} target="_blank">
                        CIA Privacy Policy
                    </LegalLink>
                    <LegalLink href={CIAObjectionRequestPOPIForm} target="_blank">
                        CIA Objection Request POPI Form
                    </LegalLink>
                    <LegalLink href={PromotionofAccesstoInformation} target="_blank">
                        Promotion of Access to Information Policy
                    </LegalLink>
                    <LegalLink href={CodeofConduct2022} target="_blank">
                        Code of Conduct 2022
                    </LegalLink>
                    <LegalLink href={DisclosureNotice} target="_blank">
                        Disclosure Notice
                    </LegalLink>
                    <LegalLink href={TreatingCustomersFairlyNov2020} target="_blank">
                        Treating Customers Fairly Nov 2020
                    </LegalLink>
                    <LegalLink href={ConflictofInterestManagementPolicyNov2020} target="_blank">
                        Conflict of Interest Management Policy Nov 2020
                    </LegalLink>
                    <LegalLink href={ComplaintsPolicy} target="_blank">
                        Complaints Policy
                    </LegalLink>
                </LinkContainer>
            </Container>
        </LegalRoot>
    );
};

export default Legal;
