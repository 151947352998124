import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';

const IntroductionRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
    padding: '50px 0',
    textAlign: 'center',
}));

const Introduction = () => {
    return (
        <IntroductionRoot>
            <Container maxWidth="lg">
                <SectionTitle subtitle="Nama Giveaway" hideArrow={true} />

                <Typography variant="h5">
                    Fill out the form below and claim your braai tong!
                </Typography>

                <Typography variant="body1" marginY={2}>
                    By completing the form you'll be subscribed to our informative newsletters. We
                    promise not to spam, and you can unsubscribe anytime if you don't find the
                    content valuable.
                </Typography>
            </Container>
        </IntroductionRoot>
    );
};

export default Introduction;
