import React from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import { APP_FADE_TIMEOUT } from '../../constants/AppConstants';
import Newsletter from './newsletters/Newsletter';
import NewsletterContainer from './newsletters/NewsletterContainer';
import ListPosts from './newsletters/ListPosts';

const NewsletterRoot = styled('div')(({ theme }) => ({}));

const newsletter_array = [
    {
        postId: '1',
        title: 'Up In Flames - A good news story from peddie',
        date: '2022/02/17',
        text: `When what you've worked so hard for goes up in flames.`,
    },
];

const Newsletters = () => {
    return (
        <Fade in={true} timeout={APP_FADE_TIMEOUT}>
            <NewsletterRoot>
                <Newsletter />

                <NewsletterContainer />
            </NewsletterRoot>
        </Fade>
    );
};

export default Newsletters;
