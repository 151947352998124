import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';
import ProductInfo from './components/ProductInfo';
import SelfAssistOptions from './components/SelfAssistOptions';
import Testemonials from './components/Testemonials';
import Newsletter from './components/Newsletter';
import HomeSlider from './components/HomeSlider';
import { APP_FADE_TIMEOUT } from '../../constants/AppConstants';

const HomeRoot = styled('div')(({ theme }) => ({}));

const Home = () => {
    return (
        <Fade in={true} timeout={APP_FADE_TIMEOUT}>
            <HomeRoot>
                <HomeSlider />

                <Introduction />

                <ProductInfo />

                <SelfAssistOptions />

                <Testemonials />

                <Newsletter />
            </HomeRoot>
        </Fade>
    );
};

export default Home;
