import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import Post from './Post';
import NewsletterSidebar from './NewsletterSidebar';
import ListPosts from './ListPosts';
import { useHistory } from 'react-router-dom';

const NewsletterContainerRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const NewsletterContainer = (props) => {
    const { component } = props;

    const history = useHistory();

    const handlePostClick = (item) => {
        history.push({
            pathname: '/newsletter/post',
            search: `?query=${item['id']}`,
        });
    };

    return (
        <NewsletterContainerRoot>
            <Container maxWidth="lg">
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={8}>
                        <ListPosts />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <NewsletterSidebar handlePostClick={handlePostClick} />
                    </Grid>
                </Grid>
            </Container>
        </NewsletterContainerRoot>
    );
};

export default NewsletterContainer;
